import React from "react";
import axiosInstance from "../axios/axios";

class SlotService {
  async getDoctor(id) {
    try {
      const res = await axiosInstance.get(`/api/doctor/get-doc-by-id/${id}`);
      //   console.log("Doctor", res.data);
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }
}

export const slotService = new SlotService();

// export { emrService, EMRService };
