import { Helmet } from "react-helmet";
const Settings = () => {
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>
      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Settings</h4>
          </div>
          <div className="TurnNotificationArea">
            <h6>Turn Notification</h6>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider" />
            </label>
          </div>
          <div className="SettingsList">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <h4>Change Password</h4>
                  <p>
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
