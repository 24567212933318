import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const PayUsingCash = ({
  toggleCashPopup,
  settoggleCashPopup,
  selectedDate,
  selectedSlot,
  selectedDoc,
  additionalFee,
  filteredDoc,
  selectedSlotStartTime,
  handleAppointmentBooking,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => settoggleCashPopup(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Modal show={toggleCashPopup} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <>
            <div className="modal-body">
              <div className="ModalTopArea">
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Congratulations.png" />
                  </div>
                  <div className="AccountCreatBody">
                    <article>
                      <h3>Booking Confirmation</h3>
                      {/* <h4>
                        Congratulations <br />
                        Your payment was successful
                      </h4> */}
                    </article>
                  </div>
                  <div className="PaymentSuccessTable">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>Doctor</p>
                            <h6 className="text-capitalize">
                              {filteredDoc[0]?.firstname}
                            </h6>
                          </td>
                          <td>
                            <p>Appointment Type</p>
                            <h6>Virtual</h6>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Billing Amount</p>
                            <h6>{filteredDoc[0]?.doctor_fees}</h6>
                          </td>
                          <td>
                            <p>Schedule Date</p>
                            <h6>{selectedDate}</h6>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Schedule Slot</p>
                            <h6>{selectedSlotStartTime}</h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="Note">
                    <span>Note : </span> Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.
                  </p>
                  <div className="ButtonBox ButtonBoxNew">
                    <button
                      className="Dark"
                      onClick={() => handleAppointmentBooking("cash")}
                    >
                      Proceed with Appointment Booking
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default PayUsingCash;
