// export const bucketCreds = {
//   bucketName: "auuction",
//   dirName: "photo",
//   region: "us-east-1",
//   accessKeyId: "AKIAU4GHOSL2BYOEG7UV",
//   secretAccessKey: "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",
// };
export const bucketCreds = {
  bucketName: "mobileapplications",
  dirName: "photo",
  region: "ap-south-1",
  accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_KEY,
};
