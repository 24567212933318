import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const clinicServices = {
  getClinicsByLatLong,
  getDoctorInsideClinic,
  getCityList,
  getClinicsByCityName,
};

async function getClinicsByLatLong(lat, long) {
  console.log("coordinates = ", {
    longitude: long,
    latitude: lat,
  });
  try {
    const result = await axiosInstance.post(
      `/api/clinic/get-clinic-by-long-lat`,
      {
        longitude: long,
        latitude: lat,
      }
    );
    console.log(result);
    if (result.data.success) {
      return { success: true, clinics: result.data.clinics };
    } else {
      return { success: false, clinics: [] };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, clinics: [] };
  }
}

async function getDoctorInsideClinic(day, date, clinic, care) {
  try {
    const res = await axiosInstance.get(
      `/api/clinic/get-doc-inside-clinic?clinic_id=${clinic}&day=${day.toLowerCase()}&date=${date}&care_type=${care}`
    );
    if (res.data.success) {
      return res.data.docs;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

async function getCityList() {
  try {
    const res = await axiosInstance.get(`/api/clinic/get-clinic-city-list`);
    if (res.data.success) {
      return {
        success: true,
        clinics: res.data.clinics,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    return {
      success: false,
    };
  }
}

async function getClinicsByCityName(city, lat, long) {
  try {
    const result = await axiosInstance.post(
      `/api/clinic/get-clinic-by-long-lat`,
      {
        longitude: long,
        latitude: lat,
        city: city,
        city_or_cords: "city",
      }
    );
    console.log(result);
    if (result.data.success) {
      return { success: true, clinics: result.data.clinics };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false };
  }
}
