import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import HeaderDoctor from "./HeaderDoctor";
import SidebarDoctor from "./SidebarDoctor";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { initializeSocket, getSocket } from "../../config/socketIoConfig";
import Swal from "sweetalert2";
import { videoCallService } from "../../services/videoCallServices";
import { AgoraPlayer2 } from "../agoraCallPlayer/AgoraPlayer2";
import { setAgoraPlayer } from "../../store/AgoraPlayerSlice";
import { setAgoraToken } from "../../store/AgoraTokenSlice";
import { setAgoraChannel } from "../../store/AgoraChannelSlice";
import { setAgoraDoctorId } from "../../store/AgoraDoctorIdSlice";
import { Howl } from "howler";
import { useRef } from "react";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const reduxUser = useSelector((state) => state.user);
  const loader = useSelector((state) => state.loader.value);
  // const agoraPlayer = useSelector((state) => state.agoraPlayer.agoraPlayer);
  const [agoraPlayer, setagoraPlayer] = useState(false);
  const channel = useSelector((state) => state.agoraChannel.agoraChannel);
  const agoraToken = useSelector((state) => state.agoraToken.agoraToken);
  const agoraDocId = useSelector((state) => state.agoraDoctorId.agoraDoctorId);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [user, setuser] = useState({});
  const [socket, setSocket] = useState(null);
  const swalRef = useRef(null);
  let socketIo;

  const sound1 = new Howl({
    src: [require("../../assets/audio.mp3")],
    preload: true,
    volume: 1.0,
  });

  const [sound, setSound] = useState(sound1);

  useEffect(() => {
    console.log("loader state", loader);
  }, [loader]);

  useEffect(() => {
    const login = localStorage.getItem("isLoggedIn");
    setisLoggedIn(login);
    const user = JSON.parse(localStorage.getItem("user"));
    setuser(user);
  }, []);

  useEffect(() => {
    if (reduxUser?._id) {
      socketIo = getSocket(reduxUser._id);
      setSocket(socketIo);

      socketIo.on("call-incoming", (msg) => {
        sound.play();
        showIncomingCallNotification(msg.senderName, msg.sender_id);
      });

      socketIo.on("call-cut-self", (msg) => {
        sound.stop();
        Swal.close();
      });

      socketIo.on("call-ended", (msg) => {
        window.location.reload();
        console.log("call ended hit");
        // toast("Call was ended by doctor");
      });

      // return () => {
      //   socketIo.disconnect();
      // };
    }
  }, []);

  //swal function for call notification
  const showIncomingCallNotification = (doctorName, doc_id) => {
    console.log("doc_id", doc_id);

    function onAnswer() {
      sound.stop();
      async function getTokenAndConnectCall() {
        const res = await videoCallService.getChannel(reduxUser?._id, doc_id);
        if (res.success) {
          const res2 = await videoCallService.getToken(res.channel);
          if (res2.success) {
            dispatch(setAgoraToken(res2.token));
            dispatch(setAgoraChannel(res.channel));
            dispatch(setAgoraDoctorId(doc_id));
            socketIo.emit("accept-call", {
              receiver_id: doc_id,
              sender_id: reduxUser?._id,
              senderName: reduxUser?.firstname,
            });
            // dispatch(setAgoraPlayer("true"));
            setagoraPlayer(true);
            // navigate(
            //   `/patient/appointment-test?id=${res2?.token}&channel=${res?.channel}&doc=${doc_id}`
            // );
          } else {
            toast("unable to obtain agora token");
          }
        } else {
          toast("unable to obtain channel id");
        }
      }

      getTokenAndConnectCall();
    }
    function onDecline() {
      sound.stop();
      socketIo.emit("decline-call", {
        receiver_id: doc_id,
        sender_id: reduxUser?._id,
        senderName: reduxUser?.firstname,
      });
    }
    Swal.fire({
      title: "Incoming Call",
      text: `Dr. ${doctorName} is calling you.`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Answer",
      cancelButtonText: "Decline",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: true,
    }).then((result) => {
      swalRef.current = Swal;
      if (result.isConfirmed) {
        // User clicked "Answer"
        if (onAnswer) onAnswer();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "Decline"
        if (onDecline) onDecline();
      }
    });
  };

  function endCall() {
    socket.emit("end-call", {
      receiver_id: agoraDocId,
      sender_id: reduxUser?._id,
      senderName: reduxUser.firstname,
    });
    window.location.reload();
  }

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const doctorToken = localStorage.getItem("doctorToken");
    if (!userToken) {
      if (
        [
          "/patient/my-profile",
          "/patient/notification",
          "/patient/core-plan",
          "/patient/lab-reports",
          "/patient/insurance",
          "/patient/appointment",
          "/patient/vitals",
          "/patient/medications",
          "/patient/document",
          "/patient/settings",
        ].includes(location.pathname)
      ) {
        navigate("/");
      }
    }

    if (!doctorToken) {
      if (
        [
          "/doctor/dashboard",
          "/doctor/video-call",
          "/doctor/clinic-visit",
          "/doctor/availabilities",
          "/settings",
          "/doctor/chat-history",
          "/doctor/help",
        ].includes(location.pathname)
      ) {
        navigate("/");
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>Ur HealthCare</title>
        <link rel="icon" type="image/x-icon" href="images/favicon.png" />
      </Helmet>
      {location.pathname.includes("doctor") ? (
        <HeaderDoctor />
      ) : (
        <Header
          isLoggedIn={isLoggedIn}
          setisLoggedIn={setisLoggedIn}
          userData={user}
        />
      )}
      {location.pathname.includes("doctor") ? <SidebarDoctor /> : null}
      <ToastContainer />
      {agoraPlayer == true ? (
        <AgoraPlayer2
          channel={channel}
          agoraToken={agoraToken}
          endCall={endCall}
          doctor_id={agoraDocId}
          patient_id={reduxUser?._id}
          patient_name={reduxUser?.firstname}
          setAgoraPlayer={setAgoraPlayer}
          agoraPlayer={agoraPlayer}
        />
      ) : null}

      {loader ? <Loader /> : null}
      {children}
      {location.pathname.includes("doctor") ? null : location.pathname.includes(
          "patient/messaging"
        ) ? null : (
        <Footer />
      )}
    </>
  );
};

export default Layout;
