import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/doctor.css";
import "./assets/patient.css";
import "./assets/style.css";
import Layout from "./components/Layout/Layout";
import Home from "./components/Pages/index";
import About from "./components/Pages/About";
import Contact from "./components/Pages/Contact";
import Privacy from "./components/Pages/Privacy";
import MyProfile from "./components/Pages/patient/MyProfile";
import Membership from "./components/Pages/patient/Membership";
import Appointment from "./components/Pages/patient/Appointment";
import Chat from "./components/Pages/patient/Chat";
import CorePlan from "./components/Pages/patient/CorePlan";
import Documents from "./components/Pages/patient/documents-available";
import Document from "./components/Pages/patient/documnets";
import Help from "./components/Pages/patient/help";
import Faq from "./components/Pages/faq";
import Terms from "./components/Pages/Terms";
import Howitworks from "./components/Pages/how-it-works";
import Notfound from "./components/Pages/NotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Vitals from "./components/Pages/patient/My-vitals";
import Prescription from "./components/Pages/patient/Prescription";
import MediacalReports from "./components/Pages/patient/MedicalReports";
import LapReports from "./components/Pages/patient/LabReports";
import Insurance from "./components/Pages/patient/Insurance";
import Settings from "./components/Pages/patient/Settings";
import DoctorHome from "./components/Pages/doctor/Home";
import VideoCall from "./components/Pages/doctor/VideoCall";
import ClinicVisit from "./components/Pages/doctor/CinicVisit";
import Availabilities from "./components/Pages/doctor/Availabilities";
import Settingss from "./components/Pages/doctor/Settings";
import Messages from "./components/Pages/doctor/Messages";
import Notifications from "./components/Pages/doctor/Notifications";
import Messaging from "./components/Pages/patient/Messaging";
import ChatHistory from "./components/Pages/doctor/ChatHistory";
import Medications from "./components/Pages/patient/Medications";
import PatientDetail from "./components/Pages/doctor/PatientDetail";
import DocterAbout from "./components/Pages/doctor/DocterAbout";
import ChangePassword from "./components/Pages/doctor/ChangePassword";
import TermCondation from "./components/Pages/doctor/TermCondation";
import PrivacyPolicy from "./components/Pages/doctor/PrivacyPolicy";
import SuccessComponent from "./components/stripeCheckout/stripeSuccess";
import Notification from "./components/Pages/patient/Notification";
import DocProfile from "./components/Pages/doctor/Profile";

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-and-condition" element={<Terms />} />
            <Route path="/how-it-works" element={<Howitworks />} />

            {/* patient routes*/}

            <Route path="/patient/membership" element={<Membership />} />
            <Route path="/patient/appointment" element={<Appointment />} />
            <Route path="/patient/my-profile" element={<MyProfile />} />
            <Route path="/patient/chat" element={<Chat />} />
            <Route path="/patient/core-plan" element={<CorePlan />} />
            <Route
              path="/patient/documents-available"
              element={<Documents />}
            />
            <Route path="/patient/document" element={<Document />} />
            <Route path="/patient/notification" element={<Notification />} />
            <Route path="/patient/help-support" element={<Help />} />
            <Route path="/patient/vitals" element={<Vitals />} />
            <Route path="/patient/prescription" element={<Prescription />} />
            <Route path="/patient/lab-reports" element={<LapReports />} />
            <Route path="/patient/medications" element={<Medications />} />
            <Route path="/patient/insurance" element={<Insurance />} />
            <Route path="/patient/settings" element={<Settings />} />
            <Route
              path="patient/medical-reports"
              element={<MediacalReports />}
            />

            {/* doctor routes*/}
            <Route path="/doctor/dashboard" element={<DoctorHome />} />
            <Route path="/doctor/doctor-profile" element={<DocProfile />} />
            <Route path="/doctor/video-call" element={<VideoCall />} />
            <Route path="/doctor/patient-details" element={<PatientDetail />} />
            <Route path="/doctor/clinic-visit" element={<ClinicVisit />} />
            <Route path="/doctor/availabilities" element={<Availabilities />} />
            <Route path="/doctor/chat-history" element={<ChatHistory />} />
            <Route path="/doctor/settings" element={<Settingss />} />
            <Route
              path="/doctor/settings/patient-details"
              element={<PatientDetail />}
            />
            <Route path="/doctor/settings/about-us" element={<DocterAbout />} />
            <Route
              path="/doctor/settings/changePassword"
              element={<ChangePassword />}
            />
            <Route
              path="/doctor/settings/term-condation"
              element={<TermCondation />}
            />
            <Route
              path="/doctor/settings/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="/doctor/settings/changePassword"
              element={<ChangePassword />}
            />

            <Route path="/doctor/notifications" element={<Notifications />} />
            {/* Messaging Routes */}
            <Route
              path="/patient/messaging/:patient/:doctor/:appointment"
              element={<Messaging />}
            />
            <Route path="/doctor/chat" element={<Messages />} />

            {/*Stripe Success Route */}

            <Route path="/membership/success" element={<SuccessComponent />} />
            {/* <Route path="/membership/cancel" element={<Messages />} /> */}

            <Route path="*" element={<Notfound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
