import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url }) => {
  return (
    <div className="video-player">
      <ReactPlayer
        url={url}
        playing={false}
        controls={true}
        width="100%"
        height="auto"
        onError={(e) => console.error("Error loading video:", e)}
      />
    </div>
  );
};

export default VideoPlayer;
