import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const staticContentService = {
  getPrivacyPolicy: getPrivacyPolicy,
  getFaq: getFaq,
  getAboutUs: getAboutUs,
  getLegalPolicy: getLegalPolicy,
  getTermAndCondation: getTermAndCondation,
  getContactUs: getContactUs,
};

async function getPrivacyPolicy(userId) {
  try {
    const res = await axiosInstance.get(`/api/static/privacy-policy`);

    if (res.status == 200 && res.data.success == true) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getFaq() {
  try {
    const res = await axiosInstance.get(`/api/faq/get-all-faq`);

    if (res.status == 200) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}
async function getTermAndCondation() {
  try {
    const res = await axiosInstance.get(`/api/static/terms-condition`);

    if (res.status == 200) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}
async function getLegalPolicy() {
  try {
    const res = await axiosInstance.get(`/api/static/delete-account-policy`);

    if (res.status == 200 && res.data.success == true) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getAboutUs() {
  try {
    const res = await axiosInstance.get(`/api/static/about-us`);

    if (res.status == 200) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getContactUs() {
  try {
    const res = await axiosInstance.get(`/api/static/contact-us`);

    if (res.status == 200 && res.data.success == true) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}
