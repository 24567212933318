// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   value: false,
// };

// export const loaderSlice = createSlice({
//   name: "loader",
//   initialState,
//   reducers: {
//     loader: (state, action) => {
//       state.value = action.payload;
//     },
//   },
// });

// export const { loader } = loaderSlice.actions;

// export default loaderSlice.reducer;

// LoaderSlice.js (or wherever you define your slice)
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    loader: (state, action) => {
      console.log("action triggered", action);
      console.log("state ", state.value);
      state.value = action.payload;
    },
  },
});

export const { loader } = loaderSlice.actions;

export default loaderSlice.reducer;
