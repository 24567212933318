import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const membershipService = {
  getMemberships: getMemberships,
  checkMembership: checkMembership,
  purchasePlan: purchasePlan,
  getPlans: getPlans,
  getActiveMem: getActiveMem,
  cancelMembership: cancelMembership,
};

async function getMemberships() {
  try {
    const res = await axiosInstance.get(`/api/membership/get-membership`);

    console.log("membership", res);

    if (res.status == 200 && res.data.success == true) {
      return {
        success: true,
        membership: res.data.plans,
      };
    } else {
      return {
        success: false,
        membership: [],
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getActiveMem(cusId) {
  try {
    const res = await axiosInstance.get(`/api/stripe/subscriptions/${cusId}`);

    if (res.status == 200) {
      return {
        success: true,
        membership: res.data.activeSubscriptions,
      };
    } else {
      return {
        success: false,
        membership: [],
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function checkMembership(patient_id) {
  console.log("patient_id", patient_id);
  try {
    const res = await axiosInstance.get(
      `/api/membership/get-patient-purchased-plans?patient_id=${patient_id}`
    );

    console.log("purchased plan", res);

    if (res.data.success == true) {
      console.log("if ran");
      return {
        success: true,
        plans: res.data.purchased_plans,
      };
    } else {
      console.log("else ran");
      return {
        success: false,
        plans: [],
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function purchasePlan(data) {
  try {
    const res = await axiosInstance.post(
      `/api/membership/patient-purchase-membership-old`,
      data
    );

    console.log("plan purchase==>", res);

    if (res.data.success == true) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getPlans() {
  try {
    const res = await axiosInstance.get(`/api/stripe/get-plans`);

    console.log("membership", res);

    if (res.status == 200 && res.data.success == true) {
      return {
        success: true,
        membership: res.data.plans,
      };
    } else {
      return {
        success: false,
        membership: [],
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function cancelMembership(data) {
  try {
    const res = await axiosInstance.post(
      `/api/stripe/cancel-subscription`,
      data
    );

    if (res.status == 200) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}
