import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { patientService } from "../../services/patientServices";
import { isImage, showNotification } from "../../helper/helper";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { useEffect } from "react";
import { uploadToAws } from "../../helper/s3Helper";
import { toast } from "react-toastify";
import { staticContentService } from "../../services/staticContentService";
import parse from "html-react-parser";
import { insuranceService } from "../../services/insuranceService";
import moment from "moment";

const AddInsurance = ({ show, setshow, insurance_id, getInsurance, User }) => {
  const dispatch = useDispatch();
  //form 2 insurance
  const [formNum, setformNum] = useState(2);
  const [carrierName, setCarrierName] = useState("");
  const [ipa, setIpa] = useState("");
  const [memberId, setMemberId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  //subscriber form states
  const [relationship, setRelationship] = useState("");
  const [subscriberFirstName, setSubscriberFirstName] = useState("");
  const [subscriberDob, setSubscriberDob] = useState("");
  const [subscriberAddress, setSubscriberAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [insurance_image, setinsurance_image] = useState("");

  const today = moment().format("YYYY-MM-DD");

  // Event handler for form submission
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let fileUrl;
    // Upload the selected file to AWS and get the URL
    try {
      if (formNum == 2) {
        fileUrl = await uploadToAws(selectedFile);
        if (fileUrl) {
          setinsurance_image(fileUrl);
          console.log("insurance form 1", {
            carrierName,
            ipa,
            memberId,
            groupId,
            dateOfBirth,
            link1: fileUrl,
          });

          setformNum(3);
        }
      }
      if (formNum == 3) {
        dispatch(loader(true));
        const user = JSON.parse(localStorage.getItem("user"));
        const insurance = {
          insurance_id: insurance_id,
          patient_id: User?._id,
          carrier_name: carrierName,
          ipa: ipa,
          relation_to_subscriber: relationship,
          member_id: memberId,
          group_id: groupId,
          firstname_subscriber: subscriberFirstName,
          lastname_subscriber: "",
          dob_insurance: dateOfBirth,
          dob_subscriber: subscriberDob,
          subscriber_address: subscriberAddress,
          subscriber_city: city,
          subscriber_state: state,
          link1: insurance_image,
          fileType1: selectedFile.type,
          link2: "examppleimage.com",
          fileType2: "",
        };

        const res = await patientService.saveInsuranceData(insurance);
        if (res) {
          handleClose();
          showNotification(
            "success",
            "Information saved",
            "Insurance info is now updated."
          );
          getInsurance(user?._id);
        } else {
          showNotification(
            "error",
            "Something went wrong",
            "Insurance info is not saved. Something went wront. Please try agian"
          );
          handleClose();
        }
        dispatch(loader(false));
      }
    } catch (err) {
      console.error("Error uploading file:", err);
      toast("Error uploading file. Please try again.");
    }
  };

  // Event handler for file input change

  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    if (isImage(file)) {
      console.log("file", file);
      setSelectedFile(file); // Set the selected file to state

      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("preview url", reader.result);
        setPreviewUrl(reader.result); // Set the preview URL to state
      };

      if (file) {
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    } else {
      showNotification("error", "File not allowed", "Only images are allowed.");
    }
  };

  function handleClose() {
    setshow(false);

    setformNum(2);
    setCarrierName("");
    setIpa("");
    setMemberId("");
    setGroupId("");
    setDateOfBirth("");
    setSelectedFile(null);
    setPreviewUrl("");

    // Subscriber form states
    setRelationship("");
    setSubscriberFirstName("");
    setSubscriberDob("");
    setSubscriberAddress("");
    setCity("");
    setState("");
    setinsurance_image("");
  }
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formNum == 2 ? (
          <>
            <div className="LoginBox">
              <div className="StepHead"></div>
              <div className="BookingModalArea">
                <h3 className="Title">Insurance Information</h3>
              </div>
              <form onSubmit={handleSubmitForm}>
                <div className="LoginBody">
                  <div className="form-group">
                    <label>Carrier Name</label>
                    <select
                      className="form-control"
                      value={carrierName}
                      onChange={(e) => setCarrierName(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Tricare West">Tricare West</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Independent Physician Association (IPA)</label>
                    <div className="form-group">
                      <label>Independent Physician Association (IPA)</label>
                      <select
                        className="form-control"
                        value={ipa}
                        onChange={(e) => setIpa(e.target.value)}
                      >
                        <option value="">Select IPA</option>
                        <option value="IPA1">IPA 1</option>
                        <option value="IPA2">IPA 2</option>
                        <option value="IPA3">IPA 3</option>
                        {/* Add more options as needed */}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={memberId}
                          onChange={(e) => setMemberId(e.target.value)}
                        />
                        <label>Member ID</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={groupId}
                          onChange={(e) => setGroupId(e.target.value)}
                        />
                        <label>Group ID/No.</label>
                      </div>
                    </div>
                  </div>
                  <div className="SelectGender">
                    <div className="form-group">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        max={today}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="SelectGender">
                      <label>Upload Insurance Card Photo</label>
                      <figure>
                        {previewUrl == "" ? (
                          <img
                            src="/images/card.png"
                            alt="Default Insurance Card"
                            className="img-fluid"
                            style={{ maxWidth: "100%" }}
                          />
                        ) : (
                          <img
                            src={previewUrl}
                            alt="Insurance Card Preview"
                            className="img-fluid"
                            style={{ maxWidth: "100%" }}
                          />
                        )}
                      </figure>
                    </div>
                    <div className="ImageSize">
                      <figcaption>
                        <h4>Jpeg, Png &amp; PDF</h4>
                        <p>20 MB max</p>
                      </figcaption>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={handleFileChange} // Assigned handleFileChange directly
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ButtonBox">
                  <button type="submit" className="Dark FolderPermissionId">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : null}

        {formNum == 3 ? (
          <div className="LoginBox">
            <div className="StepHead"></div>
            <div className="ModalTopArea">
              <div className="BookingModalArea">
                <h3 className="Title">Subscriber Information</h3>
              </div>
              <form onSubmit={handleSubmitForm}>
                <div className="LoginBody">
                  <div className="form-group">
                    <label>Patient’s Relationship to Subscriber</label>
                    <select
                      className="form-control"
                      value={relationship}
                      onChange={(e) => setRelationship(e.target.value)}
                    >
                      <option>Select</option>
                      <option>Husband</option>
                      <option>Wife</option>
                      <option>Brother</option>
                      <option>Sister</option>
                      <option>Son</option>
                      <option>Daughter</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={subscriberFirstName}
                      onChange={(e) => setSubscriberFirstName(e.target.value)}
                    />
                    <label>First Name</label>
                  </div>
                  <div className="SelectGender">
                    <div className="form-group">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        value={subscriberDob}
                        onChange={(e) => setSubscriberDob(e.target.value)}
                        max={today}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={subscriberAddress}
                      onChange={(e) => setSubscriberAddress(e.target.value)}
                    />
                    <label>Subscriber Address</label>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <label>City</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                        <label>State</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ButtonBox">
                  <button type="submit" className="Dark">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default AddInsurance;
