import { useSelector, useDispatch } from "react-redux";
import { getFormattedDate, showNotification } from "../../../helper/helper";
import female from "../../../assets/imges/Avatar.png";
import male from "../../../assets/imges/Avatar-1.png";
import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { doctorService } from "../../../services/doctorService";
import { toast } from "react-toastify";

const DocProfile = () => {
  const doctor = useSelector((state) => state.doctor);
  const [preview, setPreview] = useState(doctor?.profile_pic || "");

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    firstname: doctor?.firstname || "",
    email: doctor?.email || "",
    phone: doctor?.phone || "",
    speciality: doctor?.speciality || "",
    care_type: doctor?.care_type || "",
    about: doctor?.about || "",
    profile_pic: doctor?.profile_pic || null,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Dispatch the updated data to Redux store or API
    // dispatch({ type: "UPDATE_DOCTOR_PROFILE", payload: formData });
    console.log("Profile Submit", formData);
    const res = await doctorService.updateProfile(doctor._id, formData);

    if (res.success) {
      toast("Profile Updated");
      handleClose();
    } else {
      toast("Profile Not Updated");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setFormData({ ...formData, profile_pic: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      {/* <div className="DoctorWrapperArea">
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>My Profile</h4>
          </div>
          <div className="ProfileHead ">
            <figure>
              <img
                src={
                  doctor.profile_pic
                    ? doctor.profile_pic
                    : doctor?.gender.toLowerCase() == "male"
                    ? male
                    : female
                }
              />
            </figure>
            <figcaption>
              <h5>{doctor?.firstname}</h5>
              <p>Registration {doctor?.register_id}</p>
              <a href="">Edit Profile</a>
            </figcaption>
          </div>
          <div className="ProfileBody ">
            <aside>
              <p>Email Address</p>
              <h6>{doctor?.email}</h6>
            </aside>
            <aside>
              <p>Phone Number</p>
              <h6>{doctor?.phone}</h6>
            </aside>
            <aside>
              <p>Date Registered on</p>
              <h6>{getFormattedDate(doctor?.createdAt, "YYYY-MM-DD")}</h6>
            </aside>
            <aside className="text-capitalize">
              <p>Speciality</p>
              <h6>{doctor?.email}</h6>
            </aside>
            <aside className="text-capitalize">
              <p>Care Type</p>
              <h6>{doctor?.care_type}</h6>
            </aside>
          </div>
          <div className="AboutBox text-capitalize">
            <h6>About</h6>
            <p>{doctor?.about}</p>
          </div>
        </div>
      </div> */}

      <div className="DoctorWrapperArea">
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>My Profile</h4>
          </div>
          <div className="ProfileHead ">
            <figure>
              <img
                src={
                  preview ||
                  (doctor?.gender.toLowerCase() === "male" ? male : female)
                }
                alt="Profile"
              />
            </figure>
            <figcaption>
              <h5 className="text-capitalize">{doctor?.firstname}</h5>
              <p>Registration {doctor?.register_id}</p>
              <Button variant="link" onClick={handleShow}>
                Edit Profile
              </Button>
            </figcaption>
          </div>
          <div className="ProfileBody ">
            <aside>
              <p>Email Address</p>
              <h6>{doctor?.email}</h6>
            </aside>
            <aside>
              <p>Phone Number</p>
              <h6>{doctor?.phone}</h6>
            </aside>
            <aside>
              <p>Date Registered on</p>
              <h6>{getFormattedDate(doctor?.createdAt, "YYYY-MM-DD")}</h6>
            </aside>
            <aside className="text-capitalize">
              <p>Speciality</p>
              <h6>{doctor?.speciality}</h6>
            </aside>
            <aside className="text-capitalize">
              <p>Care Type</p>
              <h6>{doctor?.care_type}</h6>
            </aside>
          </div>
          <div className="AboutBox text-capitalize">
            <h6>About</h6>
            <p>{doctor?.about}</p>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3 text-center">
              <Form.Label>Profile Picture</Form.Label>
              <div>
                <img
                  src={
                    preview ||
                    (doctor?.gender.toLowerCase() === "male" ? male : female)
                  }
                  alt="Preview"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    marginBottom: "10px",
                  }}
                />
              </div>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Speciality</Form.Label>
              <Form.Control
                type="text"
                name="speciality"
                value={formData.speciality}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Care Type</Form.Label>
              <Form.Control
                type="text"
                name="care_type"
                value={formData.care_type}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>About</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="about"
                value={formData.about}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocProfile;
