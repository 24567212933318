import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const HeaderDoctor = () => {
  const navigate = useNavigate();
  const location = useLocation();

  async function openNotification() {
    if (location.pathname.includes("/doctor/notifications")) {
      navigate(-1);
    } else {
      navigate("/doctor/notifications");
    }
  }
  return (
    <>
      <Helmet></Helmet>
      <div className="DoctorNotification">
        <a href="javascript:void(0)" onClick={openNotification}>
          <img src="../images/Bell.png" />
        </a>
      </div>
    </>
  );
};

export default HeaderDoctor;
