import { useEffect, useState } from "react";
import { slotService } from "../../../services/slotServices";
import { useSelector } from "react-redux";

const Availabilities = () => {
  const doctor_id = useSelector((state) => state.doctor._id);
  const [alldoctors, setAllDoctors] = useState([]);
  const [filterSlot, setFilterSlot] = useState([]);
  const [day, setDay] = useState("monday");
  const [mode, setMode] = useState("virtual");

  useEffect(() => {
    getAllDoctors();
  }, []);
  useEffect(() => {
    if (alldoctors.length > 0 && doctor_id) {
      const FindSlot = alldoctors.map((item) => {
        return item.slots.filter((slot) => {
          return (
            slot.doctor_id == doctor_id &&
            slot.day == day &&
            slot.visit_type == mode
          );
        });
      });
      setFilterSlot(FindSlot);
    }
  }, [alldoctors, doctor_id, day, mode]);
  // console.log("Filtered data",filterSlot)

  async function getAllDoctors() {
    try {
      const res = await slotService.getDoctor(doctor_id);
      if (res.success) {
        // console.log("selected doctor",res.data)
        setAllDoctors(res.data);
      } else {
        console.log("Failed to get all doctors");
      }
    } catch (error) {
      console.log("Error in getting all doctors", error);
    }
  }

  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Availabilities</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>My Calendar</h4>
          </div>
          <div className="CommonTabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Virtual"
                  onClick={() => setMode("virtual")}
                >
                  Virtual Visit
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#Clinic"
                  onClick={() => setMode("clinic")}
                >
                  Clinic Visit
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="Virtual">
              <div className="SlotDay">
                <ul>
                  <li onClick={() => setDay("monday")}>
                    <span className={day === "monday" ? "active" : ""}>
                      Mon
                    </span>
                  </li>
                  <li onClick={() => setDay("tuesday")}>
                    <span className={day === "tuesday" ? "active" : ""}>
                      Tue
                    </span>
                  </li>
                  <li onClick={() => setDay("wednesday")}>
                    <span className={day === "wednesday" ? "active" : ""}>
                      Wed
                    </span>
                  </li>
                  <li onClick={() => setDay("thursday")}>
                    <span className={day === "thursday" ? "active" : ""}>
                      Thu
                    </span>
                  </li>
                  <li onClick={() => setDay("friday")}>
                    <span className={day === "friday" ? "active" : ""}>
                      Fri
                    </span>
                  </li>
                  <li onClick={() => setDay("saturday")}>
                    <span className={day === "saturday" ? "active" : ""}>
                      Sat
                    </span>
                  </li>
                  <li onClick={() => setDay("sunday")}>
                    <span className={day === "sunday" ? "active" : ""}>
                      Sun
                    </span>
                  </li>
                </ul>
              </div>
              <div className="SlotsBox">
                <h4>Available Slots</h4>
                {/* <ul>
                  <li>
                    <span>10:00 PM</span>
                  </li>
                </ul> */}

                <ul>
                  {filterSlot.map((item, index) =>
                    item.map((slot, idx) => (
                      <li key={idx}>
                        <span>{slot.start_time} </span>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
            <div className="tab-pane fade" id="Clinic">
              <div className="SlotDay">
                {/* <ul>
                  <li>
                    <span className="active">Mon</span>
                  </li>
            
                </ul> */}
                <ul>
                  <li onClick={() => setDay("monday")}>
                    <span className={day === "monday" ? "active" : ""}>
                      Mon
                    </span>
                  </li>
                  <li onClick={() => setDay("tuesday")}>
                    <span className={day === "tuesday" ? "active" : ""}>
                      Tue
                    </span>
                  </li>
                  <li onClick={() => setDay("wednesday")}>
                    <span className={day === "wednesday" ? "active" : ""}>
                      Wed
                    </span>
                  </li>
                  <li onClick={() => setDay("thursday")}>
                    <span className={day === "thursday" ? "active" : ""}>
                      Thu
                    </span>
                  </li>
                  <li onClick={() => setDay("friday")}>
                    <span className={day === "friday" ? "active" : ""}>
                      Fri
                    </span>
                  </li>
                  <li onClick={() => setDay("saturday")}>
                    <span className={day === "saturday" ? "active" : ""}>
                      Sat
                    </span>
                  </li>
                  <li onClick={() => setDay("sunday")}>
                    <span className={day === "sunday" ? "active" : ""}>
                      Sun
                    </span>
                  </li>
                </ul>
              </div>
              <div className="SlotsBox">
                <h4>Available Slots</h4>
                {/* <ul>
                  
                  <li>
                    <span>10:00 PM</span>
                  </li>
                </ul> */}
                <ul>
                  {filterSlot.map((item, index) =>
                    item.map((slot, idx) => (
                      <li key={idx}>
                        <span>{slot.start_time} </span>
                      </li>
                    ))
                  )}
                </ul>
              </div>
              {/* <div class="DataNoFound">
                  <img src="../images/Availabilities.png">
                  <p>You don’t have any slot on Tuesday</p>
              </div>   */}
              {/* <div class="SlotsBox">
                  <h4>Available Slots</h4>
                  <ul>
                      <li><span>06:00 AM</span></li>
                      <li><span>08:00 AM</span></li>
                      <li><span>10:00 AM</span></li>
                      <li><span>12:00 PM</span></li>
                      <li><span>02:00 PM</span></li>
                      <li><span>04:00 PM</span></li>
                      <li><span>06:00 PM</span></li>
                      <li><span>08:00 PM</span></li>
                      <li><span>10:00 PM</span></li> 
                      <li><span>06:00 AM</span></li>
                      <li><span>08:00 AM</span></li>
                      <li><span>10:00 AM</span></li>
                      <li><span>12:00 PM</span></li>
                      <li><span>02:00 PM</span></li>
                      <li><span>04:00 PM</span></li>
                      <li><span>06:00 PM</span></li>
                      <li><span>08:00 PM</span></li>
                      <li><span>10:00 PM</span></li> 
                      <li><span>06:00 AM</span></li>
                      <li><span>08:00 AM</span></li>
                      <li><span>10:00 AM</span></li>
                      <li><span>12:00 PM</span></li>
                      <li><span>02:00 PM</span></li>
                      <li><span>04:00 PM</span></li>
                      <li><span>06:00 PM</span></li>
                      <li><span>08:00 PM</span></li>
                      <li><span>10:00 PM</span></li> 
                      <li><span>06:00 AM</span></li>
                      <li><span>08:00 AM</span></li>
                      <li><span>10:00 AM</span></li>
                      <li><span>12:00 PM</span></li>
                      <li><span>02:00 PM</span></li>
                      <li><span>04:00 PM</span></li>
                      <li><span>06:00 PM</span></li>
                      <li><span>08:00 PM</span></li>
                      <li><span>10:00 PM</span></li> 
                  </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Availabilities;
