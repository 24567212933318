import axios from "axios";
export function filterDocByCareAndVisit(data, type) {
  if (type === "primary_virtual") {
    const primary_virtual = data.filter(
      (item, index) =>
        item.care_type == "primary" && item.visit_type == "virtual"
    );
    return primary_virtual;
  }
  if (type === "urgent_virtual") {
    const urgent_virtual = data.filter(
      (item, index) =>
        item.care_type == "urgent" && item.visit_type == "virtual"
    );
    return urgent_virtual;
  }
  if (type === "primary_clinic") {
    const primary_clinic = data.filter(
      (item, index) =>
        item.care_type == "primary" && item.visit_type == "clinic"
    );
    return primary_clinic;
  }

  if (type === "urgent_clinic") {
    const urgent_clinic = data.filter(
      (item, index) => item.care_type == "urgent" && item.visit_type == "clinic"
    );
    return urgent_clinic;
  }
}

export function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    // Check if the Geolocation API is supported
    if ("geolocation" in navigator) {
      // Use the Geolocation API to get the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success callback
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          // Resolve the promise with an object containing latitude and longitude
          resolve({ latitude, longitude });
        },
        (error) => {
          return "rejected";
        }
      );
    } else {
      // Geolocation is not supported
      return "rejected";
    }
  });
}

// Replace 'YOUR_IPINFO_ACCESS_TOKEN' with your actual IPinfo access token
const ACCESS_TOKEN = "776592accf9acf";
export const fetchLocation = async () => {
  try {
    // IPinfo API endpoint with access token
    const response = await axios.get(
      `https://ipinfo.io/json?token=${ACCESS_TOKEN}`
    );
    // Extract latitude and longitude from response
    const { loc } = response.data;
    const [latitude, longitude] = loc.split(",");
    return { latitude, longitude };
  } catch (err) {
    console.log(err.message);
  }
};
