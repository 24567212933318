import { Link } from "react-router-dom";

const Settingss = () => {
  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Settings</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>Settings</h4>
          </div>
          <div className="SettingsList">
            <ul>
              <li>
                <Link to={"/doctor/settings/changePassword"}>
                  <h4>Change Password</h4>
                  <p>
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  </p>
                </Link>
              </li>
              <li>
                <Link to={"/doctor/settings/term-condation"}>
                  <h4>Term &amp; Conditions</h4>
                  <p>
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  </p>
                </Link>
              </li>

              <li>
                <Link to={"/doctor/settings/privacy-policy"}>
                  <h4>Privacy Policy</h4>
                  <p>
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  </p>
                </Link>
              </li>

              <li>
                <Link to={"/doctor/settings/about-us"}>
                  <h4>About Us</h4>
                  <p>
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  </p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settingss;
