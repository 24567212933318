import { bucketCreds } from "../config/s3config";
import S3FileUpload from "react-s3";
import { toast } from "react-toastify";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const uploadToAws = async (file) => {
  console.log("upload to aws hits");
  if (file) {
    try {
      const data = await S3FileUpload.uploadFile(file, bucketCreds);
      console.log("Image URL:", data.location); // Log the URL of the uploaded file
      return data.location; // Return only the URL (location) of the uploaded file
    } catch (err) {
      console.error("Error uploading image:", err);
      throw err; // Rethrow the error to handle it where this function is called
    }
  } else {
    toast("Image is required and upload in JPEG, PNG, JPG format.");
    return null; // Return null if no file is uploaded
  }
};
