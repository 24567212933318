//depricated
import React, { useEffect, useState, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { FcVideoCall } from "react-icons/fc";
import { MdCallEnd } from "react-icons/md";
import { CiMicrophoneOn } from "react-icons/ci";
import { CiMicrophoneOff } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { setAgoraPlayer } from "../../store/AgoraPlayerSlice";
import Swal from "sweetalert2";

const APP_ID = "86da956e195a481a96801792a243f489";
const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

export const AgoraPlayer2 = ({
  channel,
  agoraToken,
  socket,
  doctor_id,
  patient_id,
  patient_name,
  setAgoraPlayer,
  endCall,
  agoraPlayer,
}) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [localTracks, setLocalTracks] = useState([]);
  const [hasJoined, setHasJoined] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  //states for timer

  const [callTimer, setCallTimer] = useState(0); // Timer state
  const [timerInterval, setTimerInterval] = useState(null);

  //

  const TOKEN = agoraToken;
  const CHANNEL = channel;

  useEffect(() => {
    joinCall();
  }, []);

  const handleUserJoined = async (user, mediaType) => {
    try {
      await client.subscribe(user, mediaType);
      if (mediaType === "video") {
        setUsers((prevUsers) => [...prevUsers, user]);
      }
      if (mediaType === "audio") {
        // Optionally play audio if needed
        // user.audioTrack.play();
      }
    } catch (error) {
      console.error("Error handling user join:", error);
    }
  };

  const handleUserLeft = (user) => {
    setUsers((prevUsers) => prevUsers.filter((u) => u.uid !== user.uid));
  };

  const joinCall = async () => {
    try {
      dispatch(loader(true));
      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);

      const uid = await client.join(APP_ID, CHANNEL, TOKEN, null);
      const tracks = await AgoraRTC.createMicrophoneAndCameraTracks();
      setLocalTracks(tracks);

      const [audioTrack, videoTrack] = tracks;
      setUsers((prevUsers) => [...prevUsers, { uid, videoTrack, audioTrack }]);

      await client.publish(tracks);
      setHasJoined(true);
      const intervalId = setInterval(() => {
        setCallTimer((prevTime) => prevTime + 1);
      }, 1000); // Line ~62
      setTimerInterval(intervalId);
      dispatch(loader(false));
    } catch (error) {
      dispatch(loader(false));
      console.error("Error joining Agora channel:", error);
    }
  };

  const leaveCall = async () => {
    try {
      if (timerInterval) {
        clearInterval(timerInterval); // Line ~91
        setTimerInterval(null); // Line ~92
      }
      // Unpublish local tracks if they are published
      if (client.connectionState === "CONNECTED") {
        await client.unpublish(localTracks);
      }

      // Stop and close all local tracks
      localTracks.forEach((track) => {
        track.stop();
        track.close();
      });

      // Leave the channel
      await client.leave();
      setHasJoined(false);
      setUsers([]);

      setAgoraPlayer(false);
      endCall();
    } catch (error) {
      console.error("Error during cleanup:", error);
    } finally {
      // Remove event listeners
      client.off("user-published", handleUserJoined);
      client.off("user-left", handleUserLeft);
    }
  };

  const toggleMute = () => {
    if (localTracks[0].muted) {
      localTracks[0].setMuted(false);
      setIsMuted(false);
    } else {
      localTracks[0].setMuted(true);
      setIsMuted(true);
    }
  };

  const handleEndCall = () => {
    Swal.fire({
      title: "Are you sure you want to end the call?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, end the call",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        leaveCall();
        console.log("Call ended");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Call was not ended");
      }
    });
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0"); // Ensure double-digit format
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  useEffect(() => {
    return () => {
      if (hasJoined) {
        leaveCall();
      }
    };
  }, [hasJoined]);

  return (
    <div
      style={
        agoraPlayer
          ? {
              position: "fixed", // Make the div fixed in the viewport
              top: 0, // Ensure it starts from the top
              left: 0, // Ensure it starts from the left
              width: "100%", // Full width of the viewport
              height: "100vh", // Full height of the viewport
              zIndex: 1000, // Optional: Ensure it's above other elements
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }
          : { display: "none" }
      }
    >
      <div className="button-group-agora">
        {hasJoined && (
          <div className="timer start-call">
            <h5 className="text-light">{formatTime(callTimer)}</h5>
          </div>
        )}

        {hasJoined && (
          <button className="start-call" onClick={toggleMute}>
            {isMuted ? (
              <CiMicrophoneOff className="mic" />
            ) : (
              <CiMicrophoneOn className="mic" />
            )}
          </button>
        )}
        <button
          className={hasJoined ? "end-call" : "start-call"}
          onClick={hasJoined ? handleEndCall : null}
        >
          {hasJoined ? (
            <MdCallEnd className="endcallIcon" fontSize={35} />
          ) : null}
        </button>
      </div>

      {hasJoined && (
        <>
          <div style={{ width: "100%", height: "100%" }}>
            {users.map((user) => (
              <VideoPlayer
                key={user.uid}
                user={user}
                isLocal={user.uid === client.uid}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const VideoPlayer = ({ user, isLocal }) => {
  const videoRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    // Play video
    if (user.videoTrack) {
      user.videoTrack.play(videoRef.current);
    }

    // Optionally play audio
    if (user.audioTrack) {
      user.audioTrack.play(audioRef.current);
    }

    return () => {
      if (user.videoTrack) {
        user.videoTrack.stop();
      }
      if (user.audioTrack) {
        user.audioTrack.stop();
      }
    };
  }, [user]);

  return (
    <div
      className={isLocal ? "my-vid" : "other-vid"}
      style={{
        position: isLocal ? "absolute" : "relative",
        width: isLocal ? "180px" : "100%",
        height: isLocal ? "180px" : "100%",
        bottom: isLocal ? "10px" : "auto",
        right: isLocal ? "10px" : "auto",
        zIndex: isLocal ? 10 : 1,
      }}
    >
      <div ref={videoRef} style={{ width: "100%", height: "100%" }}></div>
      {/* Uncomment if you want to show audio level */}
      {/* <div ref={audioRef} style={{ display: "none" }}></div> */}
    </div>
  );
};
