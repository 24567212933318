import { createSlice } from "@reduxjs/toolkit";

const bookingTypeSlice = createSlice({
  name: "bookingType",
  initialState: false, // Boolean state to indicate popup visibility
  reducers: {
    setBookingType: (state, action) => action.payload, // Sets state to true or false
    clearBookingType: () => false, // Explicitly resets to false
  },
});

export const { setBookingType, clearBookingType } = bookingTypeSlice.actions;
export default bookingTypeSlice.reducer;
