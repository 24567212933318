import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { patientService } from "../../../services/patientServices";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { calculateAge } from "../../../helper/helper";
import EditFamily from "../../Popups/EditFamily";
import EditMyProfile from "../../Popups/EditMyProfile";
import AddFamily from "../../Popups/AddFamily";
const MyProfile = () => {
  const [user, setuser] = useState({});
  const [family, setfamily] = useState([]);
  const [toggleFamilyModel, settoggleFamilyModel] = useState(false);
  const [toggleEditProfileModel, settoggleProfileModel] = useState(false);
  const [singleMember, setsingleMember] = useState([]);
  const [toggleAddfamily, settoggleAddfamily] = useState(false);
  const [extraData, setextraData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setuser(data);
  }, []);

  useEffect(() => {
    if (user?._id) {
      getFamilyMem();
      getExtraData();
    }
  }, [user]);

  async function getFamilyMem() {
    try {
      dispatch(loader(true));

      const result = await patientService.getFamily(user?._id);
      if (result.family_found == true) {
        dispatch(loader(false));
        setfamily(result.family);
      } else {
        dispatch(loader(false));
      }
    } catch (err) {
      dispatch(loader(false));
      console.log(err.message);
    }
  }

  async function editMemeber(id) {
    const singleMem = family.filter((item, index) => item?._id == id);
    setsingleMember(singleMem);
    console.log("singleMem", singleMem);
    settoggleFamilyModel(true);
  }

  async function getExtraData() {
    dispatch(loader(true));
    const result = await patientService.getExtraData(user?._id);
    if (result.success == true) {
      setextraData(result.data);
    }

    dispatch(loader(false));
  }

  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <EditFamily
        show={toggleFamilyModel}
        hide={settoggleFamilyModel}
        singleMember={singleMember}
        getFamilyMem={getFamilyMem}
        user={user}
      />

      <EditMyProfile
        show={toggleEditProfileModel}
        hide={settoggleProfileModel}
        user={user}
        setuser={setuser}
      />

      <AddFamily
        toggleAddfamily={toggleAddfamily}
        settoggleAddfamily={settoggleAddfamily}
        getFamilyMem={getFamilyMem}
      />

      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">My Profile</h4>
          </div>
          <div className="CommonTabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#MyProfile"
                >
                  My Profile
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Family">
                  Family
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="MyProfile">
              <div className="ProfileHead">
                <figure>
                  <img
                    src={
                      user?.profile_pic != ""
                        ? user?.profile_pic
                        : "../images/Avatar.png"
                    }
                    alt="Avatar"
                  />
                </figure>
                <figcaption>
                  <h5>{`${user.firstname} ${user.lastname}`}</h5>
                  <p>Registration {user.register_id}</p>
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#EditProfileModal"
                    onClick={() => settoggleProfileModel(true)}
                  >
                    Edit Profile
                  </a>
                </figcaption>
              </div>
              <div className="ProfileBody">
                <aside>
                  <p>Email Address</p>
                  <h6>{user.email}</h6>
                </aside>
                <aside>
                  <p>Phone Number</p>
                  <h6>{user?.phone ? user?.phone : "-"}</h6>
                </aside>
                <aside>
                  <p>Date of Birth</p>
                  <h6>{new Date(user.dob).toLocaleDateString()}</h6>
                </aside>
                <aside>
                  <p>Gender</p>
                  <h6 className="text-capitalize">
                    {user?.gender ? user?.gender : "-"}
                  </h6>
                </aside>
                <aside>
                  <p>Address</p>
                  <h6>{`${user.address ? user.address : "-"} ${
                    user.city ? user.city : ""
                  } ${user.state ? user.state : ""}`}</h6>
                </aside>
              </div>
              <div className="ProfileFooter">
                <aside>
                  <p>Date of Joining</p>
                  <h6>{new Date(user.createdAt).toLocaleDateString()}</h6>
                </aside>
                <aside>
                  <p>Total Consultation</p>
                  <h6>
                    {extraData?.total_consultation
                      ? extraData?.total_consultation
                      : "0"}
                  </h6>
                </aside>
                <aside>
                  <p>On Call</p>
                  <h6>{extraData?.on_call ? extraData?.on_call : "0"}</h6>
                </aside>
                <aside>
                  <p>On Clinic Visit</p>
                  <h6>{extraData?.on_clinic ? extraData?.on_clinic : "0"}</h6>
                </aside>
              </div>
            </div>
            <div className="tab-pane fade" id="Family">
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href="javascript:void(0);"
                  className="AddNewMemberBtn px-4"
                  style={{ marginTop: "-105px" }}
                  onClick={() => settoggleAddfamily(true)}
                >
                  {" "}
                  <i className="fa fa-plus" aria-hidden="true" /> Add New Member
                </a>
              </div>
              {family.length
                ? family.map((item, index) => (
                    <div className="MyProfileBox">
                      <span key={index} className="Icon">
                        <img
                          src={
                            item?.profile_pic
                              ? item?.profile_pic
                              : "../images/familyAvatar.png"
                          }
                        />
                      </span>
                      <aside>
                        <h6 className="text-capitalize">{item.firstname}</h6>
                        <ul>
                          <li>{calculateAge(item.dob)}</li>
                          <li>{item.relation} </li>
                          <li>{item.gender} </li>
                        </ul>
                      </aside>
                      <span onClick={() => editMemeber(item?._id)}>
                        <img src="../images/edit-icon.png" />
                      </span>
                    </div>
                  ))
                : null}

              {/* <div className="MyProfileBox">
                <span className="Icon">
                  <img src="../images/girl-avatar.png" />
                </span>
                <aside>
                  <h6>Zeeshan Alam</h6>
                  <ul>
                    <li>27 years</li>
                    <li>Sister</li>
                    <li>Female</li>
                  </ul>
                </aside>
                <a
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#EditFamilyModal"
                >
                  <img src="../images/edit-icon.png" />
                </a>
              </div>
              <div className="MyProfileBox">
                <span className="Icon">
                  <img src="../images/girl-avatar.png" />
                </span>
                <aside>
                  <h6>Rahul Bhawaliya</h6>
                  <ul>
                    <li>27 years</li>
                    <li>Sister</li>
                    <li>Female</li>
                  </ul>
                </aside>
                <a
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#EditFamilyModal"
                >
                  <img src="../images/edit-icon.png" />
                </a>
              </div>
              <div className="MyProfileBox">
                <span className="Icon">
                  <img src="../images/girl-avatar.png" />
                </span>
                <aside>
                  <h6>Akshit Kumar</h6>
                  <ul>
                    <li>27 years</li>
                    <li>Sister</li>
                    <li>Female</li>
                  </ul>
                </aside>
                <a href="javascript:void(0);">
                  <img src="../images/edit-icon.png" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
