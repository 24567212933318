import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agoraDoctorId: "", // This should match the state property you update
};

const agoraDoctorIdSlice = createSlice({
  name: "agoraDoctorId", // Update this to reflect the state property
  initialState,
  reducers: {
    setAgoraDoctorId: (state, action) => {
      state.agoraDoctorId = action.payload; // Make sure this matches the initialState property
    },
  },
});

export const { setAgoraDoctorId } = agoraDoctorIdSlice.actions;
export default agoraDoctorIdSlice.reducer;
