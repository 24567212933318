import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const insuranceService = {
  getInsurance: getInsurance,
  updateInsurance: updateInsurance,
};

async function getInsurance(id) {
  try {
    const result = await axiosInstance.get(
      `/api/admin/getInsurance_by_patient?patient_id=${id}`
    );
    console.log("insurances====>", result);
    if (result.status == 200) {
      if (result.data.data) {
        return result.data.data;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function updateInsurance(data) {
  try {
    const result = await axiosInstance.post(
      `/api/insurance/edit-insurance`,
      data
    );
    console.log("updated Insurance====>", result);
    if (result.status == 200 && result.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err.message);
  }
}
