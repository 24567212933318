import { createSlice } from "@reduxjs/toolkit";

const docPopup = createSlice({
  name: "togglePopup",
  initialState: false,
  reducers: {
    setdocpopup: (state, action) => action.payload,
    cleardocpopup: () => false,
  },
});

export const { setdocpopup, cleardocpopup } = docPopup.actions;
export default docPopup.reducer;
