import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { insuranceService } from "../../../services/insuranceService";
import { setbooking } from "../../../store/ConsultationSlice";
import { getFormattedDate, showNotification } from "../../../helper/helper";

const PayUsingINsuranceVirtualPrimary = ({
  toggleInsurancePopup,
  settoggleInsurancePopup,
  selectedDate,
  selectedSlot,
  selectedDoc,
  additionalFee,
  filteredDoc,
  selectedSlotStartTime,
  handleAppointmentBooking,
}) => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.user);
  const reduxbooking = useSelector((state) => state.booking);
  const [showModal, setShowModal] = useState(false);
  const [insurance, setinsurance] = useState([]);
  const [toggleForms, settoggleForms] = useState(0);

  const handleClose = () => {
    settoggleInsurancePopup(false);
    dispatch(setbooking(true));
  };
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    if (toggleInsurancePopup == true) {
      getInsurance();
    }
  }, [toggleInsurancePopup]);

  async function getInsurance() {
    dispatch(loader(true));
    const res = await insuranceService.getInsurance(reduxUser?._id);

    if (res?.length) {
      setinsurance(res);
    } else {
      showNotification(
        "warning",
        "No insurance found",
        "You dont have not added any insurance."
      );
      dispatch(setbooking(true));
      settoggleInsurancePopup(false);
    }
    dispatch(loader(false));
  }

  async function selectInsurance(id, verifiedStatus) {
    console.log("selected", id, verifiedStatus);
    if (verifiedStatus == "verified") {
      handleAppointmentBooking("insurance", id);
      settoggleInsurancePopup(false);
    } else {
      showNotification(
        "warning",
        "Cannot use this insurance",
        "Your insurance is not yet verified."
      );
    }
  }

  return (
    <>
      <Modal show={toggleInsurancePopup} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <>
            {toggleForms == 0 ? (
              insurance?.length ? (
                <div>
                  <h4 className="mb-2">Select Insurance</h4>
                  {insurance?.map((item, index) => (
                    <div
                      className="insuranceSelection"
                      onClick={() =>
                        selectInsurance(item?._id, item?.insurance_approved)
                      }
                    >
                      <div className="InsuranceBox InsuranceArea">
                        <div className="CenterBox">
                          <aside>
                            <p className="text-capitalize">
                              Insurance Name : <span>{item?.carrier_name}</span>
                            </p>
                          </aside>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <p>Name</p>
                                  <h5 className="text-capitalize">
                                    {item?.patient_id?.firstname}
                                  </h5>
                                </td>
                                <td>
                                  <p>Date of Birth</p>
                                  <h5>
                                    {getFormattedDate(
                                      item?.patient_id?.dob,
                                      "DD-MM-YYYY"
                                    )}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Gender</p>
                                  <h5 className="text-capitalize">
                                    {item?.patient_id?.gender}
                                  </h5>
                                </td>
                                <td>
                                  <p>ID</p>
                                  <h5>{item?.member_id}</h5>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Group ID</p>
                                  <h5>{item?.group_id}</h5>
                                </td>
                                <td>
                                  <p>IPA</p>
                                  <h5>{item?.ipa}</h5>
                                </td>
                              </tr>
                              {/* <tr>
                                        <td>
                                          <p>Plan Type</p>
                                          <h5>N/A</h5>
                                        </td>
                                      </tr> */}
                            </tbody>
                          </table>
                        </div>
                        {item?.insurance_approved == "verified" ? (
                          <>
                            <a
                              href="insurance-information-notverified.html"
                              className="VerifyBtn Green"
                            >
                              <span>
                                <img src="../images/verified.png" />
                              </span>
                              Verified
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href="javascript:void(0);"
                              className="VerifyBtn Orange"
                            >
                              <span>
                                <img src="../images/not-verified.png" />
                              </span>
                              Not Yet Verified
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null
            ) : null}
            {toggleForms == 1 ? (
              <div className="modal-body">
                <div className="ModalTopArea">
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                    </div>
                    <div className="AccountCreatBody">
                      <article>
                        <h3>Booking Confirmation</h3>
                        {/* <h4>
                      Congratulations <br />
                      Your payment was successful
                    </h4> */}
                      </article>
                    </div>
                    <div className="PaymentSuccessTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <p>Doctor</p>
                              <h6 className="text-capitalize">
                                {filteredDoc[0]?.firstname}
                              </h6>
                            </td>
                            <td>
                              <p>Appointment Type</p>
                              <h6>Virtual</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Billing Amount</p>
                              <h6>{filteredDoc[0]?.doctor_fees}</h6>
                            </td>
                            <td>
                              <p>Schedule Date</p>
                              <h6>{selectedDate}</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Schedule Slot</p>
                              <h6>{selectedSlotStartTime}</h6>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="Note">
                      <span>Note : </span> Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                    <div className="ButtonBox ButtonBoxNew">
                      <button
                        className="Dark"
                        onClick={() => handleAppointmentBooking("insurance")}
                      >
                        Proceed with Appointment Booking
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default PayUsingINsuranceVirtualPrimary;
