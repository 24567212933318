import { Helmet } from "react-helmet";
const MediacalReports = () => {
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Medical Images</h4>
          </div>
          <div className="MedicalImagesArea">
            <ul>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
              <li>
                <a href="medical-reports-available.html">
                  <figure>
                    <img src="../images/medical-1.png" />
                  </figure>
                  <figcaption>
                    <h6>Image Name</h6>
                    <p>20/05/2024</p>
                  </figcaption>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediacalReports;
