import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { emrService } from "../../../services/emrService";
import { loader } from "../../../store/LoaderSlice";
import { calculateAge, getFormattedDate } from "../../../helper/helper";

const PatientDetail = () => {
  const location = useLocation();
  const [emrId, setemrId] = useState("");
  const [meetingData, setmeetingData] = useState({});

  const [vitals, setvitals] = useState("");
  const [meds, setmeds] = useState([]);
  const [docss, setdocs] = useState([]);
  const [labreports, setlabreports] = useState([]);
  const [carePlan, setcarePlan] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("location", location);
    if (location.state.id) {
      setemrId(location.state.id);
      setmeetingData(location.state.data);
    }
  }, [location]);

  useEffect(() => {
    if (emrId != "") {
      getData();
    }
  }, [emrId]);

  async function getData() {
    dispatch(loader(true));
    const docs = await emrService.getDocuments(emrId);
    const carePlan = await emrService.getCarePlan(emrId);
    const labReport = await emrService.getLabReports(emrId);
    const meds = await emrService.getMedications(emrId);
    const res = await emrService.getVitals(emrId);

    setvitals(res.data);
    setdocs(docs.data);
    setcarePlan(carePlan.data);
    setlabreports(labReport.data);
    setmeds(meds.data);

    dispatch(loader(false));
  }

  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Patient Detail</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>Video Calls</h4>
          </div>
          <div className="AppoinmentBox mb-4">
            <div className="AppoinmentHead">
              <aside>
                <h3>
                  {meetingData.is_appointment_for_family
                    ? meetingData.family_member_id?.firstname
                    : meetingData.patient_id?.firstname}
                </h3>
                <ul className="text-capitalize">
                  <li>
                    {meetingData.is_appointment_for_family
                      ? calculateAge(meetingData?.family_member_id?.dob)
                      : calculateAge(meetingData?.patient_id?.dob)}{" "}
                    Years
                  </li>
                  <li>
                    {meetingData.is_appointment_for_family
                      ? meetingData.family_member_id?.gender
                      : meetingData.patient_id?.gender}
                  </li>
                  <li>
                    {meetingData?.is_appointment_for_family ? "Family" : "Self"}
                  </li>
                </ul>
              </aside>
              {/* <button className="Blue" id="StartCall">
                <span>
                  <img src="../images/Video.png" />
                </span>{" "}
                Start Call{" "}
              </button> */}
            </div>
            <div className="AppoinmentBody">
              <ul>
                <li>
                  <span>
                    <img src="../images/Video.png" />
                  </span>{" "}
                  {meetingData?.appointment_type}
                </li>
                <li>{meetingData?.care_type} Care</li>
              </ul>
              <h6>{meetingData?.reason_to_visit}</h6>
            </div>
            <div className="AppoinmentFooter">
              <p>
                <span>
                  <img src="../images/Clock.png" />
                </span>{" "}
                {getFormattedDate(
                  meetingData?.booking_date,
                  "DD MMM YYYY hh:mm a"
                )}
              </p>
              <h6>Now</h6>
            </div>
          </div>
          <div className="CommonTabsPills">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="pill"
                  href="#Vitals"
                >
                  Vitals
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#LabReports">
                  Documents
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  href="#MedicalImages"
                >
                  Care Plan
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#Documents">
                  Lab Reports
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#Meds">
                  Medications
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade active show " id="Vitals">
              <div className="MyVitalsArea">
                <ul>
                  <li>
                    <h6>Height</h6>
                    <p>{vitals == "" ? "-" : vitals?.height}</p>
                  </li>
                  <li>
                    <h6>Weight</h6>
                    <p>{vitals == "" ? "-" : vitals?.weight}</p>
                  </li>
                  <li>
                    <h6>Body Mass Index (BMI)</h6>
                    <p>{vitals == "" ? "-" : vitals?.bmi}</p>
                  </li>
                  <li>
                    <h6>Heart Rate</h6>
                    <p>{vitals == "" ? "-" : vitals?.heart_rate}</p>
                  </li>
                  <li>
                    <h6>Blood Pressure</h6>
                    <p>{vitals == "" ? "-" : vitals?.blood_pressure}</p>
                  </li>
                  <li>
                    <h6>SPO2</h6>
                    <p>{vitals == "" ? "-" : vitals?.spo2}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-pane fade" id="LabReports">
              <div className="LabReporsArea">
                {docss.length ? (
                  docss.map((item, index) => (
                    <article key={index}>
                      {console.log("link=>", item.document)}
                      <a href={item.document} download>
                        <aside>
                          <figcaption>
                            <h4>{item?.metatags[1]}</h4>
                            <p>{item?.description}</p>
                          </figcaption>
                          <span>
                            <img src="../images/pdf.png" alt="PDF icon" />
                          </span>
                        </aside>
                        <aside>
                          <p>
                            {getFormattedDate(
                              item.updated_at,
                              "YYYY-MM-DD hh:mm"
                            )}
                          </p>
                        </aside>
                      </a>
                    </article>
                  ))
                ) : (
                  <span>No Documents Found</span>
                )}
              </div>
            </div>
            <div className="tab-pane fade" id="MedicalImages">
              <div className="MedicalImagesArea">
                {/* <ul>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <img src="../images/medical-1.png" />
                    </figure>
                    <figcaption>
                      <h6>Image Name</h6>
                      <p>20/05/2024</p>
                    </figcaption>
                  </li>
                </ul> */}
                {carePlan.length ? (
                  carePlan.map((item, index) => (
                    <div key={index} className="care-plan-card my-3">
                      <div>
                        <strong>Title</strong> - {item?.title}
                      </div>
                      <div>
                        <strong>Instructions</strong> - {item?.instructions}
                      </div>
                      <div>
                        <strong>Schedule Date</strong> - {item?.scheduled_date}
                      </div>
                    </div>
                  ))
                ) : (
                  <span>No Care Plans Found</span>
                )}
              </div>
            </div>
            <div className="tab-pane fade" id="Documents">
              <div className="LabReporsArea">
                {labreports.length ? (
                  labreports.map((item, index) => (
                    <article key={index}>
                      {console.log("link=>", item.document)}
                      <a href={item.document} download target="_blank">
                        <aside>
                          <figcaption>
                            <h4>{item?.metatags[1]}</h4>
                            <p>{item?.description}</p>
                          </figcaption>
                          <span>
                            <img src="../images/pdf.png" alt="PDF icon" />
                          </span>
                        </aside>
                        <aside>
                          <p>
                            {getFormattedDate(
                              item.updated_at,
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </p>
                        </aside>
                      </a>
                    </article>
                  ))
                ) : (
                  <span>No lab reports found</span>
                )}
              </div>
            </div>

            <div className="tab-pane fade" id="Meds">
              <div className="LabReporsArea">
                {meds.length ? (
                  meds.map((item, index) => (
                    <article className="text-capitalize" key={index}>
                      <p className="float-right">
                        {getFormattedDate(item.updated_at, "YYYY-MM-DD")}
                      </p>
                      <aside>
                        <figcaption>
                          <h4 className="my-3">{item?.name}</h4>
                          <p className="my-3">
                            {item?.dosage_quantity != null ? (
                              <>Dosage - {item?.dosage_quantity}</>
                            ) : null}{" "}
                            {item?.dosage_units != null
                              ? item?.dosage_units
                              : null}
                          </p>
                        </figcaption>
                      </aside>
                      <aside></aside>
                      <span className="d-flex justify-content-between my-3">
                        <span>Start Date - {item?.date_started_taking}</span>
                        <span>End Date - {item?.date_stopped_taking}</span>
                      </span>
                      <span className="my-3">Note - {item.notes}</span>
                    </article>
                  ))
                ) : (
                  <span>No Medications Found</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetail;
