import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { patientService } from "../../services/patientServices";
import { isImage, showNotification } from "../../helper/helper";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { setUser } from "../../store/UserSlice";
import { useEffect } from "react";
import { uploadToAws } from "../../helper/s3Helper";
import { toast } from "react-toastify";
import { staticContentService } from "../../services/staticContentService";
import parse from "html-react-parser";
import moment from "moment";
import Timer from "../timerComponent/Timer";
import { setBookingPopup } from "../../store/BookingPopUpSlice";
import { setappointmentTrack } from "../../store/AppointmentOngoing";
import { setLogin } from "../../store/LoginPopupSlice";

const Congratulations = ({ show, hide }) => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.user);
  const tractBooking = useSelector((state) => state.appointmentTrack);
  const [hideMain, sethideMain] = useState(true);
  const [formNum, setformNum] = useState(0);
  const [privacyPolicy, setprivacyPolicy] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  //....
  const [postalCode, setpostalCode] = useState("");

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox status
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  function handleClose() {
    hide(false);
  }

  function showMultiStep(form) {
    setformNum(form);
  }

  //form 1

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");

  const handleSubmit = async (e) => {
    const patient = JSON.parse(localStorage.getItem("user"));
    e.preventDefault();

    dispatch(loader(true));
    if (firstName != "" && lastName != "" && gender != "" && dob != "") {
      const result = await patientService.insertProfileDetails({
        patient_id: patient?._id,
        firstname: firstName,
        lastname: lastName,
        gender,
        dob,
        zip: postalCode,
      });

      if (result.success) {
        showNotification(
          "success",
          "Details Saved",
          "Your profile details were saved succssfully"
        );
        showMultiStep(4);
        dispatch(setUser(result.data));
      } else {
        showNotification(
          "error",
          "Details Not Saved",
          "There was an error while saving. Please try again."
        );
      }
    } else {
      toast("Please fill out all the fields.");
    }

    dispatch(loader(false));
  };

  //

  //form 2 insurance

  const [carrierName, setCarrierName] = useState("");
  const [ipa, setIpa] = useState("");
  const [memberId, setMemberId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  //subscriber form states
  const [relationship, setRelationship] = useState("");
  const [subscriberFirstName, setSubscriberFirstName] = useState("");
  const [subscriberDob, setSubscriberDob] = useState("");
  const [subscriberAddress, setSubscriberAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [insurance_image, setinsurance_image] = useState("");

  const today = moment().format("YYYY-MM-DD");

  // Event handler for form submission
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let fileUrl;
    // Upload the selected file to AWS and get the URL
    try {
      if (formNum == 2) {
        if (
          carrierName != "" &&
          ipa != "" &&
          memberId != "" &&
          groupId != "" &&
          dateOfBirth != ""
        ) {
          fileUrl = await uploadToAws(selectedFile);
          if (fileUrl) {
            setinsurance_image(fileUrl);
            console.log("insurance form 1", {
              carrierName,
              ipa,
              memberId,
              groupId,
              dateOfBirth,
              link1: fileUrl,
            });

            setformNum(3);
          } else {
            // toast("Insurance Card Photo is required");
          }
        } else {
          toast("Please fill out all the fields.");
        }
      }
      if (formNum == 3) {
        if (
          relationship != "" &&
          subscriberFirstName != "" &&
          subscriberDob != "" &&
          city != "" &&
          state != ""
        ) {
          dispatch(loader(true));
          const user = JSON.parse(localStorage.getItem("user"));
          const insurance = {
            patient_id: user?._id,
            carrier_name: carrierName,
            ipa: ipa,
            relation_to_subscriber: relationship,
            member_id: memberId,
            group_id: groupId,
            firstname_subscriber: subscriberFirstName,
            lastname_subscriber: "",
            dob_insurance: dateOfBirth,
            dob_subscriber: subscriberDob,
            subscriber_address: subscriberAddress,
            subscriber_city: city,
            subscriber_state: state,
            link1: insurance_image,
            fileType1: selectedFile.type,
            link2: "examppleimage.com",
            fileType2: "",
          };

          const res = await patientService.saveInsuranceData(insurance);
          if (res) {
            showNotification(
              "success",
              "Information saved",
              "Insurance info is now saved."
            );
            setformNum(4);
          } else {
            showNotification(
              "error",
              "Something went wrong",
              "Insurance info is not saved. Something went wront. Please try agian"
            );
          }
          dispatch(loader(false));
        } else {
          toast("Please fill out all the fields.");
        }
      }
    } catch (err) {
      console.error("Error uploading file:", err);
      toast("Error uploading file. Please try again.");
    }
  };

  // Event handler for file input change

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (isImage(file)) {
      console.log("file", file);
      setSelectedFile(file); // Set the selected file to state

      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("preview url", reader.result);
        setPreviewUrl(reader.result); // Set the preview URL to state
      };

      if (file) {
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    } else {
      showNotification("error", "File not allowed", "Only images are allowed.");
    }
  };

  //

  //form 5

  async function getPrivacyPolicy() {
    const res = await staticContentService.getPrivacyPolicy();
    console.log("privacy policy", res);
    if (res.success) {
      console.log("==>", res);
      setprivacyPolicy(res.data);
    }
  }

  //
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      id="PatientAccountCreated"
      centered
    >
      <Modal.Body>
        <button type="button" className="CloseModal" onClick={handleClose}>
          &times;
        </button>
        {hideMain ? (
          <div className="LoginBox">
            <div className="LoginHead">
              <img src="/images/Congratulations.png" alt="Congratulations" />
            </div>
            <div className="AccountCreatBody">
              <article>
                <h3>Account Created</h3>
                <h4>
                  Congratulations your account has been created successfully
                </h4>
                <p>Now proceed filling your insurance details</p>
              </article>
            </div>
            <div className="StepBodyArea">
              <ul>
                <li
                  className="cursorPointer"
                  onClick={() => {
                    sethideMain(false);
                    showMultiStep(1);
                  }}
                >
                  <span>
                    <img src="/images/step-1.png" alt="Step 1" />
                  </span>
                  <h5>Step 1</h5>
                  <p>Profile Details</p>
                </li>
                {/* <li className="cursorPointer">
                  <span>
                    <img src="/images/step-2.png" alt="Step 2" />
                  </span>
                  <h5>Step 2</h5>
                  <p>Insurance Information</p>
                </li> */}
                <li className="cursorPointer">
                  <span>
                    <img src="/images/step-3.png" alt="Step 3" />
                  </span>
                  <h5>Step 2</h5>
                  <p>Review Our Policies</p>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {formNum == 1 ? (
          <>
            <div className="LoginBox">
              <div className="StepHead">
                <h6>
                  Step <strong>1</strong>/2
                </h6>
              </div>
              <div className="BookingModalArea">
                <h3 className="Title">Profile Details</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="LoginBody">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    <label>
                      <span style={{ color: "red" }}>*</span> First Name
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <label>
                      <span style={{ color: "red" }}>*</span> Last Name
                    </label>
                  </div>
                  <div className="SelectGender">
                    <div className="form-group">
                      <label>
                        <span style={{ color: "red" }}>*</span> Select Gender
                      </label>
                      <ul>
                        <li>
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            checked={gender === "male"}
                            onChange={(e) => setGender(e.target.value)}
                            required
                          />
                          <aside>
                            <span>Male</span>
                          </aside>
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="gender"
                            value="female"
                            checked={gender === "female"}
                            onChange={(e) => setGender(e.target.value)}
                            required
                          />
                          <aside>
                            <span>Female</span>
                          </aside>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="SelectGender">
                    <div className="form-group">
                      <label>
                        <span style={{ color: "red" }}>*</span> Date of Birth
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        max={today} // Set the max attribute to today’s date
                        required
                      />
                    </div>
                  </div>
                  <div className="SelectGender">
                    <div className="form-group">
                      <label>
                        <span style={{ color: "red" }}>*</span> Postal Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={postalCode}
                        onChange={(e) => setpostalCode(e.target.value)}
                        required
                      />
                      {/* Display an error message if the postal code format is invalid */}
                      {!/^\d{5}(-\d{4})?$/.test(postalCode) &&
                        postalCode !== "" && (
                          <small style={{ color: "red" }}>
                            Enter a ZIP code in the format XXXXX or XXXXX-XXXX.
                          </small>
                        )}
                    </div>
                  </div>
                </div>
                <div className="ButtonBox ButtonBoxNew">
                  <button className="Dark FolderPermissionId" type="submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : null}

        {formNum == 2 ? (
          <>
            <div className="LoginBox">
              <div className="StepHead">
                <h6>
                  Step <strong>2</strong>/3
                </h6>
              </div>
              <div className="BookingModalArea">
                <h3 className="Title">Insurance Information</h3>
              </div>
              <form onSubmit={handleSubmitForm}>
                <div className="LoginBody">
                  <label>Carrier Name</label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={carrierName}
                      onChange={(e) => setCarrierName(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Tricare West">Tricare West</option>
                    </select>
                  </div>
                  <label>Independent Physician Association (IPA)</label>
                  <div className="form-group">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={ipa}
                        onChange={(e) => setIpa(e.target.value)}
                      >
                        <option value="">Select IPA</option>
                        <option value="IPA1">IPA 1</option>
                        <option value="IPA2">IPA 2</option>
                        <option value="IPA3">IPA 3</option>
                        {/* Add more options as needed */}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={memberId}
                          onChange={(e) => setMemberId(e.target.value)}
                        />
                        <label>Member ID</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={groupId}
                          onChange={(e) => setGroupId(e.target.value)}
                        />
                        <label>Group ID/No.</label>
                      </div>
                    </div>
                  </div>
                  <div className="SelectGender">
                    <div className="form-group">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        max={today}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="SelectGender">
                      <label>Upload Insurance Card Photo</label>
                      <figure>
                        {previewUrl == "" ? (
                          <img
                            src="images/card.png"
                            alt="Default Insurance Card"
                            className="img-fluid"
                            style={{ maxWidth: "100%" }}
                          />
                        ) : (
                          <img
                            src={previewUrl}
                            alt="Insurance Card Preview"
                            className="img-fluid"
                            style={{ maxWidth: "100%" }}
                          />
                        )}
                      </figure>
                    </div>
                    <div className="ImageSize">
                      <figcaption>
                        <h4>Jpeg, Png &amp; PDF</h4>
                        <p>20 MB max</p>
                      </figcaption>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={handleFileChange} // Assigned handleFileChange directly
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ButtonBox ButtonBoxNew">
                  <button type="submit" className="Dark FolderPermissionId">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : null}

        {formNum == 3 ? (
          <div className="LoginBox">
            <div className="StepHead">
              <h6>
                Step <strong>2</strong>/3
              </h6>
            </div>
            <div className="ModalTopArea">
              <div className="BookingModalArea">
                <h3 className="Title">Subscriber Information</h3>
              </div>
              <form onSubmit={handleSubmitForm}>
                <div className="LoginBody">
                  <label>Patient’s Relationship to Subscriber</label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={relationship}
                      onChange={(e) => setRelationship(e.target.value)}
                    >
                      <option>Select</option>
                      <option>Husband</option>
                      <option>Wife</option>
                      <option>Brother</option>
                      <option>Sister</option>
                      <option>Son</option>
                      <option>Daughter</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={subscriberFirstName}
                      onChange={(e) => setSubscriberFirstName(e.target.value)}
                    />
                    <label>First Name</label>
                  </div>
                  <div className="SelectGender">
                    <div className="form-group">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        value={subscriberDob}
                        onChange={(e) => setSubscriberDob(e.target.value)}
                        max={today}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={subscriberAddress}
                      onChange={(e) => setSubscriberAddress(e.target.value)}
                    />
                    <label>Subscriber Address</label>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <label>City</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                        <label>State</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ButtonBox ButtonBoxNew">
                  <button type="submit" className="Dark">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : null}
        {formNum == 4 ? (
          <div className="LoginBox">
            <div className="LoginHead">
              <img src="images/Congratulations.png" />
            </div>
            <div className="AccountCreatBody">
              <article>
                <h3>Account Created</h3>
                <h4>
                  Congratulations your account has been created sucsessfully
                </h4>
                <p>Now proceed filling your insurance details</p>
              </article>
            </div>
            <div className="StepBodyArea">
              <ul>
                <li>
                  <span>
                    <img src="images/step-1.png" />
                  </span>
                  <h5>Step 1</h5>
                  <p>Profile Details</p>
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </li>
                {/* <li>
                  <span>
                    <img src="images/step-2.png" />
                  </span>
                  <h5>Step 2</h5>
                  <p>Insurance Information</p>
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </li> */}
                <li className="cursorPointer" onClick={() => setformNum(5)}>
                  <span>
                    <img src="images/step-3.png" />
                  </span>
                  <h5>Step 2</h5>
                  <p>Review Our Policies</p>
                </li>
              </ul>
            </div>
            <div className="ButtonBox ButtonBoxNew">
              <button className="Gray" onClick={() => setformNum(5)}>
                Next
              </button>
            </div>
          </div>
        ) : null}

        {formNum == 5 ? (
          <>
            <div className="LoginBox">
              <div className="StepHead">
                <h6>
                  Step <strong>2</strong>/2
                </h6>
              </div>
              <div className="AccountCreatBody">
                <article>
                  <h3>Review Our Policy</h3>
                </article>
                <div className="HippaCompliance">
                  <h6>Hippa Compliance</h6>
                  {parse(privacyPolicy[0].privacy_policy_content)}

                  <div className="my-4 mx-4">
                    <input
                      type="checkbox"
                      id="agreeCheckbox"
                      className="form-check-input"
                      checked={isChecked} // Bind checkbox to state
                      onChange={handleCheckboxChange} // Handle state change on checkbox toggle
                    />
                    <label
                      htmlFor="agreeCheckbox"
                      className="form-check-label ms-2"
                    >
                      Check to continue
                    </label>
                  </div>

                  <div className="ButtonBox ButtonBoxNew">
                    <button
                      className="Dark"
                      onClick={() => setformNum(7)}
                      disabled={!isChecked} // Disable button if checkbox is not checked
                    >
                      Agree &amp; Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {formNum == 6 ? (
          <div id="congModal" className="modal-body ">
            <div className="ModalTopArea">
              <div className="LoginBox">
                <div className="LoginHead">
                  <img src="images/Congratulations.png" />
                  <h3>Congratulation</h3>
                  <p>Your Profile is now completed</p>
                </div>
                <div className="ButtonBox ButtonBoxNew">
                  <button
                    className="Dark Button btn"
                    id="AccountCreated"
                    onClick={() => {
                      if (tractBooking == true) {
                        console.log("IF RAN");
                        dispatch(setLogin(false));
                        dispatch(setBookingPopup(true));
                        hide(false);
                      } else {
                        dispatch(setLogin(false));
                        console.log("ELSE RAN");
                        hide(false);
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {formNum == 7 ? (
          <div className="LoginBox">
            <div className="LoginHead">
              <img src="images/Congratulations.png" />
            </div>
            <div className="AccountCreatBody">
              <article>
                <h3>Account Created</h3>
                <h4>
                  Congratulations your account has been created sucsessfully
                </h4>
                <p>Now proceed filling your insurance details</p>
              </article>
            </div>
            <div className="StepBodyArea">
              <ul>
                <li>
                  <span>
                    <img src="images/step-1.png" />
                  </span>
                  <h5>Step 1</h5>
                  <p>Profile Details</p>
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </li>
                {/* <li>
                <span>
                  <img src="images/step-2.png" />
                </span>
                <h5>Step 2</h5>
                <p>Insurance Information</p>
                <span className="GreenCheck">
                  <i className="fa fa-check" aria-hidden="true" />
                </span>
              </li> */}
                <li className="cursorPointer">
                  <span>
                    <img src="images/step-3.png" />
                  </span>
                  <h5>Step 2</h5>
                  <p>Review Our Policies</p>
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </li>
              </ul>
            </div>
            <div className="ButtonBox ButtonBoxNew">
              <button className="Gray" onClick={() => setformNum(6)}>
                Next
              </button>
            </div>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default Congratulations;
