// import { Helmet } from "react-helmet";
// const LapReports = () => {
//   return (
//     <>
//       <Helmet>
//         {/* <link rel="stylesheet" href="/css/style.css" />

//         <link rel="stylesheet" href="/css/patient.css" /> */}
//       </Helmet>
//       <div className="Small-Wrapper">
//         <div className="container">
//           <div className="TitleBox">
//             <h4 className="Title">Lab Reports</h4>
//           </div>
//           <div className="LabReporsArea">
//             <article>
//               <a href="lab-reports-available.html">
//                 <aside>
//                   <figcaption>
//                     <h4>Blood Test</h4>
//                     <p>Vitamin Test</p>
//                   </figcaption>
//                   <span>
//                     <img src="../images/pdf.png" />
//                   </span>
//                 </aside>
//                 <aside>
//                   <p>Leelamani Hospital</p>
//                   <h6>24/04/24 12:44</h6>
//                 </aside>
//               </a>
//             </article>
//             <article>
//               <a href="lab-reports-available.html">
//                 <aside>
//                   <figcaption>
//                     <h4>Blood Test</h4>
//                     <p>Vitamin Test</p>
//                   </figcaption>
//                   <span>
//                     <img src="../images/pdf.png" />
//                   </span>
//                 </aside>
//                 <aside>
//                   <p>Leelamani Hospital</p>
//                   <h6>24/04/24 12:44</h6>
//                 </aside>
//               </a>
//             </article>
//             <article>
//               <a href="lab-reports-available.html">
//                 <aside>
//                   <figcaption>
//                     <h4>Blood Test</h4>
//                     <p>Vitamin Test</p>
//                   </figcaption>
//                   <span>
//                     <img src="../images/pdf.png" />
//                   </span>
//                 </aside>
//                 <aside>
//                   <p>Leelamani Hospital</p>
//                   <h6>24/04/24 12:44</h6>
//                 </aside>
//               </a>
//             </article>
//             <article>
//               <a href="lab-reports-available.html">
//                 <aside>
//                   <figcaption>
//                     <h4>Blood Test</h4>
//                     <p>Vitamin Test</p>
//                   </figcaption>
//                   <span>
//                     <img src="../images/pdf.png" />
//                   </span>
//                 </aside>
//                 <aside>
//                   <p>Leelamani Hospital</p>
//                   <h6>24/04/24 12:44</h6>
//                 </aside>
//               </a>
//             </article>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default LapReports;
import { Helmet } from "react-helmet";
import { emrService } from "../../../services/emrService";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  formatDate,
  getFormattedDate,
  startcsvdownload,
} from "../../../helper/helper";
import { loader } from "../../../store/LoaderSlice";

const LabReports = () => {
  const dispatch = useDispatch();
  const emr_id = useSelector((state) => state.user.emr_id);
  const [documents, setDocuments] = useState([]);
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");

  useEffect(() => {
    if (emr_id) {
      getCarePlan();
    }
  }, []);

  async function getCarePlan() {
    dispatch(loader(true));
    const res = await emrService.getLabReports(emr_id);
    if (res.success) {
      setDocuments(res.data);
      setNext(res.next);
      setPrev(res.prev);
    }
    dispatch(loader(false));
  }

  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Documents</h4>
          </div>
          {/* <div className="LabReporsArea">
            {documents.length ? (
              documents.map((item, index) => (
                <article>
                  <>{console.log("link=>", item.document)}</>
                  <a href="document-available.html">
                    <aside>
                      <figcaption>
                        <h4>{item?.metatags[1]}</h4>
                        <p>{item?.description}</p>
                      </figcaption>
                      <span>
                        <img src="../images/pdf.png" />
                      </span>
                    </aside>
                    <aside>
                      <p>
                        {getFormattedDate(item.updated_at, "YYYY-MM-DD hh:mm")}
                      </p>
                    </aside>
                  </a>
                </article>
              ))
            ) : (
              <div>No documents found</div>
            )}
          </div> */}
          <div className="LabReporsArea">
            {documents.length ? (
              documents.map((item, index) => (
                <article key={index}>
                  {console.log("link=>", item.document)}
                  <a href={item.document} download target="_blank">
                    <aside>
                      <figcaption>
                        <h4>{item?.metatags[1]}</h4>
                        <p>{item?.description}</p>
                      </figcaption>
                      <span>
                        <img src="../images/pdf.png" alt="PDF icon" />
                      </span>
                    </aside>
                    <aside>
                      <p>{formatDate(item.updated_at, "YYYY-MM-DD hh:mm A")}</p>
                    </aside>
                  </a>
                </article>
              ))
            ) : (
              <div>No documents found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LabReports;
