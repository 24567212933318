import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { doctorService } from "../../../services/doctorService";
import {
  calculateAge,
  getFormattedDate,
  showNotification,
} from "../../../helper/helper";
import { Link, useNavigate } from "react-router-dom";
import { videoCallService } from "../../../services/videoCallServices";
import audio from "../../../assets/audio.mp3";
import AgoraPlayer from "../../agoraCallPlayer/AgoraPlayer";
import { AgoraRoom } from "../../agoraCallPlayer/AgoraRoom";
import { patientService } from "../../../services/patientServices";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { Howl } from "howler";
import chatIcon from "../../../assets/imges/Chat.png";
const VideoCall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doctor = useSelector((state) => state.doctor);
  const [upcoming, setupcoming] = useState([]);
  const [completed, setcompleted] = useState([]);
  const [channel, setchannel] = useState("");
  const [agoraToken, setagoraToken] = useState("");
  const [initCall, setinitCall] = useState(false);
  const [patient_device_type, setpatient_device_type] = useState("");
  const [socket, setSocket] = useState(null);
  const [showcallinScreen, setshowcallinScreen] = useState(false);
  const [patient_id, setpatient_id] = useState("");
  const [patientFirstname, setpatientFirstname] = useState("");
  const [callincomingScreen, setcallincomingScreen] = useState(false);
  const [appointmentid, setappointmentid] = useState("");

  const sound1 = new Howl({
    src: [require("../../../assets/audio.mp3")],
    preload: true,
    volume: 1.0,
  });

  const [sound, setSound] = useState(sound1);

  // const SOCKET_URL = "http://3.13.196.242:5001";
  const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

  useEffect(() => {
    if (doctor?._id) {
      const socketIo = io(SOCKET_URL, {
        query: {
          id: doctor._id,
        },
        transports: ["websocket"],
      });

      setSocket(socketIo);

      socketIo.on("connect", () => {
        console.log("Connected to Socket.IO server");
      });

      socketIo.on("disconnect", (reason) => {
        console.log("Disconnected from Socket.IO server:", reason);
      });

      socketIo.on("call-incoming", (msg) => {
        console.log("call incoming", msg);
        sound.play();

        setpatientFirstname(msg.senderName);
        setpatient_id(msg.sender_id);

        getAgoraToken(msg.sender_id, doctor?._id);
      });

      socketIo.on("call-accepted", (msg) => {
        console.log("call received from notification", msg);
        setinitCall(true);
        setshowcallinScreen(false);
      });

      socketIo.on("call-declined", (msg) => {
        console.log("call declined", msg);
        setinitCall(false);
        setshowcallinScreen(false);
        toast("Call declined by Patient");
      });

      socketIo.on("call-cut-self", (msg) => {
        setinitCall(false);
        setshowcallinScreen(false);
      });

      socketIo.on("call-ended", (msg) => {
        sound.stop();
        setinitCall(false);
        setcallincomingScreen(false);
      });

      socketIo.on("call-connection-error", (msg) => {
        setinitCall(false);
        setcallincomingScreen(false);
        setshowcallinScreen(false);
        toast("Participant not available to join.");
      });

      // return () => {
      //   socketIo.disconnect();
      // };
    }
  }, [doctor]);

  useEffect(() => {
    // console.log("doc id", doctor._id);
    getAppointemnts();
  }, []);

  async function getAppointemnts() {
    dispatch(loader(true));
    const res = await doctorService.getAppointments(doctor?._id);
    console.log("appointments", res);
    const up_virtual = res?.upcoming?.filter(
      (item) => item?.appointment_type == "virtual"
    );
    const com_virtual = res?.completed?.filter(
      (item) => item?.appointment_type == "virtual"
    );
    if (up_virtual?.length) {
      setupcoming(up_virtual);
    }
    if (com_virtual?.length) {
      setcompleted(com_virtual);
    }
    dispatch(loader(false));
  }

  async function getChannelAndToken(
    patient_id,
    e,
    deviceType,
    fcmToken,
    apnToken,
    patient_firstname,
    appointment_id
  ) {
    console.log("APPOINTMENT ID =", appointment_id);
    e.preventDefault();
    setappointmentid(appointment_id);
    const res = await videoCallService.getChannel(patient_id, doctor?._id);

    setpatient_id(patient_id);
    setpatientFirstname(patient_firstname);
    if (res.success) {
      setchannel(res.channel);
      const res2 = await videoCallService.getToken(res.channel);
      if (res2.success) {
        setagoraToken(res2?.token);
        if (deviceType == "website") {
          console.log("device type website ran");
          socket.emit("start-call", {
            receiver_id: patient_id,
            sender_id: doctor?._id,
            senderName: doctor?.firstname,
          });
          setshowcallinScreen(true);
        }
        if (deviceType == "ios") {
          const data = {
            receiver_id: patient_id,
            sender_id: doctor?._id,

            receiver_role: "patient",
            sender_name: doctor?.firstname,
            title: "Video Meet",
            content: "Doctor Patient Video Meet",
          };

          const res = await videoCallService.sendCallNotification(data);
          if (res.success) {
            //enable call ring screen
          } else {
            showNotification(
              "error",
              "Call Not Connected",
              "Something went wrong not able to connect call."
            );
          }
          console.log("device type website ios");
          setshowcallinScreen(true);
        }
        if (deviceType == "android") {
          const data = {
            receiver_id: patient_id,
            sender_id: doctor?._id,

            receiver_role: "patient",
            sender_name: doctor?.firstname,
            title: "Video Meet",
            content: "Doctor Patient Video Meet",
          };

          const res = await videoCallService.sendCallNotification(data);
          if (res.success) {
            //enable call ring screen
          } else {
            showNotification(
              "error",
              "Call Not Connected",
              "Something went wrong not able to connect call."
            );
          }
          console.log("device type website android");
          setshowcallinScreen(true);
        }
      }
    } else {
      showNotification(
        "error",
        "Error Connectiong Call",
        "Unable to create channel or token"
      );
    }
  }

  async function getAgoraToken(patientId, doctorId) {
    const res = await videoCallService.getChannel(patientId, doctorId);
    if (res.success) {
      setchannel(res.channel);
      const res2 = await videoCallService.getToken(res.channel);
      if (res2.success) {
        setagoraToken(res2?.token);
        setcallincomingScreen(true);
      } else {
        console.log("unable to obtain agora token");
      }
    } else {
      console.log("unable to obtain channel id");
    }
  }

  return (
    <>
      {initCall ? (
        <AgoraRoom
          channel={channel}
          agoraToken={agoraToken}
          setinitCall={setinitCall}
          socket={socket}
          patient_id={patient_id}
          doctor_id={doctor?._id}
          doctor_name={doctor?.firstname}
          appointment_id={appointmentid}
        />
      ) : (
        <>
          {showcallinScreen ? (
            <div className="join-call-screen">
              <div className="caller-info text-center">
                <span className="caller-name text-capitalize">
                  {patientFirstname}
                </span>
                <br />

                <span className="text-white text-center">Ringing...</span>
              </div>
              <div className="call-buttons">
                <button
                  className="decline-button"
                  onClick={() => {
                    setshowcallinScreen(false);
                    socket.emit("call-cut-self", {
                      receiver_id: patient_id,
                      sender_id: doctor?._id,
                      senderName: doctor?.firstname,
                    });
                  }}
                >
                  End Call
                </button>
              </div>
            </div>
          ) : (
            <div className="DoctorWrapperArea">
              <div className="DoctorWrapper">
                <div className="TitleBox">
                  <h4>Video Calls</h4>
                </div>
                <div className="CommonTabs">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#Upcoming"
                      >
                        Upcoming
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Completed"
                      >
                        Completed
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div className="tab-pane active" id="Upcoming">
                    <div className="row">
                      {upcoming?.length ? (
                        upcoming?.map((item, index) => (
                          <div className="col-sm-4 text-capitalize">
                            <div className="AppoinmentBox ">
                              <a href="javascript:void(0)">
                                {item?.is_appointment_for_family ? (
                                  <div className="AppoinmentHead ">
                                    <aside>
                                      <h3>
                                        {console.log(
                                          "EMR ID",
                                          item?.patient_id?.emr_id
                                        )}
                                        <Link
                                          to={{
                                            pathname: `/doctor/patient-details`,
                                          }}
                                          state={{
                                            id: item?.patient_id?.emr_id,
                                            data: item,
                                          }}
                                        >
                                          {item?.family_member_id?.firstname}
                                        </Link>
                                      </h3>
                                      <ul>
                                        <li>
                                          {calculateAge(
                                            item?.family_member_id?.dob
                                          )}{" "}
                                          Years
                                        </li>
                                        <li>
                                          {item?.family_member_id?.gender}
                                        </li>
                                        <li>
                                          {item?.is_appointment_for_family
                                            ? "Family"
                                            : "Self"}
                                        </li>
                                      </ul>
                                    </aside>

                                    <button
                                      className="Blue"
                                      id="StartCall"
                                      onClick={(e) =>
                                        getChannelAndToken(
                                          item?.patient_id?._id,
                                          e,
                                          item?.patient_id?.device_type,
                                          item?.patient_id?.device_token,
                                          item?.patient_id?.apn_token,
                                          item.patient_id.firstname,
                                          item?._id
                                        )
                                      }
                                    >
                                      <span>
                                        <img src="../images/Video.png" />
                                      </span>{" "}
                                      Start Call{" "}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="AppoinmentHead">
                                    <aside>
                                      <h3>
                                        {console.log(
                                          "EMR ID",
                                          item?.patient_id?.emr_id
                                        )}
                                        <Link
                                          to={{
                                            pathname: `/doctor/patient-details`,
                                          }}
                                          state={{
                                            id: item?.patient_id?.emr_id,
                                            data: item,
                                          }}
                                        >
                                          {item?.patient_id?.firstname}
                                        </Link>
                                      </h3>
                                      <ul>
                                        <li>
                                          {calculateAge(item?.patient_id?.dob)}{" "}
                                          Years
                                        </li>
                                        <li>{item?.patient_id?.gender}</li>
                                        <li>
                                          {item?.is_appointment_for_family
                                            ? "Family"
                                            : "Self"}
                                        </li>
                                      </ul>
                                    </aside>

                                    <button
                                      className="Blue"
                                      id="StartCall"
                                      onClick={(e) =>
                                        getChannelAndToken(
                                          item?.patient_id?._id,
                                          e,
                                          item?.patient_id?.device_type,
                                          item?.patient_id?.device_token,
                                          item?.patient_id?.apn_token,
                                          item.patient_id.firstname,
                                          item?._id
                                        )
                                      }
                                    >
                                      <span>
                                        <img src="../images/Video.png" />
                                      </span>{" "}
                                      Start Call{" "}
                                    </button>
                                  </div>
                                )}
                              </a>
                              <div className="AppoinmentBody">
                                <ul>
                                  <li>
                                    <span>
                                      <img src="../images/Video.png" />
                                    </span>{" "}
                                    Virtual
                                  </li>
                                  <li>{item?.care_type} Care</li>
                                </ul>
                                <h6>{item?.reason_to_visit}</h6>
                              </div>
                              <div className="AppoinmentFooter">
                                <p>
                                  <span>
                                    <img src="../images/Clock.png" />
                                  </span>{" "}
                                  {getFormattedDate(
                                    item?.booking_date,
                                    "YYYY-MM-DD hh:mm A"
                                  )}
                                </p>
                                {/* <h6>Now</h6> */}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No upcoming appointmnets.</div>
                      )}

                      {/* <div className="col-sm-4">
          <a href="video-call-details.html">
            <div className="AppoinmentBox">
              <div className="AppoinmentHead">
                <aside>
                  <h3>John Smith</h3>
                  <ul>
                    <li>31 Years</li>
                    <li>Male</li>
                    <li>Self</li>
                  </ul>
                </aside>
                <button className="Gray" id="StartCall">
                  <span>
                    <img src="../images/Video.png" />
                  </span>{" "}
                  Start Call{" "}
                </button>
              </div>
              <div className="AppoinmentBody">
                <ul>
                  <li>
                    <span>
                      <img src="../images/Video.png" />
                    </span>{" "}
                    Virtual
                  </li>
                  <li>Primary Care</li>
                </ul>
                <h6>Reason For Call</h6>
              </div>
              <div className="AppoinmentFooter">
                <p>
                  <span>
                    <img src="../images/Clock.png" />
                  </span>{" "}
                  28 Nov 2023 06:30 pm
                </p>
                <h6>Now</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="col-sm-4">
          <a href="video-call-details.html">
            <div className="AppoinmentBox">
              <div className="AppoinmentHead">
                <aside>
                  <h3>John Smith</h3>
                  <ul>
                    <li>31 Years</li>
                    <li>Male</li>
                    <li>Self</li>
                  </ul>
                </aside>
                <button className="Blue" id="StartCall">
                  <span>
                    <img src="../images/Video.png" />
                  </span>{" "}
                  Start Call{" "}
                </button>
              </div>
              <div className="AppoinmentBody">
                <ul>
                  <li>
                    <span>
                      <img src="../images/Video.png" />
                    </span>{" "}
                    Virtual
                  </li>
                  <li>Primary Care</li>
                </ul>
                <h6>Reason For Call</h6>
              </div>
              <div className="AppoinmentFooter">
                <p>
                  <span>
                    <img src="../images/Clock.png" />
                  </span>{" "}
                  28 Nov 2023 06:30 pm
                </p>
                <h6>Now</h6>
              </div>
            </div>
          </a>
        </div> */}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="Completed">
                    <div>
                      <div className="row">
                        {completed?.length ? (
                          completed?.map((item, index) => (
                            <div className="col-sm-4">
                              <div className="AppoinmentBox">
                                <a href="javascript:void(0)">
                                  {item?.is_appointment_for_family ? (
                                    <div className="AppoinmentHead text-capitalize">
                                      <aside>
                                        <h3>
                                          {console.log(
                                            "EMR ID",
                                            item?.patient_id?.emr_id
                                          )}
                                          <Link
                                            to={{
                                              pathname: `/doctor/patient-details`,
                                            }}
                                            state={{
                                              id: item?.patient_id?.emr_id,
                                              data: item,
                                            }}
                                          >
                                            {item?.family_member_id?.firstname}{" "}
                                            {item?.family_member_id?.lastname}
                                          </Link>
                                        </h3>
                                        <ul>
                                          <li>
                                            {calculateAge(
                                              item?.family_member_id?.dob
                                            )}{" "}
                                            Years
                                          </li>
                                          <li>
                                            {item?.family_member_id?.gender}
                                          </li>
                                          <li>
                                            {item?.is_appointment_for_family
                                              ? "Family"
                                              : "Self"}
                                          </li>
                                        </ul>
                                      </aside>
                                      <Link
                                        to={`/doctor/chat?patient=${item?.patient_id?._id}&doctor=${item?.doctor_id}&appointment=${item?._id}`}
                                        className="Blue"
                                        id="StartCall"
                                      >
                                        <button className="Blue" id="StartChat">
                                          <span>
                                            <img src="../images/Video.png" />
                                          </span>
                                        </button>
                                        Chat
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className="AppoinmentHead text-capitalize">
                                      <aside>
                                        <h3>
                                          <Link
                                            to={{
                                              pathname: `/doctor/patient-details`,
                                            }}
                                            state={{
                                              id: item?.patient_id?.emr_id,
                                              data: item,
                                            }}
                                          >
                                            {item?.patient_id?.firstname}{" "}
                                            {item?.patient_id?.lastname}
                                          </Link>
                                        </h3>
                                        <ul>
                                          <li>
                                            {calculateAge(
                                              item?.patient_id?.dob
                                            )}{" "}
                                            Years
                                          </li>
                                          <li>{item?.patient_id?.gender}</li>
                                          <li>
                                            {item?.is_appointment_for_family
                                              ? "Family"
                                              : "Self"}
                                          </li>
                                        </ul>
                                      </aside>
                                      <Link
                                        to={`/doctor/chat?patient=${item?.patient_id?._id}&doctor=${item?.doctor_id}&appointment=${item?._id}`}
                                        className="Blue"
                                        id="StartCall"
                                        state={{
                                          participant: item.patient_id,
                                        }}
                                      >
                                        <button className="Blue" id="StartChat">
                                          <span className="">
                                            <img src={chatIcon} />
                                          </span>{" "}
                                          Chat
                                        </button>
                                      </Link>
                                    </div>
                                  )}
                                </a>
                                <div className="AppoinmentBody text-capitalize">
                                  <ul>
                                    <li>
                                      <span>
                                        <img src="../images/Video.png" />
                                      </span>{" "}
                                      Virtual
                                    </li>
                                    <li>{item?.care_type} Care</li>
                                  </ul>
                                  <h6>{item?.reason_to_visit}</h6>
                                </div>
                                <div className="AppoinmentFooter">
                                  <p>
                                    <span>
                                      <img src="../images/Clock.png" />
                                    </span>{" "}
                                    {getFormattedDate(
                                      item?.booking_date,
                                      "YYYY-MM-DD hh:mm A"
                                    )}
                                  </p>
                                  {/* <h6>Now</h6> */}
                                  <h6 className="text-nowrap">
                                    {item.is_cancelled
                                      ? "Status - Cancelled"
                                      : item.is_completed
                                      ? "Status - Completed"
                                      : null}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>No completed appointmnets.</div>
                        )}
                        {/* <div className="AppoinmentBox">
            <div className="AppoinmentHead">
              <aside>
                <h3>John Smith</h3>
                <ul>
                  <li>31 Years</li>
                  <li>Male</li>
                  <li>Self</li>
                </ul>
              </aside>
              <button className="Blue" id="StartChat">
                <span>
                  <img src="../images/Chat.png" />
                </span>{" "}
                Chat
              </button>
            </div>
            <div className="AppoinmentBody">
              <ul>
                <li>
                  <span>
                    <img src="../images/Video.png" />
                  </span>{" "}
                  Virtual
                </li>
                <li>Primary Care</li>
              </ul>
              <h6>Reason For Call</h6>
            </div>
            <div className="AppoinmentFooter">
              <p>
                <span>
                  <img src="../images/Clock.png" />
                </span>{" "}
                28 Nov 2023 06:30 pm
              </p>
              <h6>Now</h6>
            </div>
          </div> */}
                      </div>
                      {/* <div className="col-sm-4">
          <div className="AppoinmentBox">
            <div className="AppoinmentHead">
              <aside>
                <h3>John Smith</h3>
                <ul>
                  <li>31 Years</li>
                  <li>Male</li>
                  <li>Self</li>
                </ul>
              </aside>
              <button className="Blue">Prescription</button>
            </div>
            <div className="AppoinmentBody">
              <ul>
                <li>
                  <span>
                    <img src="../images/Video.png" />
                  </span>{" "}
                  Virtual
                </li>
                <li>Primary Care</li>
              </ul>
              <h6>Reason For Call</h6>
            </div>
            <div className="AppoinmentFooter">
              <p>
                <span>
                  <img src="../images/Clock.png" />
                </span>{" "}
                28 Nov 2023 06:30 pm
              </p>
              <h6>Now</h6>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="AppoinmentBox">
            <div className="AppoinmentHead">
              <aside>
                <h3>John Smith</h3>
                <ul>
                  <li>31 Years</li>
                  <li>Male</li>
                  <li>Self</li>
                </ul>
              </aside>
              <button className="Blue">Prescription</button>
            </div>
            <div className="AppoinmentBody">
              <ul>
                <li>
                  <span>
                    <img src="../images/Video.png" />
                  </span>{" "}
                  Virtual
                </li>
                <li>Primary Care</li>
              </ul>
              <h6>Reason For Call</h6>
            </div>
            <div className="AppoinmentFooter">
              <p>
                <span>
                  <img src="../images/Clock.png" />
                </span>{" "}
                28 Nov 2023 06:30 pm
              </p>
              <h6>Now</h6>
            </div>
          </div>
        </div> */}
                    </div>
                  </div>
                </div>
                {/* <div class="DataNoFound">
  <img src="../images/Telescope.png">
  <p>Currently You don’t have any appointments.</p>
</div> */}
              </div>
            </div>
          )}

          {callincomingScreen ? (
            <div className="join-call-screen">
              <div className="caller-info text-center">
                <span className="caller-name text-capitalize">
                  {patientFirstname}
                </span>
                <br />

                <span className="text-white text-center">Call Incoming...</span>
              </div>
              <div className="call-buttons">
                <button
                  className="decline-button"
                  onClick={() => {
                    sound.stop();
                    setcallincomingScreen(false);
                    socket.emit("decline-call", {
                      receiver_id: patient_id,
                      sender_id: doctor?._id,
                      senderName: doctor?.firstname,
                    });
                  }}
                >
                  Decline
                </button>
                <button
                  className="accept-button"
                  onClick={() => {
                    sound.stop();
                    setcallincomingScreen(false);
                    setinitCall(true);

                    socket.emit("accept-call", {
                      receiver_id: patient_id,
                      sender_id: doctor?._id,
                      senderName: doctor?.firstname,
                    });
                  }}
                >
                  Accept
                </button>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default VideoCall;
