import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loaderReducer from "./LoaderSlice";
import userReducer from "./UserSlice";
import doctorReducer from "./DoctorSlice";
import bookingReducer from "./ConsultationSlice";
import docPopupReducer from "./DocLoginSlice";
import agoraTokenReducer from "./AgoraTokenSlice";
import agoraChannelReducer from "./AgoraChannelSlice";
import agoraDoctorIdReducer from "./AgoraDoctorIdSlice";
import agoraPlayerReducer from "./AgoraPlayerSlice";
import loginPopupReducer from "./LoginPopupSlice";
import bookingPopupReducer from "./BookingPopUpSlice";
import appointmentTrackReducer from "./AppointmentOngoing";
import bookingTypeReducer from "./BookingTypeTrackSlice";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  doctor: doctorReducer,
  booking: bookingReducer,
  doctorpopup: docPopupReducer,

  agoraToken: agoraTokenReducer,
  agoraChannel: agoraChannelReducer,
  agoraDoctorId: agoraDoctorIdReducer,
  agoraPlayer: agoraPlayerReducer,
  login: loginPopupReducer,
  bookingPopup: bookingPopupReducer,
  appointmentTrack: appointmentTrackReducer,
  bookingType: bookingTypeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
