import { Helmet } from "react-helmet";
import { emrService } from "../../../services/emrService";
import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
const CorePlan = () => {
  const dispatch = useDispatch();
  const emr_id = useSelector((state) => state.user.emr_id);
  const [carePlans, setcarePlans] = useState([]);

  useEffect(() => {
    if (emr_id) {
      getCarePlan();
    }
  }, []);

  async function getCarePlan() {
    dispatch(loader(true));
    const res = await emrService.getCarePlan(emr_id);
    if (res.success) {
      setcarePlans(res.data);
    }
    dispatch(loader(false));
  }

  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      {/* <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Core Plan</h4>
          </div>
          <div className="CorePlanArea">
            <figure>
              <img src="../images/Slider.png" />
            </figure>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur{" "}
            </p>
          </div>
        </div>
      </div> */}

      <div className="Small-Wrapper">
        <div className="container">
          <div className="CorePlanArea">
            {carePlans.length ? (
              carePlans.map((item, index) => (
                <div key={index} className="care-plan-card my-3">
                  <div>
                    <strong>Title</strong> - {item?.title}
                  </div>
                  <div>
                    <strong>Instructions</strong> - {item?.instructions}
                  </div>
                  <div>
                    <strong>Schedule Date</strong> - {item?.scheduled_date}
                  </div>
                </div>
              ))
            ) : (
              <div>No care Plans Found.</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CorePlan;
