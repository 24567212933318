import { useState } from "react";
import { useEffect } from "react";
import { conversationService } from "../../../services/conversationService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import female from "../../../assets/imges/Avatar.png";
import male from "../../../assets/imges/Avatar-1.png";

const ChatHistory = () => {
  const doctor = useSelector((state) => state.doctor);
  const [chats, setchats] = useState([]);
  useEffect(() => {
    if (doctor?._id) {
      getChats(doctor?._id);
    }
  }, [doctor]);

  async function getChats(id) {
    try {
      const res = await conversationService.getDoctorChatHistory(id);
      if (res.success) {
        setchats(res.chats);
      } else {
        toast("No chats found");
      }
    } catch (err) {
      console.log(err.message);
    }
  }
  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Chat History</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>Messages</h4>
          </div>
          <div className="MessagesList">
            <ul>
              {chats.length ? (
                chats.map((item, index) => (
                  <li className="text-capitalize">
                    <Link
                      to={`/doctor/chat?patient=${item?.patient_name?.patient_id}&doctor=${doctor?._id}&appointment=${item?.appointment_id?._id}`}
                      state={{
                        participant: item.patient_name,
                      }}
                    >
                      <aside>
                        <span className="Icon">
                          <img
                            src={
                              item?.patient_name?.profile_pic
                                ? item?.patient_name?.profile_pic
                                : item?.patient_id?.gender?.toLowerCase() ===
                                  "male"
                                ? male
                                : female
                            }
                          />
                        </span>
                        {item?.unseen_message_doctor == 0 ? null : (
                          <span className="Count">
                            {item?.unseen_message_doctor}
                          </span>
                        )}
                        <h4>
                          {item?.patient_name?.firstname}&nbsp;
                          {item?.patient_name?.lastname}
                        </h4>
                        <ul>
                          <li>
                            <span>
                              <img
                                className="image-avatar2"
                                src={
                                  item?.patient_name?.profile_pic != ""
                                    ? item?.patient_name?.profile_pic
                                    : "../images/Video.png"
                                }
                              />
                            </span>{" "}
                            {item?.appointment_id?.appointment_type}
                          </li>
                          <li className="mx-1">
                            {item?.appointment_id?.care_type}
                          </li>
                        </ul>
                      </aside>
                      <p className="pl-5 mt-4">
                        {item?.last_message?.message_type == "text"
                          ? item?.last_message?.content
                          : "File"}
                      </p>
                    </Link>
                  </li>
                ))
              ) : (
                <div>No chats found</div>
              )}
              {/* <li>
                <aside>
                  <span className="Icon">
                    <img src="../images/Profile.png" />
                  </span>
                  <span className="Count">02</span>
                  <h4>Edmund Li</h4>
                  <ul>
                    <li>
                      <span>
                        <img src="../images/Video.png" />
                      </span>{" "}
                      Virtual
                    </li>
                    <li>Primary Care</li>
                  </ul>
                </aside>
                <p>
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </p>
              </li>
              <li>
                <aside>
                  <span className="Icon">
                    <img src="../images/Profile.png" />
                  </span>
                  <span className="Count">02</span>
                  <h4>Edmund Li</h4>
                  <ul>
                    <li>
                      <span>
                        <img src="../images/Video.png" />
                      </span>{" "}
                      Virtual
                    </li>
                    <li>Primary Care</li>
                  </ul>
                </aside>
                <p>
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </p>
              </li>
              <li>
                <aside>
                  <span className="Icon">
                    <img src="../images/Profile.png" />
                  </span>
                  <span className="Count">02</span>
                  <h4>Edmund Li</h4>
                  <ul>
                    <li>
                      <span>
                        <img src="../images/Video.png" />
                      </span>{" "}
                      Virtual
                    </li>
                    <li>Primary Care</li>
                  </ul>
                </aside>
                <p>
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </p>
              </li>
              <li>
                <aside>
                  <span className="Icon">
                    <img src="../images/Profile.png" />
                  </span>
                  <span className="Count">02</span>
                  <h4>Edmund Li</h4>
                  <ul>
                    <li>
                      <span>
                        <img src="../images/Clinic.png" />
                      </span>{" "}
                      Clinic Visit{" "}
                    </li>
                    <li>Primary Care</li>
                  </ul>
                </aside>
                <p>
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </p>
              </li>
              <li>
                <aside>
                  <span className="Icon">
                    <img src="../images/Profile.png" />
                  </span>
                  <span className="Count">02</span>
                  <h4>Edmund Li</h4>
                  <ul>
                    <li>
                      <span>
                        <img src="../images/Clinic.png" />
                      </span>{" "}
                      Clinic Visit{" "}
                    </li>
                    <li>Primary Care</li>
                  </ul>
                </aside>
                <p>
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatHistory;
