import { Helmet } from "react-helmet";
import styles from "../../../assets/doctor.css";
import styles2 from "../../../assets/style.css";
import { useState, useEffect } from "react";
import { loader } from "../../../store/LoaderSlice";
import { doctorService } from "../../../services/doctorService";
import { useSelector, useDispatch } from "react-redux";
const DoctorHome = () => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const doctorId = useSelector((state) => state.doctor._id);
  const [data, setdata] = useState({});

  // Set the current date when the component mounts
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    setCurrentDate(today);
    handleApplyClick();
  }, []);

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);
  const handleFilterByChange = (e) => setFilterBy(e.target.value);

  const handleApplyClick = async () => {
    // Handle the filter logic or send the data to your backend
    console.log("From:", fromDate, "To:", toDate, "Filter By:", filterBy);

    dispatch(loader(true));

    const res = await doctorService.getDoctorDashboardData(
      doctorId,
      fromDate,
      toDate,
      filterBy
    );

    if (res.success) {
      console.log("DASH", res.data);
      setdata(res.data);
    }
    dispatch(loader(false));
  };
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="../../../assets/doctor.css" />
        <link rel="stylesheet" href="../../../assets/style.css" /> */}
      </Helmet>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Dashboard</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>Dashboards</h4>
          </div>
          <div className="Title">
            <h4>Welcome to Your Dashboard</h4>
          </div>

          <div className="Filter">
            <div className="FilterLeft">
              <div className="form-group">
                <label>From</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  max={currentDate} // Ensure the date is not in the future
                />
              </div>
              <div className="form-group">
                <label>To</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate}
                  onChange={handleToDateChange}
                  max={currentDate} // Ensure the date is not in the future
                />
              </div>
            </div>
            <div className="FilterRight">
              <div className="form-group">
                <label>Select By</label>
                <select
                  className="form-control"
                  value={filterBy}
                  onChange={handleFilterByChange}
                >
                  <option value="">Select</option>
                  <option value="Today">Today</option>
                  <option value="Week">This Week</option>
                  <option value="Month">This Month</option>
                  <option value="Year">This Year</option>
                </select>
              </div>
              <div className="form-group">
                <label>&nbsp;</label>
                <button className="AddNewMemberBtn" onClick={handleApplyClick}>
                  Apply
                </button>
              </div>
            </div>
          </div>

          <div className="DashboardBox">
            <ul>
              <li>
                <aside>
                  <p>
                    Upcoming Virtual <br /> Call
                  </p>
                  <h6>{data.upcoming_virtual_calls}</h6>
                </aside>
              </li>
              <li>
                <aside>
                  <p>
                    Upcoming Clinic <br /> Visit{" "}
                  </p>
                  <h6>{data.upcoming_clinic_visits}</h6>
                </aside>
              </li>
              <li>
                <aside>
                  <p>
                    Completed Video Call <br /> Bookings
                  </p>
                  <h6>{data.completed_video_call_booking}</h6>
                </aside>
              </li>
              <li>
                <aside>
                  <p>
                    Completed Clinic Visit <br /> Bookings
                  </p>
                  <h6>{data.completed_clinic_visit_booking}</h6>
                </aside>
              </li>
              <li>
                <aside>
                  <p>
                    My <br /> Availabilities
                  </p>
                  <h6>{data.my_availablity}</h6>
                </aside>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorHome;
