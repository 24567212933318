import { Helmet } from "react-helmet";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { clearDoctor } from "../../store/DoctorSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Avatar from "../../assets/imges/Avatar-1.png";

const SidebarDoctor = () => {
  const doctor = useSelector((state) => state.doctor);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  function logout() {
    // dispatch(clearDoctor(null));
    // localStorage.clear();
    // setisLoggedIn(false);
    navigate("/");
  }

  return (
    <>
      <Helmet></Helmet>
      <div className="SideNavBox">
        <div className="SideNavHead">
          <Link to="/doctor/doctor-profile">
            <span className="Icon">
              <img src={doctor?.profile_pic ? doctor?.profile_pic : Avatar} />{" "}
            </span>
            <label>
              Hi, <span className="text-capitalize">{doctor?.firstname}</span>
            </label>
          </Link>
        </div>
        <div className="SideNavBody">
          <ul>
            <li
              className={
                location.pathname.includes("dashboard") ? "active" : null
              }
            >
              <Link to="/doctor/dashboard">
                <span className="Icon">
                  <img src="../images/Nav-1.png" />{" "}
                </span>
                <span className="Text">Dashboard</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("video-call") ? "active" : null
              }
            >
              <Link to="/doctor/video-call">
                <span className="Icon">
                  <img src="../images/Nav-2.png" />{" "}
                </span>
                <span className="Text">Video Call</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("clinic-visit") ? "active" : null
              }
            >
              <Link to="/doctor/clinic-visit">
                <span className="Icon">
                  <img src="../images/Nav-3.png" />{" "}
                </span>
                <span className="Text">Clinic Visit</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("availabilities") ? "active" : null
              }
            >
              <Link to="doctor/availabilities">
                <span className="Icon">
                  <img src="../images/Nav-4.png" />{" "}
                </span>
                <span className="Text">My Availabilities</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("settings") ? "active" : null
              }
            >
              <Link to="/doctor/settings">
                <span className="Icon">
                  <img src="../images/Nav-5.png" />{" "}
                </span>
                <span className="Text">Settings</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("chat-history") ? "active" : null
              }
            >
              <Link to="/doctor/chat-history">
                <span className="Icon">
                  <img src="../images/Nav-6.png" />{" "}
                </span>
                <span className="Text">Messages</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("support") ? "active" : null
              }
            >
              <a to="/doctor/suppot">
                <span className="Icon">
                  <img src="../images/Nav-7.png" />{" "}
                </span>
                <span className="Text">Help &amp; Support</span>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#DoctorLogoutModal"
              >
                <span className="Icon">
                  <img src="../images/Nav-8.png" />{" "}
                </span>
                <span className="Text" onClick={logout}>
                  Logout
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="SideNavFooter">
          <Link to="/doctor/dashboard">
            <img src="../images/Logo.png" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default SidebarDoctor;
