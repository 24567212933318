import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const HomepageSlider = () => {
  return (
    <>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav
        autoplay
        autoplayTimeout={3000}
        autoplayHoverPause
      >
        <div className="item">
          <div className="MembershipBox">
            <span className="Icon">
              <img src={`/images/Member-1.png`} alt="Member 1" />
            </span>
            <h5>Urgent Virtual Care</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
            </p>
            <a href="javascript:void(0);">
              <i className="fa fa-angle-right" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="item">
          <div className="MembershipBox">
            <span className="Icon">
              <img src={`/images/Member-2.png`} alt="Member 2" />
            </span>
            <h5>Renowned Experts</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
            </p>
            <a href="javascript:void(0);">
              <i className="fa fa-angle-right" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="item">
          <div className="MembershipBox">
            <span className="Icon">
              <img src={`/images/Member-3.png`} alt="Member 3" />
            </span>
            <h5>Chat with our Health Team</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
            </p>
            <a href="javascript:void(0);">
              <i className="fa fa-angle-right" aria-hidden="true" />
            </a>
          </div>
        </div>
      </OwlCarousel>
    </>
  );
};

export default HomepageSlider;
