import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import xray from "../../assets/imges/X-Ray.svg";
import pharmacy from "../../assets/imges/Pharmacy.svg";
import labs from "../../assets/imges/Labs.svg";
import primaryHealthcare from "../../assets/imges/Primary Healthcare.svg";
import urgentHealthcare from "../../assets/imges/Urgent Healthcare.svg";
import ivTherapy from "../../assets/imges/IV Therapy.svg";
import healthManagerServices from "../../assets/imges/Helath Manager Services.svg";

const ServicesSlider = () => {
  return (
    <>
      <div className="d-flex justify-content-center my-1 py-1 MembershipHead">
        <p>Dedicated healthcare manager</p>
      </div>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav
        autoplay
        autoplayTimeout={3000}
        autoplayHoverPause
        items={4} // Show 4 items by default
        slideBy={1} // Scroll 1 item at a time
        responsive={{
          0: { items: 1 }, // Show 1 item on small screens
          768: { items: 2 }, // Show 2 items on tablets
          1024: { items: 4 }, // Show 4 items on desktops
        }}
      >
        <div className="item text-center">
          <img className="serviceImg" src={xray} alt="X-Ray" />
          <h className="serviceText">X-Ray</h>
        </div>
        <div className="item text-center">
          <img className="serviceImg" src={pharmacy} alt="Pharmacy" />
          <h6 className="serviceText">Pharmacy</h6>
        </div>
        <div className="item text-center">
          <img className="serviceImg" src={labs} alt="Labs" />
          <h6 className="serviceText">Labs</h6>
        </div>
        <div className="item text-center">
          <img
            className="serviceImg"
            src={primaryHealthcare}
            alt="Primary Healthcare"
          />
          <h6 className="serviceText">Primary Healthcare</h6>
        </div>
        <div className="item text-center">
          <img
            className="serviceImg"
            src={urgentHealthcare}
            alt="Urgent Healthcare"
          />
          <h6 className="serviceText">Urgent Care</h6>
        </div>
        <div className="item text-center">
          <img className="serviceImg" src={ivTherapy} alt="IV Therapy" />
          <h6 className="serviceText">IV Therapy</h6>
        </div>
        <div className="item text-center">
          <img
            className="serviceImg"
            src={healthManagerServices}
            alt="Health Manager Services"
          />
          <h6 className="serviceText">Health Management</h6>
        </div>
      </OwlCarousel>
    </>
  );
};

export default ServicesSlider;
