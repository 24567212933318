import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { patientService } from "../../services/patientServices";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { setUser } from "../../store/UserSlice";
import { uploadToAws } from "../../helper/s3Helper";
import { showNotification } from "../../helper/helper";
import moment from "moment";
const EditMyProfile = ({ show, hide, user, setuser }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [profilePic, setprofilePic] = useState("");

  const today = moment().format("YYYY-MM-DD");

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstname || "");
      setLastName(user.lastname || "");
      setGender(user.gender || "");
      setDob(user.dob ? formatDate(user.dob) : ""); // Format the date here
      setAddress(user.address || "");
      setCity(user.city || "");
      setState(user.state || "");
    }
  }, [user]);

  useEffect(() => {
    if (profilePic != "") {
      updateProfilePic();
    }
  }, [profilePic]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return `${year}-${month}-${day}`;
  };

  const handleSave = async () => {
    dispatch(loader(true));

    const updatedUser = {
      ...user,
      firstname: firstName,
      lastname: lastName,
      gender,
      dob: new Date(dob + "T00:00:00.000Z"), // Ensure correct format for backend
      address,
      city,
      state,
      patient_id: user?._id,
    };

    const res = await patientService.updatePatient(updatedUser);

    if (res.status) {
      setuser(res.data);
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch(setUser(res.data));
      handleClose();
      showNotification(
        "success",
        "Profile updated",
        "Your profile details were updated successfuly"
      );
    }

    dispatch(loader(false));

    // handleClose();
  };

  const updateProfilePic = async () => {
    const profile_pic = await uploadToAws(profilePic);

    dispatch(loader(true));
    const updatedUser = {
      ...user,
      profile_pic,
      patient_id: user?._id,
    };

    const res = await patientService.updatePatient(updatedUser);

    if (res.status) {
      setuser({ ...user, profile_pic: profile_pic });
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch(setUser(res.data));
    }

    dispatch(loader(false));

    // handleClose();
  };

  const handleClose = () => {
    hide(false);
  };

  return (
    <Modal show={show} onHide={handleClose} id="EditMyProfile" centered>
      <Modal.Body>
        <button type="button" className="CloseModal" onClick={handleClose}>
          &times;
        </button>
        <div className="ModalTopArea">
          <div className="LoginBox">
            <div className="BookingModalArea">
              <h3 className="Title">Edit Profile</h3>
            </div>
            <Form>
              <div className="LoginBody">
                <div className="EditProfileFigure">
                  <figure>
                    <img
                      src={
                        user?.profile_pic == ""
                          ? "../images/Avatar.png"
                          : user?.profile_pic
                      }
                      alt="Profile Avatar"
                    />
                    <div className="InputBox">
                      <img src="../images/edit-icon.png" alt="Edit Icon" />
                      <input
                        type="file"
                        onChange={(e) => {
                          setprofilePic(e.target.files[0]);
                        }}
                      />
                    </div>
                  </figure>
                </div>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="">
                  <div className="SelectGender d-flex align-content-center mt-3">
                    <Form.Label className="">Select Gender</Form.Label>
                    <Form.Check
                      inline
                      type="radio"
                      className="ml-3"
                      label="Male"
                      name="gender"
                      checked={gender === "male"}
                      onChange={() => setGender("male")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Female"
                      name="gender"
                      checked={gender === "female"}
                      onChange={() => setGender("female")}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    max={today}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="ButtonBox">
                <Button
                  variant="dark"
                  className="FolderPermissionId"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditMyProfile;
