import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { doctorService } from "../../../services/doctorService";
import { calculateAge, getFormattedDate } from "../../../helper/helper";
import { Link } from "react-router-dom";
const VideoCall = () => {
  const dispatch = useDispatch();
  const doctor = useSelector((state) => state.doctor);
  const [upcoming, setupcoming] = useState([]);
  const [completed, setcompleted] = useState([]);

  useEffect(() => {
    // console.log("doc id", doctor._id);
    getAppointemnts();
  }, []);

  async function getAppointemnts() {
    dispatch(loader(true));
    const res = await doctorService.getAppointments(doctor?._id);
    console.log("appointments", res);
    const up_virtual = res?.upcoming?.filter(
      (item) => item?.appointment_type == "clinic"
    );
    const com_virtual = res?.completed?.filter(
      (item) => item?.appointment_type == "clinic"
    );
    if (up_virtual?.length) {
      setupcoming(up_virtual);
    }
    if (com_virtual?.length) {
      setcompleted(com_virtual);
    }
    dispatch(loader(false));
  }
  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Clinic Visit</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>Clinic Visit</h4>
          </div>
          <div className="CommonTabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Upcoming"
                >
                  Upcoming
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Completed">
                  Completed
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="Upcoming">
              <div className="row">
                {upcoming?.length ? (
                  upcoming?.map((item, index) => (
                    <div className="col-sm-4">
                      <div className="AppoinmentBox">
                        <a href="video-call-details.html">
                          <div className="AppoinmentHead">
                            <aside>
                              <Link
                                to={{
                                  pathname: `/doctor/patient-details`,
                                }}
                                state={{
                                  id: item?.patient_id?.emr_id,
                                  data: item,
                                }}
                              >
                                <h3>{item?.patient_id?.firstname}</h3>
                              </Link>
                              <ul>
                                <li>
                                  {calculateAge(item?.patient_id?.dob)} Years
                                </li>
                                <li>{item?.patient_id?.gender}</li>
                                <li>
                                  {item?.is_appointment_for_family
                                    ? "Family"
                                    : "Self"}
                                </li>
                              </ul>
                            </aside>
                            {/* <button className="Blue" id="StartCall">
                              <span>
                                <img src="../images/Video.png" />
                              </span>{" "}
                              Start Call{" "}
                            </button> */}
                          </div>
                        </a>
                        <div className="AppoinmentBody">
                          <ul>
                            <li>
                              <span>
                                <img src="../images/Clinic.png" />
                              </span>{" "}
                              Clinic Visit
                            </li>
                            <li>{item?.care_type} Care</li>
                          </ul>
                          <h6>{item?.reason_to_visit}</h6>
                        </div>
                        <div className="AppoinmentFooter">
                          <p>
                            <span>
                              <img src="../images/Clock.png" />
                            </span>{" "}
                            {getFormattedDate(
                              item?.booking_date,
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </p>
                          {/* <h6>Now</h6> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No upcoming appointmnets.</div>
                )}

                {/* <div className="col-sm-4">
                  <a href="video-call-details.html">
                    <div className="AppoinmentBox">
                      <div className="AppoinmentHead">
                        <aside>
                          <h3>John Smith</h3>
                          <ul>
                            <li>31 Years</li>
                            <li>Male</li>
                            <li>Self</li>
                          </ul>
                        </aside>
                        <button className="Gray" id="StartCall">
                          <span>
                            <img src="../images/Video.png" />
                          </span>{" "}
                          Start Call{" "}
                        </button>
                      </div>
                      <div className="AppoinmentBody">
                        <ul>
                          <li>
                            <span>
                              <img src="../images/Video.png" />
                            </span>{" "}
                            Virtual
                          </li>
                          <li>Primary Care</li>
                        </ul>
                        <h6>Reason For Call</h6>
                      </div>
                      <div className="AppoinmentFooter">
                        <p>
                          <span>
                            <img src="../images/Clock.png" />
                          </span>{" "}
                          28 Nov 2023 06:30 pm
                        </p>
                        <h6>Now</h6>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-4">
                  <a href="video-call-details.html">
                    <div className="AppoinmentBox">
                      <div className="AppoinmentHead">
                        <aside>
                          <h3>John Smith</h3>
                          <ul>
                            <li>31 Years</li>
                            <li>Male</li>
                            <li>Self</li>
                          </ul>
                        </aside>
                        <button className="Blue" id="StartCall">
                          <span>
                            <img src="../images/Video.png" />
                          </span>{" "}
                          Start Call{" "}
                        </button>
                      </div>
                      <div className="AppoinmentBody">
                        <ul>
                          <li>
                            <span>
                              <img src="../images/Video.png" />
                            </span>{" "}
                            Virtual
                          </li>
                          <li>Primary Care</li>
                        </ul>
                        <h6>Reason For Call</h6>
                      </div>
                      <div className="AppoinmentFooter">
                        <p>
                          <span>
                            <img src="../images/Clock.png" />
                          </span>{" "}
                          28 Nov 2023 06:30 pm
                        </p>
                        <h6>Now</h6>
                      </div>
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="tab-pane fade" id="Completed">
              <div className="row">
                <div className="col-sm-4">
                  {completed?.length ? (
                    completed?.map((item, index) => (
                      <div className="AppoinmentBox">
                        <a href="video-call-details.html">
                          <div className="AppoinmentHead">
                            <aside>
                              <Link
                                to={{
                                  pathname: `/doctor/patient-details`,
                                }}
                                state={{
                                  id: item?.patient_id?.emr_id,
                                  data: item,
                                }}
                              >
                                <h3>
                                  {item?.patient_id?.firstname}{" "}
                                  {item?.patient_id?.lastname}
                                </h3>
                              </Link>
                              <ul>
                                <li>
                                  {calculateAge(item?.patient_id?.dob)} Years
                                </li>
                                <li>{item?.patient_id?.gender}</li>
                                <li>
                                  {item?.is_appointment_for_family
                                    ? "Family"
                                    : "Self"}
                                </li>
                              </ul>
                            </aside>
                            {/* <button className="Blue" id="StartCall">
                              <span>
                                <img src="../images/Video.png" />
                              </span>{" "}
                              Chat
                            </button> */}
                          </div>
                        </a>
                        <div className="AppoinmentBody">
                          <ul>
                            <li>
                              <span>
                                <img src="../images/Video.png" />
                              </span>{" "}
                              Clinic Visit
                            </li>
                            <li>{item?.care_type} Care</li>
                          </ul>
                          <h6>{item?.reason_to_visit}</h6>
                        </div>
                        <div className="AppoinmentFooter">
                          <p>
                            <span>
                              <img src="../images/Clock.png" />
                            </span>{" "}
                            {getFormattedDate(
                              item?.booking_date,
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </p>
                          {/* <h6>Now</h6> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No completed appointmnets.</div>
                  )}
                  {/* <div className="AppoinmentBox">
                    <div className="AppoinmentHead">
                      <aside>
                        <h3>John Smith</h3>
                        <ul>
                          <li>31 Years</li>
                          <li>Male</li>
                          <li>Self</li>
                        </ul>
                      </aside>
                      <button className="Blue" id="StartChat">
                        <span>
                          <img src="../images/Chat.png" />
                        </span>{" "}
                        Chat
                      </button>
                    </div>
                    <div className="AppoinmentBody">
                      <ul>
                        <li>
                          <span>
                            <img src="../images/Video.png" />
                          </span>{" "}
                          Virtual
                        </li>
                        <li>Primary Care</li>
                      </ul>
                      <h6>Reason For Call</h6>
                    </div>
                    <div className="AppoinmentFooter">
                      <p>
                        <span>
                          <img src="../images/Clock.png" />
                        </span>{" "}
                        28 Nov 2023 06:30 pm
                      </p>
                      <h6>Now</h6>
                    </div>
                  </div> */}
                </div>
                {/* <div className="col-sm-4">
                  <div className="AppoinmentBox">
                    <div className="AppoinmentHead">
                      <aside>
                        <h3>John Smith</h3>
                        <ul>
                          <li>31 Years</li>
                          <li>Male</li>
                          <li>Self</li>
                        </ul>
                      </aside>
                      <button className="Blue">Prescription</button>
                    </div>
                    <div className="AppoinmentBody">
                      <ul>
                        <li>
                          <span>
                            <img src="../images/Video.png" />
                          </span>{" "}
                          Virtual
                        </li>
                        <li>Primary Care</li>
                      </ul>
                      <h6>Reason For Call</h6>
                    </div>
                    <div className="AppoinmentFooter">
                      <p>
                        <span>
                          <img src="../images/Clock.png" />
                        </span>{" "}
                        28 Nov 2023 06:30 pm
                      </p>
                      <h6>Now</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="AppoinmentBox">
                    <div className="AppoinmentHead">
                      <aside>
                        <h3>John Smith</h3>
                        <ul>
                          <li>31 Years</li>
                          <li>Male</li>
                          <li>Self</li>
                        </ul>
                      </aside>
                      <button className="Blue">Prescription</button>
                    </div>
                    <div className="AppoinmentBody">
                      <ul>
                        <li>
                          <span>
                            <img src="../images/Video.png" />
                          </span>{" "}
                          Virtual
                        </li>
                        <li>Primary Care</li>
                      </ul>
                      <h6>Reason For Call</h6>
                    </div>
                    <div className="AppoinmentFooter">
                      <p>
                        <span>
                          <img src="../images/Clock.png" />
                        </span>{" "}
                        28 Nov 2023 06:30 pm
                      </p>
                      <h6>Now</h6>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div class="DataNoFound">
          <img src="../images/Telescope.png">
          <p>Currently You don’t have any appointments.</p>
      </div> */}
        </div>
      </div>
    </>
  );
};

export default VideoCall;

// const ClinicVisit = () => {
//   return (
//     <>
//       <div className="DoctorWrapperArea">
//         <div className="DoctorWrapper">
//           <div className="TitleBox">
//             <h4>Clinic Visit</h4>
//           </div>
//           <div className="CommonTabs">
//             <ul className="nav nav-tabs">
//               <li className="nav-item">
//                 <a
//                   className="nav-link active"
//                   data-toggle="tab"
//                   href="#Upcoming"
//                 >
//                   Upcoming
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link" data-toggle="tab" href="#Completed">
//                   Completed
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="tab-content">
//             <div className="tab-pane active" id="Upcoming">
//               <div className="row">
//                 <div className="col-sm-4">
//                   <div className="AppoinmentBox">
//                     <div className="AppoinmentHead">
//                       <aside>
//                         <h3>John Smith</h3>
//                         <ul>
//                           <li>31 Years</li>
//                           <li>Male</li>
//                           <li>Self</li>
//                         </ul>
//                       </aside>
//                     </div>
//                     <div className="AppoinmentBody">
//                       <ul>
//                         <li>
//                           <span>
//                             <img src="../images/Clinic.png" />
//                           </span>{" "}
//                           Clinic Visit{" "}
//                         </li>
//                         <li>Primary Care</li>
//                       </ul>
//                       <h6>Reason For Visit</h6>
//                     </div>
//                     <div className="AppoinmentFooter">
//                       <p>
//                         <span>
//                           <img src="../images/Clock.png" />
//                         </span>{" "}
//                         28 Nov 2023 06:30 pm
//                       </p>
//                       <h6>Now</h6>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-sm-4">
//                   <div className="AppoinmentBox">
//                     <div className="AppoinmentHead">
//                       <aside>
//                         <h3>John Smith</h3>
//                         <ul>
//                           <li>31 Years</li>
//                           <li>Male</li>
//                           <li>Self</li>
//                         </ul>
//                       </aside>
//                     </div>
//                     <div className="AppoinmentBody">
//                       <ul>
//                         <li>
//                           <span>
//                             <img src="../images/Clinic.png" />
//                           </span>{" "}
//                           Clinic Visit{" "}
//                         </li>
//                         <li>Primary Care</li>
//                       </ul>
//                       <h6>Reason For Visit</h6>
//                     </div>
//                     <div className="AppoinmentFooter">
//                       <p>
//                         <span>
//                           <img src="../images/Clock.png" />
//                         </span>{" "}
//                         28 Nov 2023 06:30 pm
//                       </p>
//                       <h6>Now</h6>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-sm-4">
//                   <div className="AppoinmentBox">
//                     <div className="AppoinmentHead">
//                       <aside>
//                         <h3>John Smith</h3>
//                         <ul>
//                           <li>31 Years</li>
//                           <li>Male</li>
//                           <li>Self</li>
//                         </ul>
//                       </aside>
//                     </div>
//                     <div className="AppoinmentBody">
//                       <ul>
//                         <li>
//                           <span>
//                             <img src="../images/Clinic.png" />
//                           </span>{" "}
//                           Clinic Visit{" "}
//                         </li>
//                         <li>Primary Care</li>
//                       </ul>
//                       <h6>Reason For Visit</h6>
//                     </div>
//                     <div className="AppoinmentFooter">
//                       <p>
//                         <span>
//                           <img src="../images/Clock.png" />
//                         </span>{" "}
//                         28 Nov 2023 06:30 pm
//                       </p>
//                       <h6>Now</h6>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="tab-pane fade" id="Completed">
//               <div className="row">
//                 <div className="col-sm-4">
//                   <div className="AppoinmentBox">
//                     <div className="AppoinmentHead">
//                       <aside>
//                         <h3>John Smith</h3>
//                         <ul>
//                           <li>31 Years</li>
//                           <li>Male</li>
//                           <li>Self</li>
//                         </ul>
//                       </aside>
//                       <button className="Blue" id="StartChat">
//                         <span>
//                           <img src="../images/Chat.png" />
//                         </span>{" "}
//                         Chat
//                       </button>
//                     </div>
//                     <div className="AppoinmentBody">
//                       <ul>
//                         <li>
//                           <span>
//                             <img src="../images/Clinic.png" />
//                           </span>{" "}
//                           Clinic Visit{" "}
//                         </li>
//                         <li>Primary Care</li>
//                       </ul>
//                       <h6>Reason For Visit</h6>
//                     </div>
//                     <div className="AppoinmentFooter">
//                       <p>
//                         <span>
//                           <img src="../images/Clock.png" />
//                         </span>{" "}
//                         28 Nov 2023 06:30 pm
//                       </p>
//                       <h6>Now</h6>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-sm-4">
//                   <div className="AppoinmentBox">
//                     <div className="AppoinmentHead">
//                       <aside>
//                         <h3>John Smith</h3>
//                         <ul>
//                           <li>31 Years</li>
//                           <li>Male</li>
//                           <li>Self</li>
//                         </ul>
//                       </aside>
//                       <button className="Blue">Prescription</button>
//                     </div>
//                     <div className="AppoinmentBody">
//                       <ul>
//                         <li>
//                           <span>
//                             <img src="../images/Clinic.png" />
//                           </span>{" "}
//                           Clinic Visit{" "}
//                         </li>
//                         <li>Primary Care</li>
//                       </ul>
//                       <h6>Reason For Visit</h6>
//                     </div>
//                     <div className="AppoinmentFooter">
//                       <p>
//                         <span>
//                           <img src="../images/Clock.png" />
//                         </span>{" "}
//                         28 Nov 2023 06:30 pm
//                       </p>
//                       <h6>Now</h6>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-sm-4">
//                   <div className="AppoinmentBox">
//                     <div className="AppoinmentHead">
//                       <aside>
//                         <h3>John Smith</h3>
//                         <ul>
//                           <li>31 Years</li>
//                           <li>Male</li>
//                           <li>Self</li>
//                         </ul>
//                       </aside>
//                       <button className="Blue">Prescription</button>
//                     </div>
//                     <div className="AppoinmentBody">
//                       <ul>
//                         <li>
//                           <span>
//                             <img src="../images/Clinic.png" />
//                           </span>{" "}
//                           Clinic Visit{" "}
//                         </li>
//                         <li>Primary Care</li>
//                       </ul>
//                       <h6>Reason For Visit</h6>
//                     </div>
//                     <div className="AppoinmentFooter">
//                       <p>
//                         <span>
//                           <img src="../images/Clock.png" />
//                         </span>{" "}
//                         28 Nov 2023 06:30 pm
//                       </p>
//                       <h6>Now</h6>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* <div class="DataNoFound">
//           <img src="../images/Telescope.png">
//           <p>Currently You don’t have any appointments.</p>
//       </div> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ClinicVisit;
