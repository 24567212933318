import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const appointmentServices = {
  getDocWithSlots: getDocWithSlots,
  bookappointment: bookappointment,
  getUpcomingAppointments: getUpcomingAppointments,
  getCompletedAppointments: getCompletedAppointments,
  getDocWithSlotsVirtualPrimary: getDocWithSlotsVirtualPrimary,
  bookVirtualPrimary: bookVirtualPrimary,
  cancelAppointment: cancelAppointment,
  getNextSlotTime: getNextSlotTime,
  getCancelledAppintments: getCancelledAppintments,
  getDocWithSlotsForClinicPrimary: getDocWithSlotsForClinicPrimary,
  getDocWithSlotsForClinicUrgent: getDocWithSlotsForClinicUrgent,
  completeAppointment: completeAppointment,
};

async function getDocWithSlots(day, date) {
  console.log(day, date);
  try {
    const result = await axiosInstance.get(
      `/api/doctor/get-all-doctor?day=${day.toString()}&date=${date.toString()}&care_type=urgent&visit_type=virtual`
    );
    console.log("doc and slots", result);
    return result.data.docs;
  } catch (err) {
    console.log(err.message);
    return [];
  }
}

async function getNextSlotTime(day, care, visit) {
  try {
    const result = await axiosInstance.get(
      `/api/appointment/get-next-slot-time?day=${day.toLowerCase()}&visit_type=${care.toLowerCase()}&care_type=${visit.toLowerCase()}`
    );
    console.log("next time", result);
    {
      if (result.data.success) {
        return result.data.closest_time;
      } else {
        return "";
      }
    }
  } catch (err) {
    console.log(err.message);
    return "";
  }
}

async function bookappointment(data) {
  try {
    const result = await axiosInstance.post(
      `/api/appointment/create-appointment`,
      data
    );
    console.log("appointment==>", result);
    if (result.data.success) {
      return { success: true, msg: result.data.message };
    } else if (!result.data.success) {
      return { success: false, msg: result.data.message };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getUpcomingAppointments(patient_id, type) {
  try {
    const result = await axiosInstance.get(
      `/api/appointment/get-upcoming-appointments-for-patient?patient_id=${patient_id}&appointment=${type}`
    );
    console.log("upcoming appointments", result);
    if (result.status == 200 && result.data.upcoming_appointmnets.length) {
      return { success: true, data: result.data.upcoming_appointmnets };
    } else {
      return { success: false, data: [] };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getCompletedAppointments(patient_id, type) {
  try {
    const result = await axiosInstance.get(
      `/api/appointment/get-completed-appointments-for-patient?patient_id=${patient_id}&appointment=${type}`
    );
    console.log("completed appointments", result);
    if (result.status == 200 && result.data.completed_appointmnets.length) {
      return { success: true, data: result.data.completed_appointmnets };
    } else {
      return { success: false, data: [] };
    }
    return result.data.docs;
  } catch (err) {
    console.log(err.message);
  }
}

async function getCancelledAppintments(patient_id, type) {
  try {
    const result = await axiosInstance.get(
      `/api/appointment/get-cancel-appointment?patient_id=${patient_id}`
    );
    console.log("completed appointments", result);
    if (result.status == 200 && result.data.appointments.length) {
      return { success: true, data: result.data.appointments };
    } else {
      return { success: false, data: [] };
    }
    return result.data.docs;
  } catch (err) {
    console.log(err.message);
  }
}

async function getDocWithSlotsVirtualPrimary(day, date) {
  console.log(day, date);
  try {
    const result = await axiosInstance.get(
      `/api/doctor/get-all-doctor?day=${day
        .toString()
        .toLowerCase()}&date=${date.toString()}&care_type=primary&visit_type=virtual`
    );
    console.log("doc and slots virtual primary", result);
    return result.data.docs;
  } catch (err) {
    console.log(err.message);
  }
}

async function bookVirtualPrimary(data) {
  try {
    const result = await axiosInstance.post(
      `/api/appointment/create-appointment`,
      data
    );
    console.log("appointment==>", result);
    if (result.data.success) {
      return { success: true, msg: result.data.message };
    } else if (!result.data.success) {
      return { success: false, msg: result.data.message };
    }
    return {
      success: false,
      msg: "",
    };
  } catch (err) {
    console.log(err.message);
  }
}

async function cancelAppointment(id) {
  try {
    const result = await axiosInstance.post(
      `/api/appointment/cancel-appointment`,
      {
        appointment_id: id,
      }
    );
    console.log("cancel appointment==>", result);
    if (result.data.success) {
      return { success: true };
    } else if (!result.data.success) {
      return { success: false };
    }
    return {
      success: false,
      msg: "",
    };
  } catch (err) {
    console.log(err.message);
  }
}

async function getDocWithSlotsForClinicPrimary(day, date) {
  console.log(day, date);
  try {
    const result = await axiosInstance.get(
      `/api/doctor/get-all-doctor?day=${day.toString()}&date=${date.toString()}&care_type=primary&visit_type=clinic`
    );
    console.log("doc and slots", result);
    return result.data.docs;
  } catch (err) {
    console.log(err.message);
    return [];
  }
}

async function getDocWithSlotsForClinicUrgent(day, date) {
  console.log(day, date);
  try {
    const result = await axiosInstance.get(
      `/api/doctor/get-all-doctor?day=${day.toString()}&date=${date.toString()}&care_type=urgent&visit_type=clinic`
    );
    console.log("doc and slots", result);
    return result.data.docs;
  } catch (err) {
    console.log(err.message);
    return [];
  }
}

async function completeAppointment(appointmentId) {
  try {
    const result = await axiosInstance.post(`/api/agora/complete-appointment`, {
      appointmentId: appointmentId,
    });
    console.log("appointment==>", result);
    if (result.data.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
  }
}
