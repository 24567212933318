import { createSlice } from "@reduxjs/toolkit";

const appointmentTrackSlice = createSlice({
  name: "appointmentTrack",
  initialState: false, // Boolean state to indicate popup visibility
  reducers: {
    setappointmentTrack: (state, action) => action.payload, // Sets state to true or false
    clearappointmentTrack: () => false, // Explicitly resets to false
  },
});

export const { setappointmentTrack, clearappointmentTrack } =
  appointmentTrackSlice.actions;
export default appointmentTrackSlice.reducer;
