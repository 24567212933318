import { Helmet } from "react-helmet";
const Howitworks = () => {
  return (
    <>
      <Helmet>{/* <link rel="stylesheet" href="/css/style.css" /> */}</Helmet>
      <>
        <div className="Small-Wrapper">
          <div className="container">
            <div className="TitleBox">
              <h4 className="Title">How It Works</h4>
            </div>
            <div className="AboutUsArea">
              <h4 className="Title">How It Works</h4>
              <aside>
                <h4>Healthcare Through Major Insurance Plans</h4>
               
                <ul className="newul">
                  <li>
                   We provide care for patients with health insurance, including Medicare and Medicaid
                  </li>
                  <li>
                  Services include primary care, urgent care, lab tests, X-rays, pharmacy services, and 
               more
                  </li>
                  <li>
                  Bring your insurance details, and we’ll handle the rest to ensure seamless care.
                  </li>
                </ul>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p> */}
              </aside>
              <aside>
                <h4>Affordable Membership Plans</h4>
                <ul className="newul">
                  <li> Memberships start at just $59 per month for individuals and families.
                  </li>
                  <li>Includes unlimited access to primary care, urgent care, lab tests, IV therapy, X-rays, and 
                  pharmacy services.</li>
                  <li>Enjoy $0 copays and deductibles for covered services</li>
                  <li>Choose your preferred provider from our network and switch anytime</li>
                  <li>Flexible, month-to-month contracts with no long-term commitment</li>
                  <li>Visit our Membership Plan page or contact us for more details</li>

                </ul>
                
              </aside>
              
              {/* <aside>
                <h4>Lorem ipsum dolor sit amet</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </aside> */}
            </div>
          </div>
        </div>

        <div className="ModalBox">
          <div className="modal fade" id="DoctorLoginModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Login to Your Account</h3>
                        <p>
                          We’ll use your email to find your account if you have
                          one
                        </p>
                      </article>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email Address"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Password"
                          />
                          <span className="Icon">
                            <img src="images/Eye.png" />{" "}
                          </span>
                        </div>
                        <div className="form-group">
                          <h5>
                            <a
                              href="javascript:void(0)"
                              data-dismiss="modal"
                              data-toggle="modal"
                              data-target="#DoctorResetModal"
                            >
                              Reset Password?
                            </a>
                          </h5>
                        </div>
                        <div className="ButtonBox">
                          <button className="Dark" id="Login">
                            Sign In
                          </button>
                          <button
                            className="Light"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginPhoneModal"
                          >
                            Login Using OTP
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Are you seeking care? <a href="">Login here</a>{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Reset Password</h3>
                        <p>
                          We’ll send otp to your email address to verify your
                          account
                        </p>
                      </article>
                      <form>
                        <div className="form-group">
                          <label>Email Address </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email Address"
                          />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorResetOTPModal"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetOTPModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>OTP Code Verification</h3>
                        <p>Code has been send to ******@gmail.com</p>
                      </article>
                      <form>
                        <div className="form-group">
                          <div className="OTPBox">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Gray"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorResetPasswordModal"
                          >
                            Verify
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Resend Code In<strong>55</strong>s{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetPasswordModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Reset Password</h3>
                        {/* <p>We’ll send otp to your email address to verify your account</p> */}
                      </article>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Change Password"
                          />
                          <span className="Icon">
                            <img src="images/Eye.png" />{" "}
                          </span>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Confirm Password"
                          />
                          <span className="Icon">
                            <img src="images/Eye.png" />{" "}
                          </span>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorResetCongratulationsModal"
                          >
                            Change Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetCongratulationsModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                      <h3>Congratulations</h3>
                      <p>Your password has been changed successfully!</p>
                    </div>
                    <div className="LoginBody">
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorLoginModal"
                        >
                          Login Again
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorLoginPhoneModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Welcome to Ur Health</h3>
                        <p>
                          We’ll use your mobile number to find your account if
                          you have one
                        </p>
                      </article>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                          />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginOTPModal"
                          >
                            Submit
                          </button>
                          <button
                            className="Light"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginModal"
                          >
                            Login Using Password
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Are you seeking care? <a href="">Login here</a>{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorLoginOTPModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>OTP Code Verification</h3>
                        <p>Code has been send to +1 111 *******91</p>
                      </article>
                      <form>
                        <div className="form-group">
                          <div className="OTPBox">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Gray"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginCongratulationsModal"
                          >
                            Verify
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Resend Code In<strong>55</strong>s{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorLoginCongratulationsModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                      <h3>Welcome to ur HEALTHCARE</h3>
                      <p>
                        Congratulations <br /> Your have logged in successfully
                      </p>
                    </div>
                    <div className="LoginBody">
                      <div className="ButtonBox">
                        <button className="Dark" id="LoginOTP">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Patient Login Modals */}
        <div className="ModalBox">
          <div className="modal fade" id="PatientLoginModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Login to Your Account</h3>
                      </article>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email Address"
                          />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            id="Login"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#PatientLoginPhoneModal"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Are you expert care?{" "}
                          <a href="javascript:void(0);">Login here</a>{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="PatientLoginPhoneModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Welcome to Ur Health</h3>
                      </article>
                      <form>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone Number"
                          />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginOTPModal"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Are you expert care? <a href="">Login here</a>{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="PatientLoginOTPModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>OTP Code Verification</h3>
                        <p>Code has been send to +1 111 *******91</p>
                      </article>
                      <form>
                        <div className="form-group">
                          <div className="OTPBox">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Gray"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginCongratulationsModal"
                          >
                            Verify
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Resend Code In<strong>55</strong>s{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorLoginCongratulationsModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                      <h3>Welcome to ur HEALTHCARE</h3>
                      <p>
                        Congratulations <br /> Your have logged in successfully
                      </p>
                    </div>
                    <div className="LoginBody">
                      <div className="ButtonBox">
                        <button className="Dark" id="LoginOTP">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ModalBox">
          <div className="modal fade" id="BookingConsultation">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="BookingModalArea">
                    <h3 className="Title">Get Care</h3>
                    <div className="CommonTabs">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#Virtual"
                          >
                            Virtual
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#Completed"
                          >
                            Clinic Visit
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="Virtual">
                        <div className="BookingClinicVisit">
                          <h1>Book a Clinic Visit appointment</h1>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#ExplainReasonVisitModal"
                            data-dismiss="modal"
                          >
                            <div className="CareBox">
                              <span className="Icon">
                                <img src="images/care-icon.png" />
                              </span>
                              <aside>
                                <h3>Virtual Urgent Care</h3>
                                <p>Today at 06:40 PM</p>
                              </aside>
                              <span>
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </a>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#ExplainReasonVisitModal"
                            data-dismiss="modal"
                          >
                            <div className="CareBox">
                              <span className="Icon">
                                <img src="images/care-icon.png" />
                              </span>
                              <aside>
                                <h3>Virtual primary Care</h3>
                                <p>Today at 06:40 PM</p>
                              </aside>
                              <span>
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="Completed">
                        <div className="BookingClinicVisit">
                          <h1>Book a Clinic Visit appointment</h1>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#BookClinicVisitLocation"
                            data-dismiss="modal"
                          >
                            <div className="CareBox">
                              <span className="Icon">
                                <img src="images/care-icon.png" />
                              </span>
                              <aside>
                                <h3>Urgent Care</h3>
                                <p>Today at 06:40 PM</p>
                              </aside>
                              <span>
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </a>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#BookClinicVisitLocation"
                            data-dismiss="modal"
                          >
                            <div className="CareBox">
                              <span className="Icon">
                                <img src="images/care-icon.png" />
                              </span>
                              <aside>
                                <h3>Urgent Care</h3>
                                <p>Today at 06:40 PM</p>
                              </aside>
                              <span>
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ExplainReasonVisitModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="BookingModalArea">
                    <h3 className="Title">
                      Explain what is reason <br /> for visit.
                    </h3>
                    <div className="CommonFormModal">
                      <div className="form-group">
                        <label>Write your concern here</label>
                        <textarea
                          className="form-control"
                          placeholder="write here.."
                          rows={8}
                          defaultValue={""}
                        />
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark FolderPermissionId"
                          data-toggle="modal"
                          data-target="#ScheduleVirtualCall"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ScheduleVirtualCall">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">Schedule Virtual Call</h3>
                      <div className="CommonFormModal">
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="form-group">
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="form-group">
                              <select className="form-control">
                                <option>Select</option>
                                <option>California</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <figure>
                        <img src="images/Date.png" />
                      </figure>
                      <div className="SelectTimeBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/Avatar-1.png" />
                          </span>
                          <h3>Edmund Li</h3>
                          <ul>
                            <li>
                              <span>
                                <img src="images/virtual-icon.png" />
                              </span>
                              Virtual
                            </li>
                            <li>
                              <span>
                                <img src="images/location-icon.png" />
                              </span>
                              7000 mi away
                            </li>
                          </ul>
                        </aside>
                        <p>
                          <span>
                            <img src="images/time-icon.png" />
                          </span>
                          Wednesday, 09:15 AM PDT, Jun 19, 2024
                        </p>
                        <div className="SelectTimeBody">
                          <h4>Select Time</h4>
                          <ul>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="Available">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="SelectTimeBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/Avatar-1.png" />
                          </span>
                          <h3>Edmund Li</h3>
                          <ul>
                            <li>
                              <span>
                                <img src="images/virtual-icon.png" />
                              </span>
                              Virtual
                            </li>
                            <li>
                              <span>
                                <img src="images/location-icon.png" />
                              </span>
                              7000 mi away
                            </li>
                          </ul>
                        </aside>
                        <p>
                          <span>
                            <img src="images/time-icon.png" />
                          </span>
                          Wednesday, 09:15 AM PDT, Jun 19, 2024
                        </p>
                        <div className="SelectTimeBody">
                          <h4>Select Time</h4>
                          <ul>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="Available">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#WhoIsAppointment"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="WhoIsAppointment">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">
                        Who is the <br /> appointment for?
                      </h3>
                      <div className="AppointmentPersonBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/girl-avatar.png" />
                          </span>
                          <h3>Miss Sharnya</h3>
                          <ul>
                            <li>27 years</li>
                            <li>Female</li>
                          </ul>
                          <span className="RightArrow">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </aside>
                        <aside>
                          <span className="Profile">
                            <img src="images/girl-avatar.png" />
                          </span>
                          <h3>Miss Sharnya</h3>
                          <ul>
                            <li>27 years</li>
                            <li>Female</li>
                          </ul>
                          <span className="RightArrow">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </aside>
                      </div>
                      <div className="text-center">
                        <a href="javascript:void(0);" className="AddPatientBtn">
                          <i className="fa fa-plus" aria-hidden="true" />
                          Add Patient
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="BookAppAddProfileInfo">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginBody">
                      <article>
                        <h3>Add Patient Info</h3>
                      </article>
                      <form>
                        <div className="form-group">
                          <label>Select Relationship</label>
                          <select className="form-control">
                            <option>Select</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter First Name"
                          />
                        </div>
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Last Name"
                          />
                        </div>
                        <div className="form-group">
                          <div className="SelectGender">
                            <label>Select Gender</label>
                            <ul>
                              <li>
                                <input type="radio" name="male" />
                                <aside>
                                  <span>Male</span>
                                </aside>
                              </li>
                              <li>
                                <input type="radio" name="male" />
                                <aside>
                                  <span>Female</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Date of Birth</label>
                          <input type="date" className="form-control" />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark FolderPermissionId"
                            data-toggle="modal"
                            data-target="#"
                            data-dismiss="modal"
                          >
                            Save Details
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="BookConfirmAppointment">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">Confirm Appointment</h3>
                      <h3 className="BoxHeading">Doctor</h3>
                      <div className="SelectTimeBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/Avatar-1.png" />
                          </span>
                          <h3>Edmund Li</h3>
                          <ul>
                            <li>
                              <span>
                                <img src="images/virtual-icon.png" />
                              </span>
                              Virtual
                            </li>
                            <li>
                              <span>
                                <img src="images/location-icon.png" />
                              </span>
                              Primary Care
                            </li>
                          </ul>
                          <span className="RightArrow">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </aside>
                      </div>
                      <div className="SelectTimeBox">
                        <p>
                          <span>
                            <img src="images/time-icon.png" />
                          </span>
                          Wednesday, 09:15 AM PDT, Jun 19, 2024
                        </p>
                      </div>
                      <h3 className="BoxHeading">Patient</h3>
                      <div className="AppointmentPersonBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/girl-avatar.png" />
                          </span>
                          <h3>Miss Sharnya</h3>
                          <ul>
                            <li>27 years</li>
                            <li>Female</li>
                          </ul>
                        </aside>
                      </div>
                      <div className="ReasonBox">
                        <h3>Reason</h3>
                        <p>
                          Lorem ipsum crest en pic Lorem ipsum crest en picLorem
                          ipsum crest en picLorem ipsum crest en
                        </p>
                      </div>
                      <div className="ModalTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>Consultant Fee</td>
                              <td>$40.00</td>
                            </tr>
                            <tr>
                              <td>Taxes</td>
                              <td>$5.00</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total</td>
                              <td>$45.00</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#BookEndScheduling"
                          data-dismiss="modal"
                        >
                          Confirm Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="BookEndScheduling">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                    style={{ zIndex: 99 }}
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="EndScheduling">
                      <figure>
                        <img src="images/time-management.png" />
                      </figure>
                      <h2>End Scheduling</h2>
                      <p>Are you sure you want to End the scheduling?</p>
                      <div className="ButtonBox d-flex align-items-center justify-content-between">
                        <button className="Light mb-0 mr-2">
                          No, Continue
                        </button>
                        <button className="Dark ml-2">Yes, End</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="BookPayVisit">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="BookingModalArea">
                    <h3 className="Title">
                      How would you like to <br /> pay for the visit?
                    </h3>
                    <div className="UntilVisit">
                      <p>You won’t be charged until your visit</p>
                    </div>
                    <div className="BookingClinicVisit">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#BookPurchaseMemPlan"
                        data-dismiss="modal"
                      >
                        <div className="CareBox">
                          <span className="Icon">
                            <img src="images/care-icon.png" />
                          </span>
                          <aside>
                            <h3>Use Insurance</h3>
                            <p>$5.00</p>
                          </aside>
                          <span>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      </a>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#BookPurchaseMemPlan"
                        data-dismiss="modal"
                      >
                        <div className="CareBox">
                          <span className="Icon">
                            <img src="images/care-icon.png" />
                          </span>
                          <aside>
                            <h3>Pay Using Membership</h3>
                            <p>$00.00</p>
                          </aside>
                          <span>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="BookPurchaseMemPlan">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">
                        Purchase Membership <br /> Plan
                      </h3>
                      <div className="PayMonthly">
                        <aside>
                          <span>
                            <img src="images/pay-monthly.png" />
                          </span>
                          <h4>Pay Monthly</h4>
                          <h6>$15 per month</h6>
                        </aside>
                        <p>$20 today. Then $15 per month for 12 months.</p>
                      </div>
                      <div className="MembershipPlan">
                        <ul>
                          <li>
                            <input type="radio" name="plan1" />
                            <aside>
                              <span className="Icon">
                                <img src="images/plan-1.png" />
                              </span>
                              <h6>Indivisual</h6>
                              <p>$20 pm</p>
                              <span className="CheckOverlay">
                                <i className="fa fa-check" aria-hidden="true" />
                              </span>
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="plan1" />
                            <aside>
                              <span className="Icon">
                                <img src="images/plan-2.png" />
                              </span>
                              <h6>With Spouse</h6>
                              <p>$30 pm</p>
                              <span className="CheckOverlay">
                                <i className="fa fa-check" aria-hidden="true" />
                              </span>
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="plan1" />
                            <aside>
                              <span className="Icon">
                                <img src="images/plan-3.png" />
                              </span>
                              <h6>With Spouse &amp; 1 Child</h6>
                              <p>$20 pm</p>
                              <span className="CheckOverlay">
                                <i className="fa fa-check" aria-hidden="true" />
                              </span>
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="plan1" />
                            <aside>
                              <span className="Icon">
                                <img src="images/plan-4.png" />
                              </span>
                              <h6>With Spouse &amp; 2 Childre</h6>
                              <p>$20 pm</p>
                              <span className="CheckOverlay">
                                <i className="fa fa-check" aria-hidden="true" />
                              </span>
                            </aside>
                          </li>
                        </ul>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#BookPaymentSuccess"
                          data-dismiss="modal"
                        >
                          Confirm Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="BookPaymentSuccess">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox" data-toggle="modal" data-target="#">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                    </div>
                    <div className="AccountCreatBody">
                      <article>
                        <h3>Account Created</h3>
                        <h4>
                          Congratulations <br /> Your payment was successful
                        </h4>
                      </article>
                    </div>
                    <div className="PaymentSuccessTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <p>Transaction ID</p>
                              <h6>846287467846</h6>
                            </td>
                            <td>
                              <p>Plan Type</p>
                              <h6>Monthly Individual</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Billing Month</p>
                              <h6>$20 Monthly</h6>
                            </td>
                            <td>
                              <p>Purchase Date</p>
                              <h6>20/05/24</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Expiry Date</p>
                              <h6>20/05/24</h6>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="Note">
                      <span>Note : </span> Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="BookBookingConfirmed">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox" data-toggle="modal" data-target="#">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                    </div>
                    <div className="AccountCreatBody">
                      <article>
                        <h3>Booking Confirmed</h3>
                        <h4>
                          Congratulations <br /> Your payment was successful
                        </h4>
                      </article>
                    </div>
                    <div className="PaymentSuccessTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <p>Transaction ID</p>
                              <h6>846287467846</h6>
                            </td>
                            <td>
                              <p>Plan Type</p>
                              <h6>Monthly Individual</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Billing Month</p>
                              <h6>$20 Monthly</h6>
                            </td>
                            <td>
                              <p>Purchase Date</p>
                              <h6>20/05/24</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Expiry Date</p>
                              <h6>20/05/24</h6>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="Note">
                      <span>Note : </span> Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                    <div className="ButtonBox">
                      <button
                        className="Dark"
                        data-toggle="modal"
                        data-target="#BookPaymentSuccess"
                        data-dismiss="modal"
                      >
                        Proceed with Appointment Booking
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Clinic Visit Modal  */}
          <div className="modal fade" id="ClinicExplainReasonVisitModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="BookingModalArea">
                    <h3 className="Title">
                      Explain what is reason <br /> for visit.
                    </h3>
                    <div className="CommonFormModal">
                      <div className="form-group">
                        <label>Write your concern here</label>
                        <textarea
                          className="form-control"
                          placeholder="write here.."
                          rows={8}
                          defaultValue={""}
                        />
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark FolderPermissionId"
                          data-toggle="modal"
                          data-target="#ClinicScheduleVirtualCall"
                          data-dismiss="modal"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ClinicScheduleVirtualCall">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">Schedule Virtual Call</h3>
                      <div className="CommonFormModal">
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="form-group">
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="form-group">
                              <select className="form-control">
                                <option>Select</option>
                                <option>California</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <figure>
                        <img src="images/Date.png" />
                      </figure>
                      <div className="SelectTimeBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/Avatar-1.png" />
                          </span>
                          <h3>Edmund Li</h3>
                          <ul>
                            <li>
                              <span>
                                <img src="images/virtual-icon.png" />
                              </span>
                              Virtual
                            </li>
                            <li>
                              <span>
                                <img src="images/location-icon.png" />
                              </span>
                              7000 mi away
                            </li>
                          </ul>
                        </aside>
                        <p>
                          <span>
                            <img src="images/time-icon.png" />
                          </span>
                          Wednesday, 09:15 AM PDT, Jun 19, 2024
                        </p>
                        <div className="SelectTimeBody">
                          <h4>Select Time</h4>
                          <ul>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="Available">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="SelectTimeBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/Avatar-1.png" />
                          </span>
                          <h3>Edmund Li</h3>
                          <ul>
                            <li>
                              <span>
                                <img src="images/virtual-icon.png" />
                              </span>
                              Virtual
                            </li>
                            <li>
                              <span>
                                <img src="images/location-icon.png" />
                              </span>
                              7000 mi away
                            </li>
                          </ul>
                        </aside>
                        <p>
                          <span>
                            <img src="images/time-icon.png" />
                          </span>
                          Wednesday, 09:15 AM PDT, Jun 19, 2024
                        </p>
                        <div className="SelectTimeBody">
                          <h4>Select Time</h4>
                          <ul>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="AlreadyBooked">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="NotAvailable">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                            <li className="Available">
                              <input type="radio" name="selectTime" />
                              <aside>
                                <span>06:00 am</span>
                              </aside>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#ClinicWhoIsAppointment"
                          data-dismiss="modal"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ClinicWhoIsAppointment">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">
                        Who is the <br /> appointment for?
                      </h3>
                      <div className="AppointmentPersonBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/girl-avatar.png" />
                          </span>
                          <h3>Miss Sharnya</h3>
                          <ul>
                            <li>27 years</li>
                            <li>Female</li>
                          </ul>
                          <span className="RightArrow">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </aside>
                        <aside>
                          <span className="Profile">
                            <img src="images/girl-avatar.png" />
                          </span>
                          <h3>Miss Sharnya</h3>
                          <ul>
                            <li>27 years</li>
                            <li>Female</li>
                          </ul>
                          <span className="RightArrow">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </aside>
                      </div>
                      <div className="text-center">
                        <a
                          href="javascript:void(0);"
                          className="AddPatientBtn"
                          data-toggle="modal"
                          data-target="#ClinicAppAddProfileInfo"
                          data-dismiss="modal"
                        >
                          <i className="fa fa-plus" aria-hidden="true" />
                          Add Patient
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ClinicAppAddProfileInfo">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginBody">
                      <article>
                        <h3>Add Patient Info</h3>
                      </article>
                      <form>
                        <div className="form-group">
                          <label>Select Relationship</label>
                          <select className="form-control">
                            <option>Select</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter First Name"
                          />
                        </div>
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Last Name"
                          />
                        </div>
                        <div className="form-group">
                          <div className="SelectGender">
                            <label>Select Gender</label>
                            <ul>
                              <li>
                                <input type="radio" name="male" />
                                <aside>
                                  <span>Male</span>
                                </aside>
                              </li>
                              <li>
                                <input type="radio" name="male" />
                                <aside>
                                  <span>Female</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Date of Birth</label>
                          <input type="date" className="form-control" />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ClinicConfirmAppointment"
                            data-dismiss="modal"
                          >
                            Save Details
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ClinicConfirmAppointment">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">Confirm Appointment</h3>
                      <h3 className="BoxHeading">Doctor</h3>
                      <div className="SelectTimeBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/Avatar-1.png" />
                          </span>
                          <h3>Edmund Li</h3>
                          <ul>
                            <li>
                              <span>
                                <img src="images/virtual-icon.png" />
                              </span>
                              Virtual
                            </li>
                            <li>
                              <span>
                                <img src="images/location-icon.png" />
                              </span>
                              Primary Care
                            </li>
                          </ul>
                          <span className="RightArrow">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </aside>
                      </div>
                      <div className="SelectTimeBox">
                        <p>
                          <span>
                            <img src="images/time-icon.png" />
                          </span>
                          Wednesday, 09:15 AM PDT, Jun 19, 2024
                        </p>
                      </div>
                      <h3 className="BoxHeading">Patient</h3>
                      <div className="AppointmentPersonBox">
                        <aside>
                          <span className="Profile">
                            <img src="images/girl-avatar.png" />
                          </span>
                          <h3>Miss Sharnya</h3>
                          <ul>
                            <li>27 years</li>
                            <li>Female</li>
                          </ul>
                        </aside>
                      </div>
                      <div className="ReasonBox">
                        <h3>Reason</h3>
                        <p>
                          Lorem ipsum crest en pic Lorem ipsum crest en picLorem
                          ipsum crest en picLorem ipsum crest en
                        </p>
                      </div>
                      <div className="ModalTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>Consultant Fee</td>
                              <td>$40.00</td>
                            </tr>
                            <tr>
                              <td>Taxes</td>
                              <td>$5.00</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total</td>
                              <td>$45.00</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#ClinicEndScheduling"
                          data-dismiss="modal"
                        >
                          Confirm Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ClinicEndScheduling">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                    style={{ zIndex: 99 }}
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="EndScheduling">
                      <figure>
                        <img src="images/time-management.png" />
                      </figure>
                      <h2>End Scheduling</h2>
                      <p>Are you sure you want to End the scheduling?</p>
                      <div className="ButtonBox d-flex align-items-center justify-content-between">
                        <button
                          className="Light mb-0 mr-2"
                          data-toggle="modal"
                          data-target="#ClinicPayVisit"
                          data-dismiss="modal"
                        >
                          No, Continue
                        </button>
                        <button className="Dark ml-2">Yes, End</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="ClinicPayVisit">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="BookingModalArea">
                    <h3 className="Title">
                      How would you like to <br /> pay for the visit?
                    </h3>
                    <div className="UntilVisit">
                      <p>You won’t be charged until your visit</p>
                    </div>
                    <div className="BookingClinicVisit">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#BookPurchaseMemPlan"
                        data-dismiss="modal"
                      >
                        <div className="CareBox">
                          <span className="Icon">
                            <img src="images/care-icon.png" />
                          </span>
                          <aside>
                            <h3>Use Insurance</h3>
                            <p>$5.00</p>
                          </aside>
                          <span>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      </a>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#BookPurchaseMemPlan"
                        data-dismiss="modal"
                      >
                        <div className="CareBox">
                          <span className="Icon">
                            <img src="images/care-icon.png" />
                          </span>
                          <aside>
                            <h3>Pay Using Membership</h3>
                            <p>$00.00</p>
                          </aside>
                          <span>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Appointment Modal  */}
        <div className="ModalBox">
          <div className="modal fade" id="AppointmentEndCall">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div className="LoginBox">
                      <div className="AccountCreatBody">
                        <article>
                          <h3>End Call</h3>
                          <h4>
                            Are you sure you want to end the call with John ?
                          </h4>
                        </article>
                      </div>
                      <div className="ButtonBox d-flex align-items-center">
                        <button
                          className="Light mb-0 mr-2"
                          data-toggle="modal"
                          data-target="#AppointmentCongratulation"
                        >
                          No, Continue
                        </button>
                        <button className="Dark ml-2" data-dismiss="modal">
                          Yes, Emd
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="AppointmentCongratulation">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div
                      className="LoginBox"
                      data-toggle="modal"
                      data-target="#"
                    >
                      <div className="LoginHead">
                        <img src="../images/Congratulations.png" />
                      </div>
                      <div className="AccountCreatBody">
                        <article>
                          <h3>Congratulation</h3>
                          <p>Call ended successfully</p>
                        </article>
                      </div>
                      <div className="PaymentSuccessTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <p>Patient</p>
                                <h6>James Smith</h6>
                              </td>
                              <td>
                                <p>Booking ID</p>
                                <h6>#9434343493</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Call Duration</p>
                                <h6>5:00</h6>
                              </td>
                              <td>
                                <p>Schedule Date</p>
                                <h6>20/05/24</h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#"
                          data-dismiss="modal"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="AppointmentShareYourThoughts">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="ModalTopArea">
                    <div
                      className="LoginBox"
                      data-toggle="modal"
                      data-target="#"
                    >
                      <div className="LoginHead">
                        <img src="../images/Congratulations.png" />
                      </div>
                      <div className="AccountCreatBody">
                        <article>
                          <h3>Share Your Thoughts!</h3>
                          <p>About Your Recent Consultation</p>
                        </article>
                      </div>
                      <div className="ShareThoughts">
                        <ul>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-1.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-1.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-1.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-4.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-5.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-6.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-7.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-8.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-9.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-10.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-11.png" />
                            </aside>
                          </li>
                          <li>
                            <input type="radio" name="thought" />
                            <aside>
                              <img src="../images/thought-12.png" />
                            </aside>
                          </li>
                        </ul>
                      </div>
                      <div className="ButtonBox d-flex align-items-center">
                        <button
                          className="Light mb-0 mr-2"
                          data-toggle="modal"
                          data-target="#"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="Dark ml-2"
                          data-toggle="modal"
                          data-target="#"
                          data-dismiss="modal"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="AppointmentSpecialistModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead Doctor">
                      <img src="../images/doctor-avatar.png" />
                    </div>
                    <div className="AccountCreatBody">
                      <article>
                        <h3>Dr. Emily Grace</h3>
                        <p>Skin Specialist</p>
                        <ul className="ModalStar">
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                        </ul>
                        <p className="TypeReview">“Type Your Review Here...”</p>
                      </article>
                    </div>
                    <div className="LoginBody">
                      <form>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-toggle="modal"
                            data-target="#AppointmentSpecialistParaModal"
                            data-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="AppointmentSpecialistParaModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead Doctor">
                      <img src="../images/doctor-avatar.png" />
                    </div>
                    <div className="AccountCreatBody">
                      <article>
                        <h3>Dr. Emily Grace</h3>
                        <p>Skin Specialist</p>
                        <ul className="ModalStar">
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                          <li>
                            <i className="fa fa-star" aria-hidden="true" />
                          </li>
                        </ul>
                        <p>
                          “I cannot praise Dr. Emily Grace's skills as a skin
                          specialist enough. She is the ultimate selection for
                          anyone seeking the very best in skin treatment.”
                        </p>
                      </article>
                    </div>
                    <div className="LoginBody">
                      <form>
                        <div className="ButtonBox">
                          <button className="Dark" id="Login">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Howitworks;
