import { useState, useEffect } from "react";
import moment from "moment/moment";
import { showNotification } from "../../helper/helper";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { Helmet } from "react-helmet";
import HomepageSlider from "../sliders/HomepageSlider";
import BookAppointment from "../Popups/BookAppointment";
import { appointmentServices } from "../../services/appointmentServices";
import VirtualPrimary from "../Popups/VirtualPrimary";
import { toast } from "react-toastify";
import { setbooking } from "../../store/ConsultationSlice";
import { setdocpopup } from "../../store/DocLoginSlice";
import ClinicUrgent from "../Popups/ClinicUrgent";
import ClinicPrimary from "../Popups/ClinicPrimary";
import BookAppointmentLogout from "../Popups/BookingLogout/BookAppointment";
import { setBookingPopup } from "../../store/BookingPopUpSlice";
import ContactForm from "../contactForm/contactForm";
import ServicesSlider from "../sliders/ServicesSlider";
import Banner from "../../assets/imges/banner_img.png";
import expert1 from "../../assets/imges/doctorCardMale.png";
import expert2 from "../../assets/imges/male2.png";
import expert3 from "../../assets/imges/femaleDoctor.png";

const Home = () => {
  const dispatch = useDispatch();
  const docPopoup = useSelector((state) => state.togglePopup);
  const reduxUser = useSelector((state) => state.user);
  const [toggleAppointmentModal, settoggleAppointmentModal] = useState(false);
  const [doctors, setdoctors] = useState([]);
  const [virtualPrimary, setvirtualPrimary] = useState(false);
  const [clinicUrgent, setclinicUrgent] = useState(false);
  const [clinicPrimary, setclinicPrimaryPopup] = useState(false);

  //........................................................................

  const [toggleAppointmentModal2, settoggleAppointmentModal2] = useState(false);

  //........................................................................

  useEffect(() => {
    getdocAndslots();
    // console.log(process.env.REACT_APP_APP_URL + `/public/download.png`);
  }, []);

  async function getdocAndslots(day, date) {
    dispatch(loader(true));
    const currentDate = moment().format("YYYY/MM/DD");
    const currentDayOfWeek = moment().format("dddd").toLowerCase();
    const selectedDate = date ? date : currentDate;
    const selectedDay = day ? day.toLowerCase() : currentDayOfWeek;
    const res = await appointmentServices.getDocWithSlots(
      selectedDay,
      selectedDate
    );

    setdoctors(res);
    dispatch(loader(false));
  }

  return (
    <>
      <Helmet>
        <title>Ur HealthCare</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <link rel="stylesheet" href="/css/style.css" /> */}
        {/* <link rel="stylesheet" href="/css/doctor.css" /> */}
        {/* <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <BookAppointment
        toggleAppointmentModal={toggleAppointmentModal}
        settoggleAppointmentModal={settoggleAppointmentModal}
        doctors={doctors}
        getdocAndslots={getdocAndslots}
        setvirtualPrimary={setvirtualPrimary}
        clinicUrgent={clinicUrgent}
        setclinicUrgent={setclinicUrgent}
        clinicPrimary={clinicPrimary}
        setclinicPrimaryPopup={setclinicPrimaryPopup}
      />

      <BookAppointmentLogout
        toggleAppointmentModal={toggleAppointmentModal2}
        settoggleAppointmentModal={settoggleAppointmentModal2}
        doctors={doctors}
        getdocAndslots={getdocAndslots}
        setvirtualPrimary={setvirtualPrimary}
        clinicUrgent={clinicUrgent}
        setclinicUrgent={setclinicUrgent}
        clinicPrimary={clinicPrimary}
        setclinicPrimaryPopup={setclinicPrimaryPopup}
      />

      <VirtualPrimary
        virtualPrimary={virtualPrimary}
        setvirtualPrimary={setvirtualPrimary}
      />

      <ClinicUrgent
        clinicUrgent={clinicUrgent}
        setclinicUrgent={setclinicUrgent}
      />

      <ClinicPrimary
        clinicPrimary={clinicPrimary}
        setclinicPrimaryPopup={setclinicPrimaryPopup}
      />

      <section>
        <div className="SlideArea">
          <div className="container">
            <div className="SlideBox">
              <div className="SlideLeft">
                {/* <h5>Best Medical care</h5> */}
                <h4>
                  Transforming Healthcare <br />
                  by placing you at the heart of every decision, creating a
                  seamless and patient- centric experience.
                  {/* Professional <br /> Care for your <br /> Family */}
                </h4>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    const user = localStorage.getItem("user");
                    const isLogged = localStorage.getItem("isLoggedIn");
                    if (isLogged && user) {
                      dispatch(setbooking(true));
                    } else {
                      // toast("You need to login to book appointment");
                      settoggleAppointmentModal2(true);
                      dispatch(setBookingPopup(true));
                    }
                  }}
                >
                  Book Appointment
                </a>
                <aside>
                  {/* <h6>10K Trusted Patient</h6> */}
                  <img src="images/Trusted.png" />
                </aside>
              </div>
              <div className="SlideRight">
                {/* ===Banner img====== */}
                <img src={Banner} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="MembershipArea">
          <div className="container">
            <div className="MembershipHead">
              <p>Membership</p>
              <h3>
                A hassle free healthcare experience, with our memberships
                options for you and your family.
              </h3>
            </div>

            {/* <div className="MembershipBody">
              <HomepageSlider />
            </div> */}

            <div>
              <ServicesSlider />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="LocationArea">
          <div className="LocationHead">
            <div className="container">
              {/* <select>
                <option>California</option>
              </select> */}
              <p>Location</p>
              <h3>Explore our locations</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3110.3495020010278!2d-121.26561392536648!3d38.77862145370591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809b21d87a1d1ed1%3A0x6219d125c1eb0eb7!2s6839%20Five%20Star%20Blvd%2C%20Rocklin%2C%20CA%2095677%2C%20USA!5e0!3m2!1sen!2sin!4v1733465637220!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
          </div>
          <div className="LocationBody">
            <div className="owl-carousel owl-theme LocationSlide">
              <div className="item">
                <div className="LocationBox">
                  <figure>
                    <img src="images/Location.png" />
                  </figure>
                  <figcaption>
                    <h4>Boston,MA Downtown Boston</h4>
                    <h6>80 Summer St, Boston, MA 02110</h6>
                    <p>
                      <span>
                        <img src="images/Clock.png" />{" "}
                      </span>
                      <strong>Primary Care : </strong> 9:30 am Tomorrow
                    </p>
                    <p>
                      <span>
                        <img src="images/Clock.png" />{" "}
                      </span>
                      <strong>Urgent Care : </strong> 10:30 am Today
                    </p>
                  </figcaption>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="ExpertArea">
          <div className="container">
            <div className="ExpertHead">
              <p>Expert</p>
              <h3>
                Our Professional Experts Working <br /> For Your Health
              </h3>
            </div>
            <div className="ExpertBody">
              <div className="row justify-content-center">
                <div className="col-sm-3">
                  <div className="ExpertBox">
                    <figure>
                      <img src={expert1} />
                    </figure>
                    <figcaption>
                      <h3>Dr. KC Dilip</h3>
                      <p>Internal Medicine</p>
                      <ol>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-facebook" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-twitter" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-linkedin" aria-hidden="true" />
                          </a>
                        </li>
                      </ol>
                    </figcaption>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="ExpertBox">
                    <figure>
                      <img src={expert2} />
                    </figure>
                    <figcaption>
                      <h3>Dr. Zarlasht Fakiri</h3>
                      <p> Internal Medicine </p>
                      <ol>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-facebook" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-twitter" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-linkedin" aria-hidden="true" />
                          </a>
                        </li>
                      </ol>
                    </figcaption>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="ExpertBox">
                    <figure>
                      <img src={expert3} />
                    </figure>
                    <figcaption>
                      <h3>Ramneek Kahlon</h3>
                      <p> Nurse Practioner</p>
                      <ol>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-facebook" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-twitter" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-linkedin" aria-hidden="true" />
                          </a>
                        </li>
                      </ol>
                    </figcaption>
                  </div>
                </div>

                {/* ====================doctor card=========== */}
                {/* <div className="col-sm-3">
                  <div className="ExpertBox">
                    <figure>
                      <img src="images/Expert-4.png" />
                    </figure>
                    <figcaption>
                      <h3>Richard Kyle</h3>
                      <p>Doctor</p>
                      <ol>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-facebook" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-twitter" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="fa fa-linkedin" aria-hidden="true" />
                          </a>
                        </li>
                      </ol>
                    </figcaption>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="ApplicationArea">
          <figure>
            <img src="images/Application.png" />
          </figure>
          {/* <figcaption>
            <h6>Why Choose Us</h6>
            <h4>Text me the Application</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
            </p>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Number"
              />
            </div>
            <button className="SentSmsButton">Sent SMS</button>
          </figcaption> */}
          <ContactForm />
        </div>
      </section>
      <section>
        <div className="DownloadArea">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="DownloadLeft">
                  <h3>Download the urHealthcare app</h3>
                  <ul>
                    <li>Manage you health</li>
                    <li>Virtual care</li>
                    <li>Care plan</li>
                    <li>Medications refill</li>
                    <li>Choose Provider</li>
                    <li>Book APPOITMENTS</li>
                    <li>Diagnisotc results</li>
                  </ul>
                  <a href="javascript:void(0);">
                    <img src="images/Download-1.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/Download-2.png" />
                  </a>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="DownloadRight">
                  <img src="images/Download.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="ModalBox">
        <div className="modal fade" id="DoctorLoginModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>Login to Your Account</h3>
                      <p>
                        We’ll use your email to find your account if you have
                        one
                      </p>
                    </article>
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" />
                        <label>Email Address</label>
                      </div>
                      <div className="form-group">
                        <input type="password" className="form-control" />
                        <label>Email Password</label>
                        <span className="Icon">
                          <img src="images/Eye.png" />{" "}
                        </span>
                      </div>
                      <div className="form-group">
                        <h5>
                          <a
                            href="javascript:void(0)"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorResetModal"
                          >
                            Reset Password?
                          </a>
                        </h5>
                      </div>
                      <div className="ButtonBox">
                        <button className="Dark" id="Login">
                          Sign In
                        </button>
                        <button
                          className="Light"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorLoginPhoneModal"
                        >
                          Login Using OTP
                        </button>
                      </div>
                    </form>
                    {/* <aside>
                              <h6>Are you seeking care?  <a href="">Login here</a> </h6>
                          </aside> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="DoctorResetModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>Reset Password</h3>
                      <p>
                        We’ll send otp to your email address to verify your
                        account
                      </p>
                    </article>
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" />
                        <label>Email Address </label>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorResetOTPModal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="DoctorResetOTPModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>OTP Code Verification</h3>
                      <p>Code has been send to ******@gmail.com</p>
                    </article>
                    <form>
                      <div className="form-group">
                        <div className="OTPBox">
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Gray"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorResetPasswordModal"
                        >
                          Verify
                        </button>
                      </div>
                    </form>
                    <aside>
                      <h6>
                        Resend Code In<strong>55</strong>s{" "}
                      </h6>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="DoctorResetPasswordModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>Reset Password</h3>
                      {/* <p>We’ll send otp to your email address to verify your account</p> */}
                    </article>
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" />
                        <label>Change Password</label>
                        <span className="Icon">
                          <img src="images/Eye.png" />{" "}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <span className="Icon">
                          <img src="images/Eye.png" />{" "}
                        </span>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorResetCongratulationsModal"
                        >
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="DoctorResetCongratulationsModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Congratulations.png" />
                    <h3>Congratulations</h3>
                    <p>Your password has been changed successfully!</p>
                  </div>
                  <div className="LoginBody">
                    <div className="ButtonBox">
                      <button
                        className="Dark"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#DoctorLoginModal"
                      >
                        Login Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="DoctorLoginPhoneModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>Welcome to Ur Health</h3>
                      <p>
                        We’ll use your mobile number to find your account if you
                        have one
                      </p>
                    </article>
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" />
                        <label>Phone Number</label>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorLoginOTPModal"
                        >
                          Submit
                        </button>
                        <button
                          className="Light"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorLoginModal"
                        >
                          Login Using Password
                        </button>
                      </div>
                    </form>
                    <aside>
                      <h6>
                        Are you seeking care?{" "}
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#DoctorLoginModal"
                          data-dismiss="modal"
                        >
                          Login here
                        </a>{" "}
                      </h6>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="DoctorLoginOTPModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>OTP Code Verification</h3>
                      <p>Code has been send to +1 111 *******91</p>
                    </article>
                    <form>
                      <div className="form-group">
                        <div className="OTPBox">
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Gray"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorLoginCongratulationsModal"
                        >
                          Verify
                        </button>
                      </div>
                    </form>
                    <aside>
                      <h6>
                        Resend Code In<strong>55</strong>s{" "}
                      </h6>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="DoctorLoginCongratulationsModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Congratulations.png" />
                    <h3>Welcome to ur HEALTHCARE</h3>
                    <p>
                      Congratulations <br /> Your have logged in successfully
                    </p>
                  </div>
                  <div className="LoginBody">
                    <div className="ButtonBox">
                      <button className="Dark" id="LoginOTP">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ModalBox">
        <div className="modal fade" id="PatientLoginModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>Login to Your Account</h3>
                    </article>
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" />
                        <label>Email Address</label>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          id="Login"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#PatientLoginPhoneModal"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                    <aside>
                      <h6>
                        Are you expert care?{" "}
                        <a href="javascript:void(0);">Login here</a>{" "}
                      </h6>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientLoginPhoneModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>Welcome to Ur Health</h3>
                    </article>
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" />
                        <label>Phone Number</label>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#PatientLoginOTPModal"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                    <aside>
                      <h6>
                        Are you expert care? <a href="">Login here</a>{" "}
                      </h6>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientLoginOTPModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Fav.png" />
                  </div>
                  <div className="LoginBody">
                    <article>
                      <h3>OTP Code Verification</h3>
                      <p>Code has been send to +1 111 *******91</p>
                    </article>
                    <form>
                      <div className="form-group">
                        <div className="OTPBox">
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                          <input
                            type="text"
                            className="form-control"
                            maxLength={1}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Gray FolderPermissionId"
                          data-toggle="modal"
                          data-target="#PatientAccountCreated"
                          data-dismiss="modal"
                        >
                          Verify
                        </button>
                      </div>
                    </form>
                    <aside>
                      <h6>
                        Resend Code In<strong>55</strong>s{" "}
                      </h6>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientAccountCreated">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="LoginHead">
                    <img src="images/Congratulations.png" />
                  </div>
                  <div className="AccountCreatBody">
                    <article>
                      <h3>Account Created</h3>
                      <h4>
                        Congratulations your account has been created
                        sucsessfully
                      </h4>
                      <p>Now proceed filling your insurance details</p>
                    </article>
                  </div>
                  <div className="StepBodyArea">
                    <ul>
                      <li
                        data-toggle="modal"
                        data-target="#PatientProfileDetails"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/step-1.png" />
                        </span>
                        <h5>Step 1</h5>
                        <p>Profile Details</p>
                      </li>
                      <li
                        data-toggle="modal"
                        data-target="#PatientProfileDetails"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/step-2.png" />
                        </span>
                        <h5>Step 2</h5>
                        <p>Insurance Information</p>
                      </li>
                      <li
                        data-toggle="modal"
                        data-target="#PatientProfileDetails"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/step-3.png" />
                        </span>
                        <h5>Step 3</h5>
                        <p>Review Our Policies</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientProfileDetails">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="ModalTopArea">
                  <div className="LoginBox">
                    <div className="StepHead">
                      <h6>
                        Step <strong>1</strong>/3
                      </h6>
                    </div>
                    <div className="BookingModalArea">
                      <h3 className="Title">Profile Details</h3>
                    </div>
                    <form>
                      <div className="LoginBody">
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>First Name</label>
                        </div>
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>Last Name</label>
                        </div>
                        <div className="SelectGender">
                          <div className="form-group">
                            <label>Select Gender</label>
                            <ul>
                              <li>
                                <input type="radio" name="gender" />
                                <aside>
                                  <span>Male</span>
                                </aside>
                              </li>
                              <li>
                                <input type="radio" name="gender" />
                                <aside>
                                  <span>Female</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="SelectGender">
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <input type="date" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark FolderPermissionId"
                          data-toggle="modal"
                          data-target="#PatientInsuranceInformation"
                          data-dismiss="modal"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientInsuranceInformation">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="ModalTopArea">
                  <div className="LoginBox">
                    <div className="StepHead">
                      <h6>
                        Step <strong>2</strong>/3
                      </h6>
                    </div>
                    <div className="BookingModalArea">
                      <h3 className="Title">Insurance Information</h3>
                    </div>
                    <form>
                      <div className="LoginBody">
                        <div className="form-group">
                          <label>Carrier Name</label>
                          <select className="form-control">
                            <option>Select</option>
                            <option>Tricare West</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Independent Physician Association (IPA)</label>
                          <select className="form-control">
                            <option>Select IPA</option>
                            <option />
                          </select>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input type="text" className="form-control" />
                              <label>Member ID</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input type="text" className="form-control" />
                              <label>Group ID/No.</label>
                            </div>
                          </div>
                        </div>
                        <div className="SelectGender">
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <input type="date" className="form-control" />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="SelectGender">
                            <label>Upload Insurance Card Photo</label>
                            <figure>
                              <img src="images/card.png" />
                            </figure>
                          </div>
                          <div className="ImageSize">
                            <figcaption>
                              <h4>Jpeg, Png &amp; PDF</h4>
                              <p>20 MB max</p>
                            </figcaption>
                            <button className="Button Plus">
                              <input type="file" />
                              <i className="fa fa-plus" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark FolderPermissionId"
                          data-toggle="modal"
                          data-target="#PatientSubscriberInformation"
                          data-dismiss="modal"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientSubscriberInformation">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="StepHead">
                    <h6>
                      Step <strong>2</strong>/3
                    </h6>
                  </div>
                  <div className="ModalTopArea">
                    <div className="BookingModalArea">
                      <h3 className="Title">Subscriber Information</h3>
                    </div>
                    <form>
                      <div className="LoginBody">
                        <div className="form-group">
                          <label>Patient’s Relationship to Subscriber</label>
                          <select className="form-control">
                            <option>Select</option>
                            <option>Tricare West</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>First Name</label>
                        </div>
                        <div className="SelectGender">
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <input type="date" className="form-control" />
                          </div>
                        </div>
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>Subscriber Address</label>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input type="text" className="form-control" />
                              <label>City</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input type="text" className="form-control" />
                              <label>State</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#PatientAccountCreatedStep2"
                          data-dismiss="modal"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientAccountCreatedStep2">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="ModalTopArea">
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                    </div>
                    <div className="AccountCreatBody">
                      <article>
                        <h3>Account Created</h3>
                        <h4>
                          Congratulations your account has been created
                          sucsessfully
                        </h4>
                        <p>Now proceed filling your insurance details</p>
                      </article>
                    </div>
                    <div className="StepBodyArea">
                      <ul>
                        <li
                          data-toggle="modal"
                          data-target="#PatientProfileDetails"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/step-1.png" />
                          </span>
                          <h5>Step 1</h5>
                          <p>Profile Details</p>
                          <span className="GreenCheck">
                            <i className="fa fa-check" aria-hidden="true" />
                          </span>
                        </li>
                        <li
                          data-toggle="modal"
                          data-target="#PatientProfileDetails"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/step-2.png" />
                          </span>
                          <h5>Step 2</h5>
                          <p>Insurance Information</p>
                          <span className="GreenCheck">
                            <i className="fa fa-check" aria-hidden="true" />
                          </span>
                        </li>
                        <li
                          data-toggle="modal"
                          data-target="#PatientProfileDetails"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/step-3.png" />
                          </span>
                          <h5>Step 3</h5>
                          <p>Review Our Policies</p>
                        </li>
                      </ul>
                    </div>
                    <div className="ButtonBox">
                      <button
                        className="Gray"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#PatientReviewOurPolicy"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientReviewOurPolicy">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="LoginBox">
                  <div className="StepHead">
                    <h6>
                      Step <strong>3</strong>/3
                    </h6>
                  </div>
                  <div className="AccountCreatBody">
                    <article>
                      <h3>Review Our Policy</h3>
                    </article>
                    <div className="HippaCompliance">
                      <h6>Hippa Compliance</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod incididunt ut labore et dolore magna
                        aliqua. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Lorem ipsum dolor sit
                        amet, consectetur
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod incididunt ut labore et dolore magna
                        aliqua. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Lorem ipsum dolor sit
                        amet, consectetur
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod incididunt ut labore et dolore magna
                        aliqua. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Lorem ipsum dolor sit
                        amet, consectetur
                      </p>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#PatientAccountCongratulationsModal"
                          data-dismiss="modal"
                        >
                          Agree &amp; Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="PatientAccountCongratulationsModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </button>
                <div className="ModalTopArea">
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                      <h3>Congratulation</h3>
                      <p>Your Profile is now completed</p>
                    </div>
                    <div className="ButtonBox">
                      <button className="Dark" id="AccountCreated">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
