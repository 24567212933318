// src/Timer.js
import React, { useState, useEffect, useRef } from "react";

const Timer = () => {
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(true); // Start timer automatically
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds <= 1) {
            clearInterval(intervalRef.current);
            setIsActive(false); // Stop the timer and show reset button
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [isActive]);

  const resetTimer = () => {
    setSeconds(60);
    setIsActive(true); // Restart the timer
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {isActive ? (
        <h6>{`Resend Code in ${seconds} seconds`}</h6>
      ) : (
        <button className="btn btn-sm btn-secondary" onClick={resetTimer}>
          Resend
        </button>
      )}
    </div>
  );
};

export default Timer;
