import React, { useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { showNotification } from "../../helper/helper";
import { toast } from "react-toastify";
import { setUser } from "../../store/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import Timer from "../timerComponent/Timer";
import { setLogin } from "../../store/LoginPopupSlice";
import { setBookingPopup } from "../../store/BookingPopUpSlice";
const PatientLoginOTPModal = ({
  toggleEmailVerify,
  settoggleEmailVerify,
  email,
  otpInputs,
  setOtpInputs,
  otp,
  token,
  user,
  setisLoggedIn,
}) => {
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const onGoingBooking = useSelector((state) => state.appointmentTrack);

  const handleInputChange = (index, value) => {
    if (isNaN(value)) return; // Allow only numeric inputs

    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);

    // Move focus to the next input if value is entered
    if (value && index < otpInputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Move focus to previous input if backspace is pressed
    if (e.key === "Backspace" && index > 0 && !otpInputs[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  function handleClose() {
    setOtpInputs(["", "", "", ""]);
    settoggleEmailVerify(false);
    if (onGoingBooking) {
      dispatch(setLogin(false));
      // dispatch(setBookingPopup(true));
      toast("Continue with your appointment");
    } else {
      dispatch(setLogin(false));
    }
  }

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const enteredOtp = otpInputs.join("");
    console.log("otp", otp);
    console.log("enteredOtp", enteredOtp);
    if (otp === enteredOtp) {
      handleClose();
      setTimeout(() => {}, 400);
      showNotification(
        "success",
        "OTP Verification Successful",
        "You have successfully logged in."
      );
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      setisLoggedIn(true);

      localStorage.setItem("isLoggedIn", true);
      dispatch(setUser(user));
      // window.location.reload();
    } else {
      console.log("toast hit");
      toast("Incorrect OTP");
    }
  };

  return (
    <Modal
      show={toggleEmailVerify}
      onHide={handleClose}
      backdrop="static"
      id="PatientLoginOTPModal"
      centered
    >
      <div className="modal-content">
        <div className="modal-body">
          <button
            type="button"
            className="CloseModal"
            onClick={handleClose}
            aria-label="Close"
          >
            &times;
          </button>
          <div className="LoginBox">
            <div className="LoginHead">
              <img src="images/Fav.png" alt="Logo" />
            </div>
            <div className="LoginBody">
              <article>
                <h3>OTP Code Verification</h3>
                <p>Code has been sent to {email}</p>
              </article>
              <Form onSubmit={handleVerifyOtp}>
                <Form.Group className="form-group">
                  <div className="OTPBox">
                    {otpInputs.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        className="form-control"
                        maxLength={1}
                        placeholder=""
                        value={value}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                </Form.Group>
                <div className="ButtonBox">
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={otpInputs.some((input) => input === "")}
                  >
                    Verify
                  </Button>
                </div>
              </Form>

              <aside>
                {/* <h6>
                  Resend Code In <strong>55</strong> s
                </h6> */}
                <Timer />
              </aside>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PatientLoginOTPModal;
