import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { patientService } from "../../services/patientServices";
import { uploadToAws } from "../../helper/s3Helper";
import moment from "moment";
const EditFamily = ({ show, hide, singleMember, getFamilyMem, user }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    // Populate state with singleMember data when it changes
    if (singleMember && singleMember.length > 0) {
      const member = singleMember[0]; // Assuming singleMember is an array with one member
      setFirstName(member.firstname || "");
      setLastName(member.lastname || "");
      setGender(member.gender || "");
      setDob(member.dob ? member.dob.substring(0, 10) : ""); // Extract date part only
    }
  }, [singleMember]);

  useEffect(() => {
    if (selectedFile != null) {
      updateProfilePic();
    }
  }, [selectedFile]);

  function handleClose() {
    hide(false);
  }

  const handleSave = async () => {
    const res = await patientService.updateFamilyMem(
      {
        firstname: firstName,
        lastname: lastName,
        gender: gender,
        dob: dob,
        patient: user?._id,
      },
      singleMember[0]?._id
    );

    if (res.status) {
      getFamilyMem();
      handleClose();
    } else {
    }
  };

  const updateProfilePic = async () => {
    const profile_pic = await uploadToAws(selectedFile);

    const res = await patientService.updateFamilyMem(
      {
        patient: user?._id,
        profile_pic: profile_pic,
      },
      singleMember[0]?._id
    );

    if (res.status) {
      getFamilyMem();
      handleClose();
    } else {
    }

    // handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} id="EditFamily" centered>
      <Modal.Body>
        <button type="button" className="CloseModal" onClick={handleClose}>
          &times;
        </button>
        <div className="ModalTopArea">
          <div className="LoginBox">
            <div className="BookingModalArea">
              <h3 className="Title">Edit Family Profile</h3>
            </div>
            <Form>
              <div className="LoginBody">
                <div className="EditProfileFigure">
                  <figure>
                    <img
                      src={
                        singleMember[0]?.profile_pic
                          ? singleMember[0]?.profile_pic
                          : "../images/familyAvatar.png"
                      }
                      alt="Family Member Avatar"
                    />
                    <div className="InputBox">
                      <img src="../images/edit-icon.png" alt="Edit Icon" />
                      <input
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setSelectedFile(file);
                        }}
                      />
                    </div>
                  </figure>
                </div>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="SelectGender d-flex align-items-center mt-3">
                    <Form.Label>Select Gender</Form.Label>
                    <Form.Check
                      inline
                      type="radio"
                      label="Male"
                      className="ml-3"
                      name="gender"
                      checked={gender === "male"}
                      onChange={() => setGender("male")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Female"
                      name="gender"
                      checked={gender === "female"}
                      onChange={() => setGender("female")}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    max={today}
                  />
                </Form.Group>
              </div>
              <div className="ButtonBox">
                <Button
                  variant="dark"
                  className="FolderPermissionId"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditFamily;
