// // userSlice.js
// import { createSlice } from "@reduxjs/toolkit";

// const userSlice = createSlice({
//   name: "user",
//   initialState: null,
//   reducers: {
//     setUser: (state, action) => action.payload,
//     clearUser: () => null,
//   },
// });

// export const { setUser, clearUser } = userSlice.actions;
// export default userSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: false,
  reducers: {
    setUser: (state, action) => action.payload,
    clearUser: () => null,
    updateUser: (state, action) => {
      if (state) {
        // Return a new state with the updated key-value pair
        return { ...state, ...action.payload };
      }
      return state; // No updates if state is null
    },
  },
});

export const { setUser, clearUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
