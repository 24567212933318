import { io } from "socket.io-client";
import { toast } from "react-toastify";

let socket;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const initializeSocket = (userId) => {
  socket = io(SOCKET_URL, {
    query: {
      id: userId,
    },
    transports: ["websocket"],
  });

  return socket;
};

export const getSocket = (userId) => {
  // toast("socket connection hit");
  // Checking if the socket is already initialized
  if (!socket) {
    // If not initialized, call initializeSocket with the userId to connect then return socket istance.
    socket = initializeSocket(userId);
  }
  return socket;
};

// import { io } from "socket.io-client";

// class SocketService {
//   static instance;

//   constructor() {
//     if (SocketService.instance) {
//       return SocketService.instance;
//     }

//     this.socket = null;
//     SocketService.instance = this;
//   }

//   initialize(userId) {
//     if (!this.socket) {
//       const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
//       this.socket = io(SOCKET_URL, {
//         query: {
//           id: userId,
//         },
//         transports: ["websocket"],
//       });
//     }
//     return this.socket;
//   }

//   getSocket(userId) {
//     if (!this.socket) {
//       this.initialize(userId);
//     }
//     return this.socket;
//   }
// }

// const socketService = new SocketService();

// export const initializeSocket = (userId) => socketService.initialize(userId);

// export const getSocket = (userId) => socketService.getSocket(userId);
