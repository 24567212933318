import { Helmet } from "react-helmet";
const Chat = () => {
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>
      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Chat</h4>
          </div>
          <a href="messages-chat.html">
            <div className="ChatBox">
              <aside>
                <span className="Icon">
                  <img src="../images/chat-img.png" />
                </span>
                <h6>Dr. Emma Sophia</h6>
                <p>Hi How Are You?</p>
              </aside>
              <aside>
                <p>05:21 pm</p>
                <span className="Count">02</span>
              </aside>
            </div>
          </a>
          <a href="messages-chat.html">
            <div className="ChatBox">
              <aside>
                <span className="Icon">
                  <img src="../images/chat-img.png" />
                </span>
                <h6>Dr. Emma Sophia</h6>
                <p>Hi How Are You?</p>
              </aside>
              <aside>
                <p>05:21 pm</p>
                <span className="Count">02</span>
              </aside>
            </div>
          </a>
          <a href="messages-chat.html">
            <div className="ChatBox">
              <aside>
                <span className="Icon">
                  <img src="../images/chat-img.png" />
                </span>
                <h6>Dr. Emma Sophia</h6>
                <p>Hi How Are You?</p>
              </aside>
              <aside>
                <p>05:21 pm</p>
                <span className="Count">02</span>
              </aside>
            </div>
          </a>
          <a href="messages-chat.html">
            <div className="ChatBox">
              <aside>
                <span className="Icon">
                  <img src="../images/chat-img.png" />
                </span>
                <h6>Dr. Emma Sophia</h6>
                <p>Hi How Are You?</p>
              </aside>
              <aside>
                <p>05:21 pm</p>
                <span className="Count">02</span>
              </aside>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Chat;
