import { Link } from "react-router-dom";
import Logo from "../../assets/imges/logo.svg";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="Footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <div className="Foots">
                  <figure>
                    <img src={Logo} />
                  </figure>
                  <p>
                    We are a comprehensive healthcare company offering primary
                    care, urgent care, lab testing, X-ray services, and an
                    in-house pharmacy. We provide accessible care through
                    affordable membership options as well as for patients with
                    major health insurance plans, medicare and Medical
                  </p>
                  <ol>
                    <li>
                      <a href="javascript:void(0);">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i className="fa fa-linkedin" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="Foots">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-condition">
                        Term &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Legal Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="Foots">
                  <h3>Information</h3>
                  <h6>
                    <span>
                      <img src="/images/Icon-1.png" />
                    </span>{" "}
                    916-964-2001
                  </h6>
                  <h6>
                    <span>
                      <img src="/images/Icon-2.png" />
                    </span>{" "}
                    info@urhealthcare.org
                  </h6>
                  <h6>
                    <span>
                      <img src="/images/Icon-3.png" />
                    </span>{" "}
                    6839 Five start Blvd, Suite -D Rocklin, California, 95677
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Copyright">
          <p>Copyright © 2024 UrHealthcare. All Right reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
