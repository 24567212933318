import { useState, useEffect } from "react";
import { patientService } from "../../services/patientServices";
import { getOtp } from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { setUser, clearUser } from "../../store/UserSlice";
import { setbooking } from "../../store/ConsultationSlice";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PatientLoginModal from "../Popups/PatientPopup";
import PatientLoginOTPModal from "../Popups/EmailOtp";
import PatientEmailVerifyRegister from "../Popups/EmailVerifyRegister";
import VerifyPhone from "../Popups/VerifyPhone";
import VerifyPhoneOtp from "../Popups/VerifyPhoneOtp";
import Congratulations from "../Popups/Congratulations";
import DoctorLoginPopup from "../Popups/DoctorLoginPopup";
import { setdocpopup } from "../../store/DocLoginSlice";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { setLogin } from "../../store/LoginPopupSlice";
import female from "../../assets/imges/Avatar.png";
import male from "../../assets/imges/Avatar-1.png";

import Logo from "../../assets/imges/logo.svg"


const Header = ({ isLoggedIn, setisLoggedIn, userData }) => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.user);
  const docPopoup = useSelector((state) => state.togglePopup);
  const reduxbooking = useSelector((state) => state.booking);
  const reduxLogin = useSelector((state) => state.login);
  const [togglePopup, settogglePopup] = useState(false);
  const [toggleEmailVerify, settoggleEmailVerify] = useState(false);
  const [toggleVerifyPhone, settoggleVerifyPhone] = useState(false);
  const [toggleOTPModal, setToggleOTPModal] = useState(false);
  const [toggleEmailVerifyRegister, settoggleEmailVerifyRegister] =
    useState(false);
  const [togglecongratulations, settogglecongratulations] = useState(false);
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [phoneRegister, setphoneRegister] = useState("");
  const [token, settoken] = useState("");
  const [otp, setotp] = useState("");
  const [otpregister, setotpRegister] = useState("");
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [user, setuser] = useState({});
  //states for doctor login popup
  const [showDocLogin, setshowDocLogin] = useState(false);

  //.......

  const navigate = useNavigate();

  //.......

  function logout() {
    dispatch(setUser(null));
    localStorage.clear();
    setisLoggedIn(false);
    navigate("/");
  }

  function triggerPopup() {
    console.log("reduxbooking", reduxbooking);
    if (reduxbooking) {
      dispatch(setbooking(false));
    } else {
      navigate("/");
      dispatch(setbooking(true));
    }
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <>
      {/* patient login flow starts */}
      <PatientLoginModal
        togglePopup={reduxLogin}
        settogglePopup={settogglePopup}
        phone={phone}
        setphone={setphone}
        email={email}
        setemail={setemail}
        settoken={settoken}
        setotp={setotp}
        settoggleEmailVerify={settoggleEmailVerify}
        setuser={setuser}
        toggleEmailVerifyRegister={toggleEmailVerifyRegister}
        settoggleEmailVerifyRegister={settoggleEmailVerifyRegister}
        setshowDocLogin={setshowDocLogin}
      />
      <PatientLoginOTPModal
        toggleEmailVerify={toggleEmailVerify}
        settoggleEmailVerify={settoggleEmailVerify}
        email={email}
        otpInputs={otpInputs}
        setOtpInputs={setOtpInputs}
        otp={otp}
        token={token}
        user={user}
        setisLoggedIn={setisLoggedIn}
      />

      <PatientEmailVerifyRegister
        toggleEmailVerify={toggleEmailVerifyRegister}
        settoggleEmailVerify={settoggleEmailVerifyRegister}
        email={email}
        otpInputs={otpInputs}
        setOtpInputs={setOtpInputs}
        otp={otp}
        token={token}
        user={user}
        setisLoggedIn={setisLoggedIn}
        togglePhoneModal={toggleVerifyPhone}
        setTogglePhoneModal={settoggleVerifyPhone}
      />

      <VerifyPhone
        togglePhoneModal={toggleVerifyPhone}
        setTogglePhoneModal={settoggleVerifyPhone}
        toggleOTPModal={toggleOTPModal}
        setToggleOTPModal={setToggleOTPModal}
        phone={phoneRegister}
        setphone={setphoneRegister}
        otpregister={otpregister}
        setotpRegister={setotpRegister}
        setshowDocLogin={setshowDocLogin}
      />

      <VerifyPhoneOtp
        toggleOTPModal={toggleOTPModal}
        setToggleOTPModal={setToggleOTPModal}
        phoneRegister={phoneRegister}
        otpregister={otpregister}
        email={email}
        setisLoggedIn={setisLoggedIn}
        togglecongratulations={togglecongratulations}
        settogglecongratulations={settogglecongratulations}
      />

      <Congratulations
        setisLoggedIn={setisLoggedIn}
        show={togglecongratulations}
        hide={settogglecongratulations}
      />

      {/*Doctor Login Starts */}
      <DoctorLoginPopup
        showDocLogin={showDocLogin}
        setshowDocLogin={setshowDocLogin}
        docPopoup={docPopoup}
      />

      <header>
        <div className="Header">
          <Container>
            <div className="Social">
              <ul>
                <li>
                  <span>
                    <img src="/images/Icon-1.png" alt="Phone Icon" />{" "}
                  </span>{" "}
                  916-964-2001
                </li>
                <li>
                  <span>
                    <img src="/images/Icon-2.png" alt="Email Icon" />{" "}
                  </span>{" "}
                  info@urhealthcare.org
                </li>
                <li>
                  <span>
                    <img src="/images/Icon-3.png" alt="Location Icon" />{" "}
                  </span>{" "}
                  6839 Five start Blvd, Suite -D Rocklin, California, 95677
                </li>
              </ul>
              <ol>
                {isLoggedIn ? null : (
                  <li className="Login">
                    <a href="#" onClick={() => setshowDocLogin(true)}>
                      <span>
                        <i className="fa fa-user-circle-o" aria-hidden="true" />
                      </span>{" "}
                      Doctor Login
                    </a>
                  </li>
                )}

                {/* Social Media Icons */}
                {["facebook", "twitter", "linkedin", "vimeo", "instagram"].map(
                  (platform) => (
                    <li key={platform}>
                      <a href="#">
                        <i className={`fa fa-${platform}`} aria-hidden="true" />
                      </a>
                    </li>
                  )
                )}
              </ol>
            </div>
          </Container>
        </div>
        <div className="Navigation">
          <Container>
            <Navbar expand="lg" variant="light">
              <Link className="navbar-brand" to="/">
                <img src={Logo} alt="Logo" />
             
              </Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  {" "}
                  {/* Align nav items to the right */}
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                  <Link className="nav-link" to="/how-it-works">
                    How it Works
                  </Link>
                  <Link className="nav-link" to="/about">
                    About Us
                  </Link>
                  <Link className="nav-link" to="/patient/membership">
                    Membership & Pricing
                  </Link>
                  <Link className="nav-link" to="/faq">
                    FAQ's
                  </Link>
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                  {!isLoggedIn && (
                    <li className="nav-item Login">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => {
                          dispatch(setLogin(true));
                          settogglePopup(true);
                        }}
                      >
                        <span>
                          <i
                            className="fa fa-user-circle-o"
                            aria-hidden="true"
                          />
                        </span>
                        Login
                      </a>
                    </li>
                  )}
                  {reduxUser && (
                    <NavDropdown
                      title={
                        <>
                          Hi {reduxUser?.firstname || reduxUser?.email}
                          <span>
                            <img
                              src={
                                reduxUser?.profile_pic
                                  ? reduxUser?.profile_pic
                                  : reduxUser?.gender?.toLowerCase() === "male"
                                  ? male
                                  : female
                              }
                              alt="Profile"
                              className="profile-pic"
                            />
                          </span>
                        </>
                      }
                      id="basic-nav-dropdown"
                    >
                      <div className="NewDropdownBox">
                        <NavDropdown.Item as={Link} to="/patient/my-profile">
                          My Profile
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/patient/appointment">
                          Appointment
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/patient/notification">
                          Notifications
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="patient/vitals">
                          Vitals
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item as={Link} to="patient/prescription">
                        Prescription
                      </NavDropdown.Item> */}
                        <NavDropdown.Item as={Link} to="patient/core-plan">
                          Care Plan
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item as={Link} to="patient/medical-reports">
                        Medical Images
                      </NavDropdown.Item> */}
                        <NavDropdown.Item as={Link} to="patient/medications">
                          Medications
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="patient/lab-reports">
                          Lab Results
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="patient/document">
                          Documents
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="patient/insurance">
                          Insurance
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/patient/membership">
                          Membership <br /> Plans
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="patient/help-support">
                          Help & Support
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="patient/settings">
                          Settings
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={logout}>
                          Logout
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={triggerPopup}>
                          Book Consultation
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </header>

      {/* <header>
        <div className="Header">
          <div className="container">
            <div className="Social">
              <ul>
                <li>
                  <span>
                    <img src="/images/Icon-1.png" />{" "}
                  </span>{" "}
                  +1 123 123 1122
                </li>
                <li>
                  <span>
                    <img src="/images/Icon-2.png" />{" "}
                  </span>{" "}
                  info@galenic.com
                </li>
                <li>
                  <span>
                    <img src="/images/Icon-3.png" />{" "}
                  </span>{" "}
                  Thornton Heath, United Kingdom
                </li>
              </ul>
              <ol>
                <li className="Login">
                  <a
                    href="javascript:void(0);"
                    onClick={() => setshowDocLogin(true)}
                  >
                    <span>
                      <i className="fa fa-user-circle-o" aria-hidden="true" />
                    </span>{" "}
                    Doctor Login
                  </a>
                </li>

                <li>
                  <a href="javascript:void(0);">
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i className="fa fa-twitter" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i className="fa fa-linkedin" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i className="fa fa-vimeo" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i className="fa fa-instagram" aria-hidden="true" />
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="Navigation">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/">
                <img src="/images/Logo.png" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Menu"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="Menu">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/how-it-works">
                      How it Works
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="patient/membership">
                      Membership &amp; Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/faq">
                      FAQ's
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">
                      Contact
                    </Link>
                  </li>
                  {isLoggedIn ? null : (
                    <li
                      className="nav-item Login"
                      onClick={() => settogglePopup(true)}
                    >
                      <a className="nav-link" href="javascript:void(0);">
                        <span>
                          <i
                            className="fa fa-user-circle-o"
                            aria-hidden="true"
                          />
                        </span>
                        Login
                      </a>
                    </li>
                  )}

                  {reduxUser != null ? (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle "
                        href="javascript:void(0);"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Hi{" "}
                        {reduxUser?.firstname
                          ? reduxUser?.firstname
                          : reduxUser.email}
                        <span>
                          <img
                            src={
                              reduxUser?.profile_pic != ""
                                ? reduxUser?.profile_pic
                                : "/images/boy-avatar.png"
                            }
                          />
                        </span>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            to="/patient/my-profile"
                            className="dropdown-item"
                          >
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/patient/appointment"
                            className="dropdown-item"
                          >
                            Appointment
                          </Link>
                        </li>
                        <li>
                          <Link to="patient/vitals" className="dropdown-item">
                            Vitals
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="patient/prescription"
                            className="dropdown-item"
                          >
                            Prescription
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="patient/core-plan"
                            className="dropdown-item"
                          >
                            Care Plan
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="patient/medical-reports"
                            className="dropdown-item"
                          >
                            Medical Images
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="patient/lab-reports"
                            className="dropdown-item"
                          >
                            Lab Results
                          </Link>
                        </li>
                        <li>
                          <Link to="patient/document" className="dropdown-item">
                            Documents
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="patient/insurance"
                            className="dropdown-item"
                          >
                            Insurance
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/patient/membership"
                            className="dropdown-item"
                          >
                            Membership <br /> Transactions
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="patient/help-support"
                            className="dropdown-item"
                          >
                            Help &amp; Support
                          </Link>
                        </li>
                        <li>
                          <Link to="patient/settings" className="dropdown-item">
                            Settings
                          </Link>
                        </li>
                        <li>
                          <span
                            className="dropdown-item cursor-pointer"
                            onClick={logout}
                          >
                            Logout
                          </span>
                        </li>
                        <li className="BookConsultation">
                          <a
                            className="dropdown-item"
                            onClick={() => triggerPopup()}
                          >
                            Book Consultation
                          </a>
                        </li>
                      </ul>
                    </li>
                  ) : null}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header> */}
      {/*doctor modal */}
      <>
        <div className="ModalBox">
          <div className="modal fade" id="DoctorLoginModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Login to Your Account</h3>
                        <p>
                          We’ll use your email to find your account if you have
                          one
                        </p>
                      </article>
                      <form>
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>Email Address</label>
                        </div>
                        <div className="form-group">
                          <input type="password" className="form-control" />
                          <label>Email Password</label>
                          <span className="Icon">
                            <img src="/images/Eye.png" />{" "}
                          </span>
                        </div>
                        <div className="form-group">
                          <h5>
                            <a
                              href="javascript:void(0)"
                              data-dismiss="modal"
                              data-toggle="modal"
                              data-target="#DoctorResetModal"
                            >
                              Reset Password?
                            </a>
                          </h5>
                        </div>
                        <div className="ButtonBox">
                          <button className="Dark" id="Login">
                            Sign In
                          </button>
                          <button
                            className="Light"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginPhoneModal"
                          >
                            Login Using OTP
                          </button>
                        </div>
                      </form>
                      {/* <aside>
                              <h6>Are you seeking care?  <a href="">Login here</a> </h6>
                          </aside> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Reset Password</h3>
                        <p>
                          We’ll send otp to your email address to verify your
                          account
                        </p>
                      </article>
                      <form>
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>Email Address </label>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorResetOTPModal"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetOTPModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>OTP Code Verification</h3>
                        <p>Code has been send to ******@gmail.com</p>
                      </article>
                      <form>
                        <div className="form-group">
                          <div className="OTPBox">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Gray"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorResetPasswordModal"
                          >
                            Verify
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Resend Code In<strong>55</strong>s{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetPasswordModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Reset Password</h3>
                        {/* <p>We’ll send otp to your email address to verify your account</p> */}
                      </article>
                      <form>
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>Change Password</label>
                          <span className="Icon">
                            <img src="/images/Eye.png" />{" "}
                          </span>
                        </div>
                        <div className="form-group">
                          <label>Confirm Password</label>
                          <span className="Icon">
                            <img src="/images/Eye.png" />{" "}
                          </span>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorResetCongratulationsModal"
                          >
                            Change Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorResetCongratulationsModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Congratulations.png" />
                      <h3>Congratulations</h3>
                      <p>Your password has been changed successfully!</p>
                    </div>
                    <div className="LoginBody">
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#DoctorLoginModal"
                        >
                          Login Again
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorLoginPhoneModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>Welcome to Ur Health</h3>
                        <p>
                          We’ll use your mobile number to find your account if
                          you have one
                        </p>
                      </article>
                      <form>
                        <div className="form-group">
                          <input type="text" className="form-control" />
                          <label>Phone Number</label>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginOTPModal"
                          >
                            Submit
                          </button>
                          <button
                            className="Light"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginModal"
                          >
                            Login Using Password
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Are you seeking care?{" "}
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#DoctorLoginModal"
                            data-dismiss="modal"
                          >
                            Login here
                          </a>{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorLoginOTPModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Fav.png" />
                    </div>
                    <div className="LoginBody">
                      <article>
                        <h3>OTP Code Verification</h3>
                        <p>Code has been send to +1 111 *******91</p>
                      </article>
                      <form>
                        <div className="form-group">
                          <div className="OTPBox">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                            <input
                              type="text"
                              className="form-control"
                              maxLength={1}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Gray"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#DoctorLoginCongratulationsModal"
                          >
                            Verify
                          </button>
                        </div>
                      </form>
                      <aside>
                        <h6>
                          Resend Code In<strong>55</strong>s{" "}
                        </h6>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="DoctorLoginCongratulationsModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </button>
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="/images/Congratulations.png" />
                      <h3>Welcome to ur HEALTHCARE</h3>
                      <p>
                        Congratulations <br /> Your have logged in successfully
                      </p>
                    </div>
                    <div className="LoginBody">
                      <div className="ButtonBox">
                        <button className="Dark" id="LoginOTP">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* patient modal */}

        <>
          <div className="ModalBox">
            <div className="modal fade" id="BookingConsultation">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="BookingModalArea">
                      <h3 className="Title">Get Care</h3>
                      <div className="CommonTabs">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#Virtual"
                            >
                              Virtual
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Completed"
                            >
                              Clinic Visit
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="Virtual">
                          <div className="BookingClinicVisit">
                            <h1>Book a Clinic Visit appointment</h1>
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#ExplainReasonVisitModal"
                              data-dismiss="modal"
                            >
                              <div className="CareBox">
                                <span className="Icon">
                                  <img src="images/care-icon.png" />
                                </span>
                                <aside>
                                  <h3>Virtual Urgent Care</h3>
                                  <p>Today at 06:40 PM</p>
                                </aside>
                                <span>
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </a>
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#ExplainReasonVisitModal"
                              data-dismiss="modal"
                            >
                              <div className="CareBox">
                                <span className="Icon">
                                  <img src="images/care-icon.png" />
                                </span>
                                <aside>
                                  <h3>Virtual primary Care</h3>
                                  <p>Today at 06:40 PM</p>
                                </aside>
                                <span>
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="Completed">
                          <div className="BookingClinicVisit">
                            <h1>Book a Clinic Visit appointment</h1>
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#BookClinicVisitLocation"
                              data-dismiss="modal"
                            >
                              <div className="CareBox">
                                <span className="Icon">
                                  <img src="images/care-icon.png" />
                                </span>
                                <aside>
                                  <h3>Urgent Care</h3>
                                  <p>Today at 06:40 PM</p>
                                </aside>
                                <span>
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </a>
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#BookClinicVisitLocation"
                              data-dismiss="modal"
                            >
                              <div className="CareBox">
                                <span className="Icon">
                                  <img src="images/care-icon.png" />
                                </span>
                                <aside>
                                  <h3>Urgent Care</h3>
                                  <p>Today at 06:40 PM</p>
                                </aside>
                                <span>
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ExplainReasonVisitModal">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="BookingModalArea">
                      <h3 className="Title">
                        Explain what is reason <br /> for visit.
                      </h3>
                      <div className="CommonFormModal">
                        <div className="form-group">
                          <label>Write your concern here</label>
                          <textarea
                            className="form-control"
                            placeholder="write here.."
                            rows={8}
                            defaultValue={""}
                          />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ScheduleVirtualCall"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ScheduleVirtualCall">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="BookingModalArea">
                        <h3 className="Title">Schedule Virtual Call</h3>
                        <div className="CommonFormModal">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="form-group">
                                <input type="date" className="form-control" />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="form-group">
                                <select className="form-control">
                                  <option>Select</option>
                                  <option>California</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <figure>
                          <img src="images/Date.png" />
                        </figure>
                        <div className="SelectTimeBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/Avatar-1.png" />
                            </span>
                            <h3>Edmund Li</h3>
                            <ul>
                              <li>
                                <span>
                                  <img src="images/virtual-icon.png" />
                                </span>
                                Virtual
                              </li>
                              <li>
                                <span>
                                  <img src="images/location-icon.png" />
                                </span>
                                7000 mi away
                              </li>
                            </ul>
                          </aside>
                          <p>
                            <span>
                              <img src="images/time-icon.png" />
                            </span>
                            Wednesday, 09:15 AM PDT, Jun 19, 2024
                          </p>
                          <div className="SelectTimeBody">
                            <h4>Select Time</h4>
                            <ul>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="Available">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="SelectTimeBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/Avatar-1.png" />
                            </span>
                            <h3>Edmund Li</h3>
                            <ul>
                              <li>
                                <span>
                                  <img src="images/virtual-icon.png" />
                                </span>
                                Virtual
                              </li>
                              <li>
                                <span>
                                  <img src="images/location-icon.png" />
                                </span>
                                7000 mi away
                              </li>
                            </ul>
                          </aside>
                          <p>
                            <span>
                              <img src="images/time-icon.png" />
                            </span>
                            Wednesday, 09:15 AM PDT, Jun 19, 2024
                          </p>
                          <div className="SelectTimeBody">
                            <h4>Select Time</h4>
                            <ul>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="Available">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-toggle="modal"
                            data-target="#WhoIsAppointment"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="WhoIsAppointment">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="BookingModalArea">
                        <h3 className="Title">
                          Who is the <br /> appointment for?
                        </h3>
                        <div className="AppointmentPersonBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/girl-avatar.png" />
                            </span>
                            <h3>Miss Sharnya</h3>
                            <ul>
                              <li>27 years</li>
                              <li>Female</li>
                            </ul>
                            <span className="RightArrow">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </aside>
                          <aside>
                            <span className="Profile">
                              <img src="images/girl-avatar.png" />
                            </span>
                            <h3>Miss Sharnya</h3>
                            <ul>
                              <li>27 years</li>
                              <li>Female</li>
                            </ul>
                            <span className="RightArrow">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </aside>
                        </div>
                        <div className="text-center">
                          <a
                            href="javascript:void(0);"
                            className="AddPatientBtn"
                          >
                            <i className="fa fa-plus" aria-hidden="true" />
                            Add Patient
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="BookAppAddProfileInfo">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="LoginBox">
                      <div className="LoginBody">
                        <article>
                          <h3>Add Patient Info</h3>
                        </article>
                        <form>
                          <div className="form-group">
                            <label>Select Relationship</label>
                            <select className="form-control">
                              <option>Select</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter First Name"
                            />
                          </div>
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Last Name"
                            />
                          </div>
                          <div className="form-group">
                            <div className="SelectGender">
                              <label>Select Gender</label>
                              <ul>
                                <li>
                                  <input type="radio" name="male" />
                                  <aside>
                                    <span>Male</span>
                                  </aside>
                                </li>
                                <li>
                                  <input type="radio" name="male" />
                                  <aside>
                                    <span>Female</span>
                                  </aside>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <input type="date" className="form-control" />
                          </div>
                          <div className="ButtonBox">
                            <button
                              className="Dark FolderPermissionId"
                              data-toggle="modal"
                              data-target="#"
                              data-dismiss="modal"
                            >
                              Save Details
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="BookConfirmAppointment">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="BookingModalArea">
                        <h3 className="Title">Confirm Appointment</h3>
                        <h3 className="BoxHeading">Doctor</h3>
                        <div className="SelectTimeBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/Avatar-1.png" />
                            </span>
                            <h3>Edmund Li</h3>
                            <ul>
                              <li>
                                <span>
                                  <img src="images/virtual-icon.png" />
                                </span>
                                Virtual
                              </li>
                              <li>
                                <span>
                                  <img src="images/location-icon.png" />
                                </span>
                                Primary Care
                              </li>
                            </ul>
                            <span className="RightArrow">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </aside>
                        </div>
                        <div className="SelectTimeBox">
                          <p>
                            <span>
                              <img src="images/time-icon.png" />
                            </span>
                            Wednesday, 09:15 AM PDT, Jun 19, 2024
                          </p>
                        </div>
                        <h3 className="BoxHeading">Patient</h3>
                        <div className="AppointmentPersonBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/girl-avatar.png" />
                            </span>
                            <h3>Miss Sharnya</h3>
                            <ul>
                              <li>27 years</li>
                              <li>Female</li>
                            </ul>
                          </aside>
                        </div>
                        <div className="ReasonBox">
                          <h3>Reason</h3>
                          <p>
                            Lorem ipsum crest en pic Lorem ipsum crest en
                            picLorem ipsum crest en picLorem ipsum crest en
                          </p>
                        </div>
                        <div className="ModalTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Consultant Fee</td>
                                <td>$40.00</td>
                              </tr>
                              <tr>
                                <td>Taxes</td>
                                <td>$5.00</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>Total</td>
                                <td>$45.00</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-toggle="modal"
                            data-target="#BookEndScheduling"
                            data-dismiss="modal"
                          >
                            Confirm Payment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="BookEndScheduling">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                      style={{ zIndex: 99 }}
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="EndScheduling">
                        <figure>
                          <img src="images/time-management.png" />
                        </figure>
                        <h2>End Scheduling</h2>
                        <p>Are you sure you want to End the scheduling?</p>
                        <div className="ButtonBox d-flex align-items-center justify-content-between">
                          <button className="Light mb-0 mr-2">
                            No, Continue
                          </button>
                          <button className="Dark ml-2">Yes, End</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="BookPayVisit">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="BookingModalArea">
                      <h3 className="Title">
                        How would you like to <br /> pay for the visit?
                      </h3>
                      <div className="UntilVisit">
                        <p>You won’t be charged until your visit</p>
                      </div>
                      <div className="BookingClinicVisit">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#BookPurchaseMemPlan"
                          data-dismiss="modal"
                        >
                          <div className="CareBox">
                            <span className="Icon">
                              <img src="images/care-icon.png" />
                            </span>
                            <aside>
                              <h3>Use Insurance</h3>
                              <p>$5.00</p>
                            </aside>
                            <span>
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#BookPurchaseMemPlan"
                          data-dismiss="modal"
                        >
                          <div className="CareBox">
                            <span className="Icon">
                              <img src="images/care-icon.png" />
                            </span>
                            <aside>
                              <h3>Pay Using Membership</h3>
                              <p>$00.00</p>
                            </aside>
                            <span>
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="BookPurchaseMemPlan">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="BookingModalArea">
                        <h3 className="Title">
                          Purchase Membership <br /> Plan
                        </h3>
                        <div className="PayMonthly">
                          <aside>
                            <span>
                              <img src="images/pay-monthly.png" />
                            </span>
                            <h4>Pay Monthly</h4>
                            <h6>$15 per month</h6>
                          </aside>
                          <p>$20 today. Then $15 per month for 12 months.</p>
                        </div>
                        <div className="MembershipPlan">
                          <ul>
                            <li>
                              <input type="radio" name="plan1" />
                              <aside>
                                <span className="Icon">
                                  <img src="images/plan-1.png" />
                                </span>
                                <h6>Indivisual</h6>
                                <p>$20 pm</p>
                                <span className="CheckOverlay">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                </span>
                              </aside>
                            </li>
                            <li>
                              <input type="radio" name="plan1" />
                              <aside>
                                <span className="Icon">
                                  <img src="images/plan-2.png" />
                                </span>
                                <h6>With Spouse</h6>
                                <p>$30 pm</p>
                                <span className="CheckOverlay">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                </span>
                              </aside>
                            </li>
                            <li>
                              <input type="radio" name="plan1" />
                              <aside>
                                <span className="Icon">
                                  <img src="images/plan-3.png" />
                                </span>
                                <h6>With Spouse &amp; 1 Child</h6>
                                <p>$20 pm</p>
                                <span className="CheckOverlay">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                </span>
                              </aside>
                            </li>
                            <li>
                              <input type="radio" name="plan1" />
                              <aside>
                                <span className="Icon">
                                  <img src="images/plan-4.png" />
                                </span>
                                <h6>With Spouse &amp; 2 Childre</h6>
                                <p>$20 pm</p>
                                <span className="CheckOverlay">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                </span>
                              </aside>
                            </li>
                          </ul>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-toggle="modal"
                            data-target="#BookPaymentSuccess"
                            data-dismiss="modal"
                          >
                            Confirm Payment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="BookPaymentSuccess">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div
                      className="LoginBox"
                      data-toggle="modal"
                      data-target="#"
                    >
                      <div className="LoginHead">
                        <img src="images/Congratulations.png" />
                      </div>
                      <div className="AccountCreatBody">
                        <article>
                          <h3>Account Created</h3>
                          <h4>
                            Congratulations <br /> Your payment was successful
                          </h4>
                        </article>
                      </div>
                      <div className="PaymentSuccessTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <p>Transaction ID</p>
                                <h6>846287467846</h6>
                              </td>
                              <td>
                                <p>Plan Type</p>
                                <h6>Monthly Individual</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Billing Month</p>
                                <h6>$20 Monthly</h6>
                              </td>
                              <td>
                                <p>Purchase Date</p>
                                <h6>20/05/24</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Expiry Date</p>
                                <h6>20/05/24</h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="Note">
                        <span>Note : </span> Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="BookBookingConfirmed">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div
                      className="LoginBox"
                      data-toggle="modal"
                      data-target="#"
                    >
                      <div className="LoginHead">
                        <img src="images/Congratulations.png" />
                      </div>
                      <div className="AccountCreatBody">
                        <article>
                          <h3>Booking Confirmed</h3>
                          <h4>
                            Congratulations <br /> Your payment was successful
                          </h4>
                        </article>
                      </div>
                      <div className="PaymentSuccessTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <p>Transaction ID</p>
                                <h6>846287467846</h6>
                              </td>
                              <td>
                                <p>Plan Type</p>
                                <h6>Monthly Individual</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Billing Month</p>
                                <h6>$20 Monthly</h6>
                              </td>
                              <td>
                                <p>Purchase Date</p>
                                <h6>20/05/24</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Expiry Date</p>
                                <h6>20/05/24</h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="Note">
                        <span>Note : </span> Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua.
                      </p>
                      <div className="ButtonBox">
                        <button
                          className="Dark"
                          data-toggle="modal"
                          data-target="#BookPaymentSuccess"
                          data-dismiss="modal"
                        >
                          Proceed with Appointment Booking
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Clinic Visit Modal  */}
            <div className="modal fade" id="ClinicExplainReasonVisitModal">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="BookingModalArea">
                      <h3 className="Title">
                        Explain what is reason <br /> for visit.
                      </h3>
                      <div className="CommonFormModal">
                        <div className="form-group">
                          <label>Write your concern here</label>
                          <textarea
                            className="form-control"
                            placeholder="write here.."
                            rows={8}
                            defaultValue={""}
                          />
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ClinicScheduleVirtualCall"
                            data-dismiss="modal"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ClinicScheduleVirtualCall">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="BookingModalArea">
                        <h3 className="Title">Schedule Virtual Call</h3>
                        <div className="CommonFormModal">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="form-group">
                                <input type="date" className="form-control" />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="form-group">
                                <select className="form-control">
                                  <option>Select</option>
                                  <option>California</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <figure>
                          <img src="images/Date.png" />
                        </figure>
                        <div className="SelectTimeBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/Avatar-1.png" />
                            </span>
                            <h3>Edmund Li</h3>
                            <ul>
                              <li>
                                <span>
                                  <img src="images/virtual-icon.png" />
                                </span>
                                Virtual
                              </li>
                              <li>
                                <span>
                                  <img src="images/location-icon.png" />
                                </span>
                                7000 mi away
                              </li>
                            </ul>
                          </aside>
                          <p>
                            <span>
                              <img src="images/time-icon.png" />
                            </span>
                            Wednesday, 09:15 AM PDT, Jun 19, 2024
                          </p>
                          <div className="SelectTimeBody">
                            <h4>Select Time</h4>
                            <ul>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="Available">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="SelectTimeBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/Avatar-1.png" />
                            </span>
                            <h3>Edmund Li</h3>
                            <ul>
                              <li>
                                <span>
                                  <img src="images/virtual-icon.png" />
                                </span>
                                Virtual
                              </li>
                              <li>
                                <span>
                                  <img src="images/location-icon.png" />
                                </span>
                                7000 mi away
                              </li>
                            </ul>
                          </aside>
                          <p>
                            <span>
                              <img src="images/time-icon.png" />
                            </span>
                            Wednesday, 09:15 AM PDT, Jun 19, 2024
                          </p>
                          <div className="SelectTimeBody">
                            <h4>Select Time</h4>
                            <ul>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="AlreadyBooked">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="NotAvailable">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                              <li className="Available">
                                <input type="radio" name="selectTime" />
                                <aside>
                                  <span>06:00 am</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-toggle="modal"
                            data-target="#ClinicWhoIsAppointment"
                            data-dismiss="modal"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ClinicWhoIsAppointment">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="BookingModalArea">
                        <h3 className="Title">
                          Who is the <br /> appointment for?
                        </h3>
                        <div className="AppointmentPersonBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/girl-avatar.png" />
                            </span>
                            <h3>Miss Sharnya</h3>
                            <ul>
                              <li>27 years</li>
                              <li>Female</li>
                            </ul>
                            <span className="RightArrow">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </aside>
                          <aside>
                            <span className="Profile">
                              <img src="images/girl-avatar.png" />
                            </span>
                            <h3>Miss Sharnya</h3>
                            <ul>
                              <li>27 years</li>
                              <li>Female</li>
                            </ul>
                            <span className="RightArrow">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </aside>
                        </div>
                        <div className="text-center">
                          <a
                            href="javascript:void(0);"
                            className="AddPatientBtn"
                            data-toggle="modal"
                            data-target="#ClinicAppAddProfileInfo"
                            data-dismiss="modal"
                          >
                            <i className="fa fa-plus" aria-hidden="true" />
                            Add Patient
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ClinicAppAddProfileInfo">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="LoginBox">
                      <div className="LoginBody">
                        <article>
                          <h3>Add Patient Info</h3>
                        </article>
                        <form>
                          <div className="form-group">
                            <label>Select Relationship</label>
                            <select className="form-control">
                              <option>Select</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter First Name"
                            />
                          </div>
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Last Name"
                            />
                          </div>
                          <div className="form-group">
                            <div className="SelectGender">
                              <label>Select Gender</label>
                              <ul>
                                <li>
                                  <input type="radio" name="male" />
                                  <aside>
                                    <span>Male</span>
                                  </aside>
                                </li>
                                <li>
                                  <input type="radio" name="male" />
                                  <aside>
                                    <span>Female</span>
                                  </aside>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <input type="date" className="form-control" />
                          </div>
                          <div className="ButtonBox">
                            <button
                              className="Dark FolderPermissionId"
                              data-toggle="modal"
                              data-target="#ClinicConfirmAppointment"
                              data-dismiss="modal"
                            >
                              Save Details
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ClinicConfirmAppointment">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="BookingModalArea">
                        <h3 className="Title">Confirm Appointment</h3>
                        <h3 className="BoxHeading">Doctor</h3>
                        <div className="SelectTimeBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/Avatar-1.png" />
                            </span>
                            <h3>Edmund Li</h3>
                            <ul>
                              <li>
                                <span>
                                  <img src="images/virtual-icon.png" />
                                </span>
                                Virtual
                              </li>
                              <li>
                                <span>
                                  <img src="images/location-icon.png" />
                                </span>
                                Primary Care
                              </li>
                            </ul>
                            <span className="RightArrow">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </aside>
                        </div>
                        <div className="SelectTimeBox">
                          <p>
                            <span>
                              <img src="images/time-icon.png" />
                            </span>
                            Wednesday, 09:15 AM PDT, Jun 19, 2024
                          </p>
                        </div>
                        <h3 className="BoxHeading">Patient</h3>
                        <div className="AppointmentPersonBox">
                          <aside>
                            <span className="Profile">
                              <img src="images/girl-avatar.png" />
                            </span>
                            <h3>Miss Sharnya</h3>
                            <ul>
                              <li>27 years</li>
                              <li>Female</li>
                            </ul>
                          </aside>
                        </div>
                        <div className="ReasonBox">
                          <h3>Reason</h3>
                          <p>
                            Lorem ipsum crest en pic Lorem ipsum crest en
                            picLorem ipsum crest en picLorem ipsum crest en
                          </p>
                        </div>
                        <div className="ModalTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Consultant Fee</td>
                                <td>$40.00</td>
                              </tr>
                              <tr>
                                <td>Taxes</td>
                                <td>$5.00</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>Total</td>
                                <td>$45.00</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="ButtonBox">
                          <button
                            className="Dark"
                            data-toggle="modal"
                            data-target="#ClinicEndScheduling"
                            data-dismiss="modal"
                          >
                            Confirm Payment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ClinicEndScheduling">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                      style={{ zIndex: 99 }}
                    >
                      ×
                    </button>
                    <div className="ModalTopArea">
                      <div className="EndScheduling">
                        <figure>
                          <img src="images/time-management.png" />
                        </figure>
                        <h2>End Scheduling</h2>
                        <p>Are you sure you want to End the scheduling?</p>
                        <div className="ButtonBox d-flex align-items-center justify-content-between">
                          <button
                            className="Light mb-0 mr-2"
                            data-toggle="modal"
                            data-target="#ClinicPayVisit"
                            data-dismiss="modal"
                          >
                            No, Continue
                          </button>
                          <button className="Dark ml-2">Yes, End</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ClinicPayVisit">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="CloseModal"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <div className="BookingModalArea">
                      <h3 className="Title">
                        How would you like to <br /> pay for the visit?
                      </h3>
                      <div className="UntilVisit">
                        <p>You won’t be charged until your visit</p>
                      </div>
                      <div className="BookingClinicVisit">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#BookPurchaseMemPlan"
                          data-dismiss="modal"
                        >
                          <div className="CareBox">
                            <span className="Icon">
                              <img src="images/care-icon.png" />
                            </span>
                            <aside>
                              <h3>Use Insurance</h3>
                              <p>$5.00</p>
                            </aside>
                            <span>
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#BookPurchaseMemPlan"
                          data-dismiss="modal"
                        >
                          <div className="CareBox">
                            <span className="Icon">
                              <img src="images/care-icon.png" />
                            </span>
                            <aside>
                              <h3>Pay Using Membership</h3>
                              <p>$00.00</p>
                            </aside>
                            <span>
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </>
  );
};

export default Header;
