import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { patientService } from "../../services/patientServices";
import { showNotification } from "../../helper/helper";
import { uploadToAws } from "../../helper/s3Helper";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { IoMdArrowDropdown } from "react-icons/io";
import moment from "moment";

const AddFamily = ({ toggleAddfamily, settoggleAddfamily, getFamilyMem }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    dob: "",
    profile_pic: "",
    address: "",
    city: "",
    state: "",
    relation: "",
  });

  const today = moment().format("YYYY-MM-DD");
  const handleChange = (e) => {
    if (!formData.patient) {
      const user = JSON.parse(localStorage.getItem("user"));
      setFormData({ ...formData, patient: user?._id });
    }
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileUrl = await uploadToAws(file);
    console.log("File url", fileUrl);
    setFormData((prevState) => ({
      ...prevState,
      profile_pic: fileUrl,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loader(true));

    try {
      const res = await patientService.addFamilyMem(formData);

      if (res) {
        dispatch(loader(false));
        console.log("Form submitted with data:", formData);

        // Reset the form after successful submission
        document.getElementById("familyForm").reset();

        showNotification(
          "success",
          "Record saved",
          "Family member added successfully"
        );
        getFamilyMem();
        handleClose();
      } else {
        dispatch(loader(false));
        throw new Error("Failed to add family member");
      }
    } catch (error) {
      dispatch(loader(false));
      console.error("Error adding family member:", error);

      showNotification(
        "error",
        "Record not saved",
        "Something went wrong. Please try again later."
      );
    }
  };

  function handleClose() {
    settoggleAddfamily(false);
    setFormData({
      firstname: "",
      lastname: "",
      gender: "",
      dob: "",
      profile_pic: "",
      address: "",
      city: "",
      state: "",
      relation: "",
    });
  }

  return (
    <Modal show={toggleAddfamily} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Family Member Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} id="familyForm">
          <Form.Group controlId="firstname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="lastname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="gender">
            <Form.Label>Gender</Form.Label>

            <Form.Control
              as="select"
              name="gender"
              className="position-relative"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="dob">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              max={today}
              required
            />
          </Form.Group>
          <Form.Group controlId="profile_pic">
            <Form.Label>Profile Picture</Form.Label>
            <Form.Control
              type="file"
              name="profile_pic"
              onChange={handleFileChange}
              accept="image/*"
              required
            />
            {formData.profile_pic && (
              <img
                src={formData.profile_pic}
                alt="Profile Preview"
                style={{ marginTop: "10px", maxWidth: "100px" }}
              />
            )}
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="state">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="relation">
            <Form.Label>Relationship</Form.Label>
            <Form.Control
              as="select"
              name="relation"
              value={formData.relation}
              onChange={handleChange}
              required
            >
              <option value="">Select Relationship</option>
              <option value="Brother">Brother</option>
              <option value="Sister">Sister</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Husband">Husband</option>
              <option value="Wife">Wife</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Others">Others</option>
            </Form.Control>
          </Form.Group>
          <Button className="mt-3" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddFamily;
