import { Helmet } from "react-helmet";
const Help = () => {
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Help &amp; Support</h4>
          </div>
          <div className="SettingsList">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <h4>Chat with Admin</h4>
                  <p>
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  </p>
                </a>
              </li>
            </ul>
          </div>
          <div className="HelpCenterBox">
            <h4>Help Center</h4>
            <ul>
              <li>
                <span>
                  <img src="../images/call.png" />
                </span>
                <p>Emergency Call</p>
                <h6>100</h6>
              </li>
              <li>
                <span>
                  <img src="../images/call.png" />
                </span>
                <p>Help &amp; Support</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
