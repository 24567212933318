// // SuccessComponent.js
// import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// const SuccessComponent = () => {
//   const location = useLocation();

//   useEffect(() => {
//     const query = new URLSearchParams(location.search);
//     const sessionId = query.get("session_id");
//     const customerId = query.get("customerId");
//     const planType = query.get("planType");
//     const quantity = query.get("quantity");

//     if (sessionId && customerId && planType && quantity) {
//       // Retrieve the Bearer token from localStorage
//       const token = localStorage.getItem("token");

//       if (!token) {
//         alert("User is not authenticated. Please log in.");
//         return;
//       }

//       // Call backend to confirm subscription
//       fetch("http://localhost:5001/api/stripe/confirm-subscription", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`, // Include the Bearer token
//         },
//         body: JSON.stringify({
//           sessionId,
//           customerId,
//           planType,
//           quantity,
//         }),
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.success) {
//             alert("Subscription created successfully!");
//           } else {
//             alert("Failed to create subscription.");
//           }
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           alert("An error occurred.");
//         });
//     } else {
//       alert("Missing query parameters.");
//     }
//   }, [location]);

//   return <div>Success! Your subscription is being processed.</div>;
// };

// export default SuccessComponent;

// SuccessComponent.js
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const SuccessComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("session_id");
    const customerId = query.get("customerId");
    const planType = query.get("planType");
    const quantity = query.get("quantity");

    if (sessionId && customerId && planType && quantity) {
      // Retrieve the Bearer token from localStorage
      const token = localStorage.getItem("token");

      if (!token) {
        Swal.fire({
          icon: "error",
          title: "Authentication Error",
          text: "User is not authenticated. Please log in.",
        });
        return;
      }

      // Call backend to confirm subscription
      fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/stripe/confirm-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the Bearer token
          },
          body: JSON.stringify({
            sessionId,
            customerId,
            planType,
            family_relations: quantity,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            Swal.fire({
              icon: "success",
              title: "Subscription Successful",
              text: "Your subscription has been created successfully! Redirecting to the home page in 5 seconds...",
              timer: 5000, // Auto-close after 5 seconds
              showConfirmButton: false,
              willClose: () => {
                // Redirect to the home page after the alert closes
                navigate("/");
              },
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Subscription Failed",
              text: "Failed to create subscription. Please try again.",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          Swal.fire({
            icon: "error",
            title: "An Error Occurred",
            text: "Something went wrong. Please try again later.",
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Invalid Request",
        text: "Missing query parameters.",
      });
    }
  }, [location, navigate]);

  return <div>Success! Your subscription is being processed.</div>;
};

export default SuccessComponent;
