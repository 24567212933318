import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agoraPlayer: "", // Initial state
};

const agoraPlayerSlice = createSlice({
  name: "agoraPlayer", // Slice name
  initialState,
  reducers: {
    setAgoraPlayer: (state, action) => {
      state.agoraPlayer = action.payload; // Update the state
    },
  },
});

export const { setAgoraPlayer } = agoraPlayerSlice.actions;
export default agoraPlayerSlice.reducer;
