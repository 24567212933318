import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const conversationService = {
  createConvo: createConvo,
  getMessagesByConversation: getMessagesByConversation,
  createConvoDoctor: createConvoDoctor,
  getDoctorChatHistory: getDoctorChatHistory,
};

async function createConvo(patient, doctor, appointment) {
  try {
    const result = await axiosInstance.post(
      `/api/messaging/create-conversation-between-two?appointment=${appointment}`,
      {
        sender_id: patient,
        receiver_id: doctor,
        sender_role: "patient",
        receiver_role: "doctor",
      }
    );
    console.log(result);
    if (result.status == 200 && result.data.message == "conversation created") {
      return {
        success: true,
        convo: result.data.conversation,
        conversation_type: "new",
      };
    } else if (
      result.status == 200 &&
      result.data.message == "conversation is already present"
    ) {
      return {
        success: true,
        convo: result.data.conversation,
        conversation_type: "existing",
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, clinics: [] };
  }
}

async function createConvoDoctor(patient, doctor, appointment) {
  try {
    const result = await axiosInstance.post(
      `/api/messaging/create-conversation-between-two?appointment=${appointment}`,
      {
        sender_id: patient,
        receiver_id: doctor,
        sender_role: "doctor",
        receiver_role: "patient",
      }
    );
    console.log(result);
    if (result.status == 200 && result.data.message == "conversation created") {
      return {
        success: true,
        convo: result.data.conversation,
        conversation_type: "new",
      };
    } else if (
      result.status == 200 &&
      result.data.message == "conversation is already present"
    ) {
      return {
        success: true,
        convo: result.data.conversation,
        conversation_type: "existing",
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, clinics: [] };
  }
}

async function getMessagesByConversation(id) {
  try {
    const result = await axiosInstance.get(
      `/api/messaging/get-chat-by-conversation/${id}`
    );
    console.log(result);
    if (result.status == 200 && result.data.chat.messages.length) {
      return {
        success: true,
        messages: result.data.chat.messages,
        participants: result.data.chat.participants,
        convo_open_or_close: result.data.chat.isConversationOpen,
      };
    } else {
      return {
        success: false,
        messages: [],
        convo_open_or_close: result.data.chat.isConversationOpen,
        participants: result.data.chat.participants,
      };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, clinics: [] };
  }
}

async function getDoctorChatHistory(id) {
  try {
    const result = await axiosInstance.get(
      `/api/messaging/get-completed-chats-by-doctor/${id}`
    );
    console.log(result);

    if (result.status == 200 && result.data.message == "chats found") {
      return {
        success: true,
        chats: result.data.chats,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false };
  }
}
