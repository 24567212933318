import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { appointmentServices } from "../../../services/appointmentServices";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { toast } from "react-toastify";
import { getFormattedDate, showNotification } from "../../../helper/helper";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import { getSocket } from "../../../config/socketIoConfig";
import { Howl } from "howler";
import { AgoraPlayer } from "../../agoraCallPlayer/AgoraPlayer";
import { videoCallService } from "../../../services/videoCallServices";
import { useLocation } from "react-router-dom";
const Appointment = () => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.user);

  const location = useLocation;
  const [user, setuser] = useState({});
  const [appointments, setappointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [tabActive, settabActive] = useState("upcoming");
  const [cancelled, setcancelled] = useState([]);
  const [socket, setSocket] = useState(null);
  const [play_audio, setplay_audio] = useState(false);
  const [page, setpage] = useState(1);
  const [channel, setchannel] = useState("");
  const [agoraToken, setagoraToken] = useState("");
  const [callerName, setcallerName] = useState("");
  const [doctor_id, setdoctor_id] = useState("");
  const [doctorFirstname, setdoctorFirstname] = useState("");
  const [appointmentid, setappointmentid] = useState("");

  const sound1 = new Howl({
    src: [require("../../../assets/audio.mp3")],
    preload: true,
    volume: 1.0,
  });

  const [sound, setSound] = useState(sound1);

  // const [selectedOption, setSelectedOption] = useState("all");

  const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
  // const SOCKET_URL = "http://localhost:5001";

  useEffect(() => {
    if (reduxUser?._id) {
      // const socketIo = io(SOCKET_URL, {
      //   query: {
      //     id: reduxUser._id,
      //   },
      //   transports: ["websocket"],
      // });

      const socketIo = getSocket(reduxUser?._id);

      setSocket(socketIo);

      socketIo.on("connect", () => {
        console.log("Connected to Socket.IO server");
      });

      socketIo.on("disconnect", (reason) => {
        console.log("Disconnected from Socket.IO server:", reason);
      });

      // socketIo.on("call-incoming", (msg) => {
      //   sound.play();
      //   console.log("call incoming", msg);
      //   setpage(2);
      //   setdoctor_id(msg.sender_id);

      //   getAgoraToken(msg.receiver_id, msg.sender_id);
      // });

      socketIo.on("call-declined", (msg) => {
        console.log("call decline event ran");
        toast("Call declined by Doctor");
        setpage(1);
        sound.stop();
      });

      socketIo.on("call-accepted", (msg) => {
        setdoctor_id(msg?.sender_id);
        setpage(3);
        // getTokenAndConnectCall(reduxUser?._id, msg.sender_id);

        // toast(`Call ended by ${msg.senderName}`);
      });

      // socketIo.on("call-ended", (msg) => {
      //   setpage(1);
      //   // toast(`Call ended by ${msg.senderName}`);
      // });

      // return () => {
      //   socketIo.disconnect();
      // };
    }
  }, []);

  useEffect(() => {
    checkAppointments();
  }, []);

  async function checkAppointments() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const upcoming = await getUpcoming(user?._id, "all");
      const completed = await getComleted(user?._id, "all");
      const cancelled = await getCancelled(user?._id, "all");

      setuser(user);

      console.log("upcoming", upcoming);
      console.log("completed", completed);

      if (upcoming == false && completed == false) {
        toast("No upcoming or completed appointments.");
      } else if (upcoming == false && completed == true) {
        toast("No upcoming appointments.");
      } else if (upcoming == true && completed == false) {
        toast("No completed appointments.");
      }
    }
  }

  async function getUpcoming(patient, type) {
    dispatch(loader(true));

    const res = await appointmentServices.getUpcomingAppointments(
      patient,
      type
    );

    if (res?.success) {
      dispatch(loader(false));
      setappointments(res?.data);
    } else {
      dispatch(loader(false));
      // toast("no appointments made yet");
      setappointments([]);
      return false;
    }

    dispatch(loader(false));
  }

  async function getComleted(patient, type) {
    dispatch(loader(true));

    const res = await appointmentServices.getCompletedAppointments(
      patient,
      type
    );

    if (res?.success) {
      dispatch(loader(false));
      setCompletedAppointments(res?.data);
    } else {
      dispatch(loader(false));
      // toast("no appointments made yet");
      setCompletedAppointments([]);
      return false;
    }

    dispatch(loader(false));
  }

  async function getCancelled(patient, type) {
    dispatch(loader(true));

    const res = await appointmentServices.getCancelledAppintments(
      patient,
      type
    );

    if (res?.success) {
      dispatch(loader(false));
      setcancelled(res?.data);
    } else {
      dispatch(loader(false));
      // toast("no appointments made yet");
      setcancelled([]);
      return false;
    }

    dispatch(loader(false));
  }

  async function cancelAppointment(appointment) {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to cancel this appointment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      dispatch(loader(true));
      try {
        const res = await appointmentServices.cancelAppointment(
          appointment?._id
        );
        if (res?.success) {
          showNotification(
            "success",
            "Appointment Cancelled",
            "Appointment was cancelled successfully."
          );
          // Assuming getComleted and getUpcoming are defined elsewhere to refresh the appointment lists
          getComleted(user?._id, "all");
          getUpcoming(user?._id, "all");
          getCancelled(user?._id, "all");
        } else {
          showNotification(
            "error",
            "Appointment Not Cancelled",
            "Appointment was not cancelled."
          );
        }
      } catch (error) {
        showNotification(
          "error",
          "Error",
          "An error occurred while cancelling the appointment."
        );
      } finally {
        dispatch(loader(false));
      }
    }
  }

  async function getAgoraToken(
    patientId,
    doctorId,
    deviceType,
    doctorname,
    appointment
  ) {
    console.log("APPOINTMENT ID =", appointment);
    setappointmentid(appointment);
    const res = await videoCallService.getChannel(patientId, doctorId);
    if (res.success) {
      setchannel(res.channel);
      setdoctor_id(doctorId);
      const res2 = await videoCallService.getToken(res.channel);
      if (res2.success) {
        setagoraToken(res2?.token);
        setdoctorFirstname(doctorname);
        if (deviceType == "website") {
          console.log("device type website ran");
          socket.emit("start-call", {
            receiver_id: doctorId,
            sender_id: patientId,
            senderName: reduxUser?.firstname,
          });
          setpage(4);
        }
        if (deviceType == "ios") {
          console.log("device type ios");
          const data = {
            receiver_id: doctorId,
            sender_id: patientId,

            receiver_role: "doctor",
            sender_name: reduxUser?.firstname,
            title: "Video Meet",
            content: "Doctor Patient Video Meet",
          };

          const res = await videoCallService.sendCallNotification(data);
          if (res.success) {
            //enable call ring screen
          } else {
            showNotification(
              "error",
              "Call Not Connected",
              "Something went wrong not able to connect call."
            );
          }
          console.log("device type website ios");
          setpage(2);
        }
        if (deviceType == "android") {
          console.log("device type android");
          const data = {
            receiver_id: doctorId,
            sender_id: patientId,

            receiver_role: "doctor",
            sender_name: reduxUser?.firstname,
            title: "Video Meet",
            content: "Doctor Patient Video Meet",
          };

          const res = await videoCallService.sendCallNotification(data);
          if (res.success) {
            //enable call ring screen
          } else {
            showNotification(
              "error",
              "Call Not Connected",
              "Something went wrong not able to connect call."
            );
          }
          console.log("device type website android");
          setpage(2);
        }
      } else {
        console.log("unable to obtain agora token");
      }
    } else {
      console.log("unable to obtain channel id");
    }
  }

  // async function getTokenAndConnectCall(patientId, doctorId) {
  //   const res = await videoCallService.getChannel(patientId, doctorId);
  //   if (res.success) {
  //     setchannel(res.channel);
  //     const res2 = await videoCallService.getToken(res.channel);
  //     if (res2.success) {
  //       setagoraToken(res2?.token);

  //       setpage(3);
  //     } else {
  //       console.log("unable to obtain agora token");
  //     }
  //   } else {
  //     console.log("unable to obtain channel id");
  //   }
  // }

  return (
    <>
      <div className="py-5 px-3">
        {page == 1 ? (
          <>
            <Helmet>
              {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
            </Helmet>
            <div className="Small-Wrapper">
              <div className="container">
                <div className="TitleBox">
                  <h4 className="Title">My Appointment</h4>
                </div>
                <div className="CommonTabs">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#Upcoming"
                        onClick={() => settabActive("upcoming")}
                      >
                        Upcoming
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Completed"
                        onClick={() => settabActive("completed")}
                      >
                        Completed
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Cancelled"
                        onClick={() => settabActive("cancelled")}
                      >
                        Cancelled
                      </a>
                    </li>
                  </ul>
                  <select
                    className="form-control"
                    onChange={(event) => {
                      // setSelectedOption(event.target.value);
                      getUpcoming(user?._id, event?.target?.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="clinic">Clinic Visit</option>
                    <option value="virtual">Virtual</option>
                  </select>
                </div>
                <div className="tab-content">
                  <div
                    className={`tab-pane fade show ${
                      tabActive == "upcoming" ? "active" : null
                    }`}
                    id="Upcoming"
                  >
                    <div className="row">
                      {appointments?.length
                        ? appointments?.map((item, index) => (
                            <div className="col-sm-4 text-capitalize">
                              <div className="AppoinmentBox">
                                <div>
                                  <div className="AppoinmentHead">
                                    <aside>
                                      <h3>
                                        {/* {item?.appointment_type == "virtual"
                                    ? `${item?.doctor_id?.firstname}`
                                    : `${item?.clinic_id?.clinic_name} 
                                `} */}
                                        {item?.appointment_type == "virtual"
                                          ? `${item?.doctor_id?.firstname}`
                                          : `${item?.doctor_id?.firstname} 
                                `}
                                      </h3>
                                    </aside>
                                  </div>
                                  <div className="AppoinmentBody">
                                    <ul>
                                      <li>
                                        {item?.appointment_type == "clinic" ? (
                                          <>
                                            <span>
                                              <img src="../images/Clinic.png" />
                                            </span>

                                            <span>Clinic Visit</span>
                                          </>
                                        ) : (
                                          <>
                                            <span>
                                              <img src="../images/Video.png" />
                                            </span>
                                            <span className="">Virtual</span>
                                          </>
                                        )}
                                      </li>
                                      <li className="mx-3">
                                        {item.reason_to_visit != ""
                                          ? item.reason_to_visit
                                          : "Reason -"}
                                      </li>
                                      <button
                                        className="mx-3"
                                        onClick={() =>
                                          getAgoraToken(
                                            reduxUser?._id,
                                            item?.doctor_id?._id,
                                            item?.doctor_id?.device_type,
                                            item?.doctor_id?.firstname
                                          )
                                        }
                                      >
                                        Start Call
                                      </button>
                                    </ul>
                                  </div>

                                  <div className="AppoinmentFooter">
                                    <p>
                                      <span>
                                        <img src="../images/Clock.png" />
                                      </span>
                                      {getFormattedDate(
                                        item?.booking_date,
                                        "MMMM Do YYYY, h:mm:ss A"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  className="Red"
                                  data-toggle="modal"
                                  data-target="#CancelAppointment"
                                  onClick={() => cancelAppointment(item)}
                                >
                                  <span>
                                    <img src="../images/cancel.png" />
                                  </span>{" "}
                                  Cancel Appointment
                                </button>

                                <div>
                                  {item.appointment_type == "clinic" ? (
                                    <button className="Blue">
                                      <span>
                                        <img src="../images/directions.png" />
                                      </span>{" "}
                                      Directions
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade show ${
                      tabActive == "completed" ? "active" : null
                    }`}
                    id="Completed"
                  >
                    <div className="row">
                      {completedAppointments?.length
                        ? completedAppointments?.map((item, index) => (
                            <div className="col-sm-4 text-capitalize">
                              <div className="AppoinmentBox">
                                <div>
                                  <div className="AppoinmentHead">
                                    <aside>
                                      <h3>
                                        {/* {item?.appointment_type == "virtual"
                                    ? `${item?.doctor_id?.firstname} 
                                ${item?.doctor_id?.lastname}`
                                    : `${item?.clinic_id?.clinic_name} 
                                `} */}
                                        {item?.appointment_type == "virtual"
                                          ? `${item?.doctor_id?.firstname}`
                                          : `${item?.doctor_id?.firstname} 
                                `}
                                      </h3>
                                    </aside>
                                    <Link
                                      to={`/patient/messaging/${item.patient_id._id}/${item.doctor_id._id}/${item._id}`}
                                      state={{
                                        participant: item.doctor_id,
                                      }}
                                    >
                                      <button className="Blue" id="StartChat">
                                        <span>
                                          <img src="../images/Chat.png" />
                                        </span>{" "}
                                        Chat
                                      </button>
                                    </Link>
                                  </div>
                                  <div className="AppoinmentBody">
                                    <ul>
                                      <li>
                                        {item?.appointment_type == "clinic" ? (
                                          <>
                                            <span>
                                              <img src="../images/Clinic.png" />
                                            </span>

                                            <span>Clinic Visit</span>
                                          </>
                                        ) : (
                                          <>
                                            <span>
                                              <img src="../images/Video.png" />
                                            </span>
                                            <span className="">Virtual</span>
                                          </>
                                        )}
                                      </li>
                                      <li className="mx-3">
                                        {item.reason_to_visit != ""
                                          ? item.reason_to_visit
                                          : "Reason -"}
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="AppoinmentFooter">
                                    <p>
                                      <span>
                                        <img src="../images/Clock.png" />
                                      </span>
                                      {getFormattedDate(
                                        item?.booking_date,
                                        "MMMM Do YYYY, h:mm:ss A"
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  {item?.appointment_type == "clinic" ? (
                                    <button className="Blue">
                                      <span>
                                        <img src="../images/directions.png" />
                                      </span>{" "}
                                      Directions
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade show ${
                      tabActive == "cancelled" ? "active" : null
                    }`}
                    id="Completed"
                  >
                    <div className="row">
                      {cancelled?.length
                        ? cancelled?.map((item, index) => (
                            <div className="col-sm-4">
                              <div className="AppoinmentBox text-capitalize">
                                <div>
                                  <div className="AppoinmentHead">
                                    <aside>
                                      <h3>
                                        {/* {item?.appointment_type == "virtual"
                                    ? `${item?.doctor_id?.firstname} 
                                ${item?.doctor_id?.lastname}`
                                    : `${item?.clinic_id?.clinic_name} 
                                `} */}
                                        {item?.appointment_type == "virtual"
                                          ? `${item?.doctor_id?.firstname}`
                                          : `${item?.doctor_id?.firstname} 
                                `}
                                      </h3>
                                    </aside>
                                  </div>
                                  <div className="AppoinmentBody">
                                    <ul>
                                      <li>
                                        {item?.appointment_type == "clinic" ? (
                                          <>
                                            <span>
                                              <img src="../images/Clinic.png" />
                                            </span>

                                            <span>Clinic Visit</span>
                                          </>
                                        ) : (
                                          <>
                                            <span>
                                              <img src="../images/Video.png" />
                                            </span>
                                            <span className="">Virtual</span>
                                          </>
                                        )}
                                      </li>
                                      <li className="mx-3">
                                        Reason -
                                        <span className="resonToVisit">
                                          &nbsp;{item?.reason_to_visit}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="AppoinmentFooter">
                                    <p>
                                      <span>
                                        <img src="../images/Clock.png" />
                                      </span>
                                      {getFormattedDate(
                                        item?.booking_date,
                                        "MMMM Do YYYY, h:mm:ss A"
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  {item?.appointment_type == "clinic" ? (
                                    <button className="Blue">
                                      <span>
                                        <img src="../images/directions.png" />
                                      </span>{" "}
                                      Directions
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {page == 2 ? (
          <>
            <div className="join-call-screen">
              <div className="caller-info text-center">
                <span className="caller-name text-capitalize">
                  {callerName}
                </span>
                <br />

                <span className="text-white text-center">Incoming Call...</span>
              </div>
              <div className="call-buttons">
                <button
                  className="decline-button"
                  onClick={() => {
                    setpage(1);
                    sound.stop();
                    socket.emit("decline-call", {
                      receiver_id: doctor_id,
                      sender_id: reduxUser?._id,
                      senderName: reduxUser?.firstname,
                    });
                  }}
                >
                  Decline
                </button>
                <button
                  className="accept-button"
                  onClick={() => {
                    setpage(3);
                    sound.stop();
                    socket.emit("accept-call", {
                      receiver_id: doctor_id,
                      sender_id: reduxUser?._id,
                      senderName: reduxUser?.firstname,
                    });
                  }}
                >
                  Accept
                </button>
              </div>
            </div>

            <div className="Small-Wrapper">
              <div className="container">
                <div className="TitleBox">
                  <h4 className="Title">My Appointment</h4>
                </div>
                <div className="CommonTabs">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#Upcoming"
                        onClick={() => settabActive("upcoming")}
                      >
                        Upcoming
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Completed"
                        onClick={() => settabActive("completed")}
                      >
                        Completed
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Cancelled"
                        onClick={() => settabActive("cancelled")}
                      >
                        Cancelled
                      </a>
                    </li>
                  </ul>
                  <select
                    className="form-control"
                    onChange={(event) => {
                      // setSelectedOption(event.target.value);
                      getUpcoming(user?._id, event?.target?.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="clinic">Clinic Visit</option>
                    <option value="virtual">Virtual</option>
                  </select>
                </div>
                <div className="tab-content">
                  <div
                    className={`tab-pane fade show ${
                      tabActive == "upcoming" ? "active" : null
                    }`}
                    id="Upcoming"
                  >
                    <div className="row">
                      {appointments?.length
                        ? appointments?.map((item, index) => (
                            <div className="col-sm-4 text-capitalize">
                              <>{console.log("item", item)}</>
                              <div className="AppoinmentBox">
                                <div>
                                  <div className="AppoinmentHead">
                                    <aside>
                                      <h3>
                                        {/* {item?.appointment_type == "virtual"
                                    ? `${item?.doctor_id?.firstname}`
                                    : `${item?.clinic_id?.clinic_name} 
                                `} */}
                                        {item?.appointment_type == "virtual"
                                          ? `${item?.doctor_id?.firstname}`
                                          : `${item?.doctor_id?.firstname} 
                                `}
                                      </h3>
                                    </aside>
                                  </div>
                                  <div className="AppoinmentBody">
                                    <ul>
                                      <li>
                                        {item?.appointment_type == "clinic" ? (
                                          <>
                                            <span>
                                              <img src="../images/Clinic.png" />
                                            </span>

                                            <span>Clinic Visit</span>
                                          </>
                                        ) : (
                                          <>
                                            <span>
                                              <img src="../images/Video.png" />
                                            </span>
                                            <span className="">Virtual</span>
                                          </>
                                        )}
                                      </li>
                                      <li className="mx-3">
                                        {item.reason_to_visit != ""
                                          ? item.reason_to_visit
                                          : "Reason -"}
                                      </li>
                                      <button
                                        className="mx-3"
                                        onClick={() =>
                                          getAgoraToken(
                                            reduxUser?._id,
                                            item?.doctor_id?._id,
                                            item?.patient_id?.device_type,
                                            item?._id
                                          )
                                        }
                                      >
                                        Start Call
                                      </button>
                                    </ul>
                                  </div>

                                  <div className="AppoinmentFooter">
                                    <p>
                                      <span>
                                        <img src="../images/Clock.png" />
                                      </span>
                                      {getFormattedDate(
                                        item?.booking_date,
                                        "MMMM Do YYYY, h:mm:ss A"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  className="Red"
                                  data-toggle="modal"
                                  data-target="#CancelAppointment"
                                  onClick={() => cancelAppointment(item)}
                                >
                                  <span>
                                    <img src="../images/cancel.png" />
                                  </span>{" "}
                                  Cancel Appointment
                                </button>

                                <div>
                                  {item.appointment_type == "clinic" ? (
                                    <button className="Blue">
                                      <span>
                                        <img src="../images/directions.png" />
                                      </span>{" "}
                                      Directions
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade show ${
                      tabActive == "completed" ? "active" : null
                    }`}
                    id="Completed"
                  >
                    <div className="row">
                      {completedAppointments?.length
                        ? completedAppointments?.map((item, index) => (
                            <div className="col-sm-4 text-capitalize">
                              <div className="AppoinmentBox">
                                <div>
                                  <div className="AppoinmentHead">
                                    <aside>
                                      <h3>
                                        {/* {item?.appointment_type == "virtual"
                                    ? `${item?.doctor_id?.firstname} 
                                ${item?.doctor_id?.lastname}`
                                    : `${item?.clinic_id?.clinic_name} 
                                `} */}
                                        {item?.appointment_type == "virtual"
                                          ? `${item?.doctor_id?.firstname}`
                                          : `${item?.doctor_id?.firstname} 
                                `}
                                      </h3>
                                    </aside>
                                    <Link
                                      to={`/patient/messaging/${item.patient_id._id}/${item.doctor_id._id}/${item._id}`}
                                    >
                                      <button className="Blue" id="StartChat">
                                        <span>
                                          <img src="../images/Chat.png" />
                                        </span>{" "}
                                        Chat
                                      </button>
                                    </Link>
                                  </div>
                                  <div className="AppoinmentBody">
                                    <ul>
                                      <li>
                                        {item?.appointment_type == "clinic" ? (
                                          <>
                                            <span>
                                              <img src="../images/Clinic.png" />
                                            </span>

                                            <span>Clinic Visit</span>
                                          </>
                                        ) : (
                                          <>
                                            <span>
                                              <img src="../images/Video.png" />
                                            </span>
                                            <span className="">Virtual</span>
                                          </>
                                        )}
                                      </li>
                                      <li className="mx-3">
                                        {item.reason_to_visit != ""
                                          ? item.reason_to_visit
                                          : "Reason -"}
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="AppoinmentFooter">
                                    <p>
                                      <span>
                                        <img src="../images/Clock.png" />
                                      </span>
                                      {getFormattedDate(
                                        item?.booking_date,
                                        "MMMM Do YYYY, h:mm:ss A"
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  {item?.appointment_type == "clinic" ? (
                                    <button className="Blue">
                                      <span>
                                        <img src="../images/directions.png" />
                                      </span>{" "}
                                      Directions
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade show ${
                      tabActive == "cancelled" ? "active" : null
                    }`}
                    id="Completed"
                  >
                    <div className="row">
                      {cancelled?.length
                        ? cancelled?.map((item, index) => (
                            <div className="col-sm-4">
                              <div className="AppoinmentBox text-capitalize">
                                <div>
                                  <div className="AppoinmentHead">
                                    <aside>
                                      <h3>
                                        {/* {item?.appointment_type == "virtual"
                                    ? `${item?.doctor_id?.firstname} 
                                ${item?.doctor_id?.lastname}`
                                    : `${item?.clinic_id?.clinic_name} 
                                `} */}
                                        {item?.appointment_type == "virtual"
                                          ? `${item?.doctor_id?.firstname}`
                                          : `${item?.doctor_id?.firstname} 
                                `}
                                      </h3>
                                    </aside>
                                  </div>
                                  <div className="AppoinmentBody">
                                    <ul>
                                      <li>
                                        {item?.appointment_type == "clinic" ? (
                                          <>
                                            <span>
                                              <img src="../images/Clinic.png" />
                                            </span>

                                            <span>Clinic Visit</span>
                                          </>
                                        ) : (
                                          <>
                                            <span>
                                              <img src="../images/Video.png" />
                                            </span>
                                            <span className="">Virtual</span>
                                          </>
                                        )}
                                      </li>
                                      <li className="mx-3">
                                        Reason -
                                        <span className="resonToVisit">
                                          &nbsp;{item?.reason_to_visit}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="AppoinmentFooter">
                                    <p>
                                      <span>
                                        <img src="../images/Clock.png" />
                                      </span>
                                      {getFormattedDate(
                                        item?.booking_date,
                                        "MMMM Do YYYY, h:mm:ss A"
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  {item?.appointment_type == "clinic" ? (
                                    <button className="Blue">
                                      <span>
                                        <img src="../images/directions.png" />
                                      </span>{" "}
                                      Directions
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {page == 3 ? (
          <AgoraPlayer
            channel={channel}
            agoraToken={agoraToken}
            setpage={setpage}
            socket={socket}
            doctor_id={doctor_id}
            patient_id={reduxUser?._id}
            patient_name={reduxUser?.firstname}
            appointment_id={appointmentid}
          />
        ) : null}

        {page == 4 ? (
          <div className="join-call-screen">
            <div className="caller-info text-center">
              <span className="caller-name text-capitalize">
                {doctorFirstname}
              </span>
              <br />

              <span className="text-white text-center">Ringing...</span>
            </div>
            <div className="call-buttons">
              <button
                className="decline-button"
                onClick={() => {
                  sound.stop();
                  setpage(1);
                  socket.emit("end-call", {
                    receiver_id: doctor_id,
                    sender_id: reduxUser._id,
                    senderName: reduxUser.firstname,
                  });
                }}
              >
                End Call
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Appointment;
