import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
  name: "booking",
  initialState: false,
  reducers: {
    setbooking: (state, action) => action.payload,
    clearbooking: () => false,
  },
});

export const { setbooking, clearbooking } = bookingSlice.actions;
export default bookingSlice.reducer;
