import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { insuranceService } from "../../../services/insuranceService";
import { formatDate, getFormattedDate } from "../../../helper/helper";
import EditInsurancePopup from "../../Popups/EditInsurancePopup";
import AddInsurance from "../../Popups/AddInsurance";

const Insurance = () => {
  const dispatch = useDispatch();
  const [insurance, setinsurance] = useState([]);
  const [insurance_id, setinsurance_id] = useState("");
  const [user, setuser] = useState({});
  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);

  //states for update modal

  //

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setuser(user);
      getInsurance(user?._id);
    }
  }, []);

  async function getInsurance(patient_id) {
    dispatch(loader(true));
    const res = await insuranceService.getInsurance(patient_id);
    setinsurance(res);
    console.log("insu", res);
    setinsurance_id(res[0]?._id);
    dispatch(loader(false));
  }

  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <EditInsurancePopup
        show={show}
        setshow={setshow}
        insurance_id={insurance_id}
        getInsurance={getInsurance}
        User={user}
        insurance={insurance[0]}
      />

      <AddInsurance
        show={show2}
        setshow={setshow2}
        insurance_id={insurance_id}
        getInsurance={getInsurance}
        User={user}
      />

      {insurance.length ? (
        <>
          <div className="Small-Wrapper">
            <div className="container">
              <div className="TitleBox">
                <h4 className="Title">Insurance Information</h4>
              </div>
              <div className="InsuranceArea">
                {insurance[0]?.insurance_approved == "verified" ? (
                  <>
                    <article className="Green">
                      <span>
                        <img src="../images/set-up.png" />
                      </span>
                      You're all set up
                    </article>
                  </>
                ) : (
                  <>
                    <article className="Orange">
                      We are unable to verify your insurance
                    </article>
                  </>
                )}
                {insurance[0]?.insurance_approved == "verified" ? (
                  <>
                    <p>
                      We’ll bill you directly for medical services. Please check
                      the insurance details below
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Please bring your insurance information with you when you
                      visit our center. Our staff will verify your insurance
                      card and handle everything for you.
                    </p>
                  </>
                )}
                {insurance?.length
                  ? insurance?.map((item, index) => (
                      <div className="InsuranceBox">
                        <div className="CenterBox">
                          <aside>
                            <p className="text-capitalize">
                              Insurance Name : <span>{item?.carrier_name}</span>
                            </p>
                          </aside>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <p>Name</p>
                                  <h5 className="text-capitalize">
                                    {item?.patient_id?.firstname}
                                  </h5>
                                </td>
                                <td>
                                  <p>Date of Birth</p>
                                  <h5>
                                    {getFormattedDate(
                                      item?.patient_id?.dob,
                                      "DD-MM-YYYY"
                                    )}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Gender</p>
                                  <h5 className="text-capitalize">
                                    {item?.patient_id?.gender}
                                  </h5>
                                </td>
                                <td>
                                  <p>ID</p>
                                  <h5>{item?.member_id}</h5>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Group ID</p>
                                  <h5>{item?.group_id}</h5>
                                </td>
                                <td>
                                  <p>IPA</p>
                                  <h5>{item?.ipa}</h5>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Plan Type</p>
                                  <h5>N/A</h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {item?.insurance_approved == "verified" ? (
                          <>
                            <a
                              href="insurance-information-notverified.html"
                              className="VerifyBtn Green"
                            >
                              <span>
                                <img src="../images/verified.png" />
                              </span>
                              Verified
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href="javascript:void(0);"
                              className="VerifyBtn Orange"
                            >
                              <span>
                                <img src="../images/not-verified.png" />
                              </span>
                              Not Yet Verified
                            </a>
                            <p
                              className="AddNewMemberBtn w-100 text-center mt-4"
                              onClick={() => setshow(true)}
                            >
                              Edit
                            </p>
                          </>
                        )}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <button className="mx-4 my-5" onClick={() => setshow2(true)}>
          Add Insurance
        </button>
      )}
    </>
  );
};

export default Insurance;
