import React from "react";
import ReactPlayer from "react-player";

const AudioPlayer = ({ url }) => {
  return (
    <div className="audio-player">
      <ReactPlayer
        url={url}
        playing={false} // Set to true to auto-play on load
        controls={true} // Display default controls
        width="100%" // Full width
        height="50px" // Height for audio player
        config={{
          file: {
            attributes: {
              controlsList: "", // Disable download button
            },
          },
        }}
      />
    </div>
  );
};

export default AudioPlayer;
