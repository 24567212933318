import React, { useEffect, useState } from "react";

import { staticContentService } from "../../../services/staticContentService";

import { useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const [privacyPolicy, setPrivacyPolicy] = useState([]);

  useEffect(() => {
    getContents();
  }, []);

  async function getContents() {
    dispatch(loader(true));
    const result = await staticContentService.getPrivacyPolicy();

    if (result?.success) {
      console.log("Privacy Data", result.data);
      setPrivacyPolicy(result.data);
      dispatch(loader(false));
    }
  }
  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Privacy Policy</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>
              <a href="settings.html">
                <i className="fa fa-angle-left" aria-hidden="true" />
              </a>{" "}
              Privacy Policy
            </h4>
          </div>
          <div className="StaticBox">
            {privacyPolicy.map((item, index) => (
              <p
                dangerouslySetInnerHTML={{
                  __html: item.privacy_policy_content,
                }}
              ></p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
