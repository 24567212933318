import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { loader } from "../../../store/LoaderSlice";
import { doctorService } from "../../../services/doctorService";
const Notifications = () => {
  const dispatch = useDispatch();
  const doctorId = useSelector((state) => state.doctor._id);
  const [notifications, setnotifications] = useState([]);
  useEffect(() => {
    getNotification();
  }, []);

  async function getNotification() {
    dispatch(loader(true));

    const res = await doctorService.getNotifications(doctorId);

    if (res.success) {
      console.log("NOTIFICATIONS", res.data);

      setnotifications(res.data);
    }

    dispatch(loader(false));
  }
  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Notifications</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>Notifications</h4>
          </div>

          <div className="NotificationsList">
            <ul>
              {notifications.length ? (
                notifications.map((item, index) => (
                  <li className="text-capitalize">
                    <span className="Icon">
                      <img src="../images/Notifications-1.png" />{" "}
                    </span>
                    <span className="Time">{item.notification_time}</span>
                    <h5>{item.title ? item.title : ""}</h5>
                    <p>{item.message ? item.message : ""}</p>
                  </li>
                ))
              ) : (
                <li>No notifications for you.</li>
              )}

              {/* <li>
                <span className="Icon">
                  <img src="../images/Notifications-2.png" />{" "}
                </span>
                <span className="Time">1 min ago</span>
                <h5>Upcoming Appointment</h5>
                <p>You have a patient to attend in half an hour</p>
              </li>
              <li>
                <span className="Icon">
                  <img src="../images/Notifications-3.png" />{" "}
                </span>
                <span className="Time">1 min ago</span>
                <h5>New Message</h5>
                <p>You have a unread message from Mike Brown</p>
              </li>
              <li>
                <span className="Icon">
                  <img src="../images/Notifications-4.png" />{" "}
                </span>
                <span className="Time">1 min ago</span>
                <h5>We are verifying</h5>
                <p>
                  Our professionals are taking a look at your KYC submission. We
                  will get back to you in 24 hours.{" "}
                </p>
              </li>
              <li>
                <span className="Icon">
                  <img src="../images/Notifications-5.png" />{" "}
                </span>
                <span className="Time">1 min ago</span>
                <h5>Review your weekly earnings</h5>
                <p>
                  Our professionals are taking a look at your KYC submission. We
                  will get back to you in 24 hours.{" "}
                </p>
              </li>
              <li>
                <span className="Icon">
                  <img src="../images/Notifications-6.png" />{" "}
                </span>
                <span className="Time">1 min ago</span>
                <h5>Review your weekly earnings</h5>
                <p>
                  Our professionals are taking a look at your KYC submission. We
                  will get back to you in 24 hours.{" "}
                </p>
              </li>
              <li>
                <span className="Icon">
                  <img src="../images/Notifications-7.png" />{" "}
                </span>
                <span className="Time">1 min ago</span>
                <h5>Review your weekly earnings</h5>
                <p>
                  Our professionals are taking a look at your KYC submission. We
                  will get back to you in 24 hours.{" "}
                </p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
