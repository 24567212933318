import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { membershipService } from "../../../services/membershipService";
import { patientService } from "../../../services/patientServices";
import { toast } from "react-toastify";
import { extractTime, getFormattedDate } from "../../../helper/helper";
import CheckoutComponent from "../../stripeCheckout/stripeCheckout";
import { useSelector, useDispatch } from "react-redux";
import { setUser, updateUser } from "../../../store/UserSlice";
import StripeCheckout from "../../stripePaymentIntent/stripeIntent";
import { loader } from "../../../store/LoaderSlice";
import { Card, Button, Row, Col, Modal, Form } from "react-bootstrap";
import {
  calculateAge,
  calculateLastDate,
  generateTransactionId,
  isStoredDateAfterCurrentDate,
  showNotification,
} from "../../../helper/helper";
import female from "../../../assets/imges/Avatar.png";
import male from "../../../assets/imges/Avatar-1.png";
import moment from "moment";

const Membership = () => {
  const [membership, setmemberships] = useState([]);
  const [user, setuser] = useState({});
  const [isloggedin, setIsloggedIn] = useState(false);
  const [selectedMembership, setselectedMembership] = useState(0);
  const [purchasedPlan, setpurchasedPlan] = useState([]);
  const customerId = useSelector((state) => state?.user?.customer_id);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [plans, setplans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    individual: true,
    spouse: false,
    children: false,
  });
  const [popup, setpopup] = useState(1);
  const [activeMembership, setactiveMembership] = useState([]);

  const pricingDetails = {
    "Essential Care Plan": {
      single: 59,
      spouse: 30,
      child: 10,
    },
    "Comprehensive Care Plan": {
      single: 79,
      spouse: 50,
      child: 20,
    },
    "Premium Plus Care Plan": {
      single: 199,
      spouse: 90,
      child: 50,
    },
  };

  useEffect(() => {
    if (userData) {
      getCusId();
      getActiveMembership();
    }
    getPlans();
  }, []);

  async function getCusId() {
    const res = await patientService.getCustomerId(
      userData.email,
      userData.phone,
      userData.name
    );
    if (res?.success) {
      dispatch(updateUser({ customer_id: res.data }));
    }
  }

  async function getPlans() {
    dispatch(loader(true));
    const res = await membershipService.getPlans();
    if (res?.success) {
      setplans(res?.membership);
    }
    dispatch(loader(false));
  }

  async function getActiveMembership() {
    // dispatch(loader(true));
    const res = await membershipService.getActiveMem(userData.customer_id);
    if (res?.success) {
      setactiveMembership(res?.membership);
      console.log("ACTIVE", res?.membership);
    }
    // dispatch(loader(false));
  }

  const handleCardClick = (plan) => {
    setSelectedPlanId(plan.planId);
    setSelectedProductId(plan.productId);
    setSelectedPlanData(plan);
    setShowModal(true); // Open modal
  };

  // Close modal and reset selected options
  const handleCloseModal = () => {
    setShowModal(false);
    setpopup(1);
    setSelectedOptions({
      individual: true,
      spoisloggedIncustomer_iduse: false,
      children: false,
    }); // Reset selected options
  };

  async function cancelSubscription(subsId) {
    console.log(subsId);
    dispatch(loader(true));

    const data = {
      patient_id: userData._id,
      subscriptionId: subsId,
      cancelAtPeriodEnd: false,
    };

    const res = await membershipService.cancelMembership(data);
    if (res?.success) {
      showNotification(
        "Success",
        "Plan Cancelled",
        "Plan Cancelled Successfully"
      );
      getActiveMembership();
    }
    dispatch(loader(false));
  }

  async function upgradeSubscription(plan) {
    dispatch(loader(true));

    const data = {
      patient_id: userData._id,
      subscriptionId: activeMembership[0].id,
      cancelAtPeriodEnd: false,
    };

    const res = await membershipService.cancelMembership(data);
    if (res?.success) {
      handleCardClick(plan);
    }
    dispatch(loader(false));
  }

  return (
    <>
      <Row className="g-4 px-4 my-5 ">
        {plans.map((plan, index) => {
          const pricing = pricingDetails[plan.planName];
          const imageUrl = plan.planImage
            ? plan.planImage
            : "https://via.placeholder.com/300x200?text=Plan+Image";

          return (
            <Col key={plan.planId} md={4}>
              <Card className={`${index === 1 ? "CardCenterCss" : ""} h-100`}>
                <Card.Img
                  variant="top"
                  src={imageUrl}
                  alt={plan.planName}
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title>{plan.planName}</Card.Title>
                  {pricing ? (
                    <>
                      <Card.Text>
                        <strong>Price:</strong> ${pricing.single}/month for a
                        single person
                      </Card.Text>
                      <Card.Text>
                        <ul>
                          <li>
                            {" "}
                            <i
                              className="fa fa-plus mr-2"
                              aria-hidden="true"
                            ></i>
                            ${pricing.spouse}/month for a spouse
                          </li>
                          <li>
                            <i
                              className="fa fa-plus mr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            ${pricing.child}/month per additional child
                          </li>
                        </ul>
                      </Card.Text>
                    </>
                  ) : (
                    <Card.Text>Pricing details unavailable</Card.Text>
                  )}
                </Card.Body>
                <Card.Footer>
                  {userData ? (
                    activeMembership[0]?.productId ? null : (
                      <Button
                        variant="primary"
                        onClick={() => handleCardClick(plan)}
                      >
                        Subscribe
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => toast("Login to purchase")}
                    >
                      Subscribe
                    </Button>
                  )}
                  {activeMembership[0]?.productId === plan?.productId ? (
                    <button className="btn btn-secondary mx-4 " disabled>
                      Subscribed
                    </button>
                  ) : activeMembership[0]?.productId ? (
                    // <button
                    //   className="btn btn-warning mx-4 "
                    //   // onClick={() => upgradeSubscription(plan)}
                    //   {() =>
                    //     Swal.fire({
                    //       title: 'Are you sure?',
                    //       text: `Your existing membership will be cancelled and upgraded to the selected plan. Do you want to proceed?`,
                    //       icon: 'warning',
                    //       showCancelButton: true,
                    //       confirmButtonColor: '#3085d6',
                    //       cancelButtonColor: '#d33',
                    //       confirmButtonText: 'Yes, upgrade!',
                    //       cancelButtonText: 'Cancel',
                    //     }).then((result) => {
                    //       if (result.isConfirmed) {
                    //         upgradeSubscription(plan)
                    //       } else {
                    //         console.log('Upgrade cancelled');
                    //       }
                    //     })
                    //   }
                    // >
                    //   Upgrade
                    // </button>
                    <button
                      className="btn btn-warning mx-4"
                      onClick={() =>
                        Swal.fire({
                          title: "Are you sure?",
                          text: `Your existing membership will be cancelled and upgraded to the selected plan. Do you want to proceed?`,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, upgrade!",
                          cancelButtonText: "Cancel",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            upgradeSubscription(plan); // Call the function with the selected plan
                          } else {
                            console.log("Upgrade cancelled");
                          }
                        })
                      }
                    >
                      Upgrade
                    </button>
                  ) : null}
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>

      <PlanModal
        show={showModal}
        onHide={handleCloseModal}
        planId={selectedPlanId}
        productId={selectedProductId}
        planData={selectedPlanData}
        popup={popup}
        setpopup={setpopup}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions} // Pass the setter function to the modal
      />

      {userData ? (
        <>
          {activeMembership.length
            ? activeMembership.map((item, index) => (
                <div className="Small-Wrapper">
                  <div className="container">
                    <div className="TitleBox">
                      <h4 className="Title">Membership</h4>
                    </div>
                    <div className="RenewArea">
                      <span className="Green TopSpan">
                        {" "}
                        <img src="../images/set-up.png" /> Your membership is
                        Acive
                      </span>
                      <div className="PayMonthlyBox">
                        <aside>
                          <figcaption>
                            <h4>Pay Monthly</h4>
                            <p>$15 per month</p>
                          </figcaption>
                          <button className="Expired">Active</button>
                        </aside>
                        <div className="PayMonthlyTable">
                          <span className="Icon">
                            <img src="../images/individual.png" />
                          </span>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <h6>{item?.productName}</h6>
                                </td>
                                <td>
                                  <p>Expired On</p>
                                  <h6>
                                    {getFormattedDate(
                                      item.nextPaymentDate,
                                      "YYYY/MM/DD"
                                    )}
                                  </h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary mx-2 btn-sm"
                        onClick={() => cancelSubscription(item.id)}
                      >
                        Cancel Subscription
                      </button>
                    </div>
                    <div className="TransactionArea">
                      <h5 className="Title">Transaction</h5>
                      {item.transactionHistory.length
                        ? item.transactionHistory.map((item2, index) => (
                            <article>
                              <aside>
                                <figcaption>
                                  <h5>Auto Debit</h5>
                                  <p>ID : {item2?.id}</p>
                                </figcaption>
                                <h5 className="Red">${item2?.amountPaid}</h5>
                              </aside>
                              <aside>
                                <h6>
                                  {item.productName}/
                                  {moment(item2?.paymentDate).format("MMM")}{" "}
                                  Month
                                </h6>
                                <h6>
                                  <span>
                                    {getFormattedDate(
                                      item2?.paymentDate,
                                      "YYYY/MM/DD hh:mm A"
                                    )}
                                  </span>
                                </h6>
                              </aside>
                            </article>
                          ))
                        : null}
                      {/* <article>
                        <aside>
                          <figcaption>
                            <h5>Auto Debit</h5>
                            <p>479632694091482304804</p>
                          </figcaption>
                          <h5 className="Red">$50</h5>
                        </aside>
                        <aside>
                          <h6>Individual Plan/Jan Month</h6>
                          <h6>
                            <span>07/06/24</span> 18:46
                          </h6>
                        </aside>
                      </article>
                      <article>
                        <aside>
                          <figcaption>
                            <h5>Auto Debit</h5>
                            <p>479632694091482304804</p>
                          </figcaption>
                          <h5 className="Red">$50</h5>
                        </aside>
                        <aside>
                          <h6>Individual Plan/Jan Month</h6>
                          <h6>
                            <span>07/06/24</span> 18:46
                          </h6>
                        </aside>
                      </article>
                      <article>
                        <aside>
                          <figcaption>
                            <h5>Auto Debit</h5>
                            <p>479632694091482304804</p>
                          </figcaption>
                          <h5 className="Red">$50</h5>
                        </aside>
                        <aside>
                          <h6>Individual Plan/Jan Month</h6>
                          <h6>
                            <span>07/06/24</span> 18:46
                          </h6>
                        </aside>
                      </article> */}
                    </div>
                    <div style={{ clear: "both" }} />
                  </div>
                </div>
              ))
            : null}
        </>
      ) : null}
    </>
  );
};

const PlanModal = ({
  show,
  onHide,
  planId,
  productId,
  planData,
  selectedOptions,
  setSelectedOptions,
  popup,
  setpopup,
}) => {
  const [family, setfamily] = useState([]);
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.user);
  const [selectedRelations, setselectedRelations] = useState([
    { id: reduxUser?._id, relation: "self" },
  ]);
  const [totalAmount, settotalAmount] = useState(0);
  const [familyData, setFamilyData] = useState({
    relation: "",
    firstname: "",
    lastname: "",
    gender: "",
    dob: "",
    patient: "",
  });

  const [isChecked, setIsChecked] = useState(false);

  const today = moment().format("YYYY-MM-DD");
  const maxMembers =
    (selectedOptions.individual ? 1 : 0) +
    (selectedOptions.spouse ? 1 : 0) +
    (selectedOptions.children ? 1 : 0);
  const [maxMem, setmaxMem] = useState(maxMembers);

  useEffect(() => {
    getFam();
  }, []);

  console.log("PLAN DATA", planData);

  useEffect(() => {
    console.log("SELECTED RELATION", selectedRelations);
  }, [selectedRelations]);

  const handleCheckboxChange = (option) => {
    // Allow toggling only for spouse and children
    if (option !== "individual") {
      setSelectedOptions((prevState) => ({
        ...prevState,
        [option]: !prevState[option],
      }));
    }
  };

  const handleNext = () => {
    const selectedPrices = {
      individual:
        selectedOptions.individual &&
        (planData.planName === "Essential Care Plan"
          ? 59
          : planData.planName === "Comprehensive Care Plan"
          ? 79
          : 199),
      spouse:
        selectedOptions.spouse &&
        (planData.planName === "Essential Care Plan"
          ? 30
          : planData.planName === "Comprehensive Care Plan"
          ? 50
          : 90),
      children:
        selectedOptions.children &&
        (planData.planName === "Essential Care Plan"
          ? 10
          : planData.planName === "Comprehensive Care Plan"
          ? 20
          : 50),
    };

    const totalAmount =
      (selectedPrices.individual || 0) +
      (selectedPrices.spouse || 0) +
      (selectedPrices.children || 0);

    console.log("Selected Prices:", selectedPrices);

    // alert(`Selected Prices: ${JSON.stringify(selectedPrices)}`);
    console.log("Total Amount:", totalAmount);
    if (popup == 1) {
      settotalAmount(totalAmount);
      if (
        selectedOptions.individual == true &&
        selectedOptions.spouse == false &&
        selectedOptions.children == false
      ) {
        setpopup(5);
      } else {
        setpopup(2);
      }
    }
    if (popup == 2) {
      setpopup(5);
    }
    if (popup == 5) {
      setpopup(3);
    }
  };

  async function getFam(data) {
    dispatch(loader(true));
    const res = await patientService.getFamily(reduxUser?._id);
    if (res?.family.length) {
      console.log("res.family", res.family);
      setfamily(res.family);
    }
    dispatch(loader(false));
  }

  async function selectMembers(id, item) {
    const mem = {
      _id: id,
      relation: item.relation,
    };

    setselectedRelations([...selectedRelations, mem]);
  }

  async function selectMembers(id, item, isChecked) {
    const mem = {
      _id: id,
      relation: item.relation,
    };

    if (isChecked) {
      // Add member to selectedRelations
      setselectedRelations((prev) => [...prev, mem]);
    } else {
      // Remove member from selectedRelations
      setselectedRelations((prev) =>
        prev.filter((member) => member._id !== id)
      );
    }
  }

  // async function selectMembers(id, item, isChecked) {
  //   const mem = {
  //     _id: id,
  //     relation: item.relation,
  //   };

  //   // Determine the maximum allowed members based on selectedOptions

  //   setselectedRelations((prev) => {
  //     // Ensure the first element (default member) remains in the array
  //     const defaultMember = prev[0];

  //     if (isChecked) {
  //       // Add member if limit is not reached
  //       if (prev.length < maxMem + 1) {
  //         return [...prev, mem];
  //       } else {
  //         toast("You can only select up to " + maxMem + " family members.");
  //         return prev;
  //       }
  //     } else {
  //       // Remove member if it's not the default member
  //       return prev.filter(
  //         (member) => member?._id !== id || member === defaultMember
  //       );
  //     }
  //   });
  // }

  const familyDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFamilyData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSaveDetails = async () => {
    console.log("Family Data:", familyData);
    familyData.patient = reduxUser?._id;

    const res = await patientService.addFamilyMem(familyData);
    if (res) {
      getFam(reduxUser);
      showNotification(
        "success",
        "Family Member Added",
        "Family member was added successfuly."
      );
      setpopup(2);
    } else {
      showNotification("error", "Not Saved", "Something went wrong.");
    }
    setFamilyData({
      relation: "",
      firstname: "",
      lastname: "",
      gender: "",
      dob: "",
      patient: reduxUser?._id,
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Checkout</Modal.Title>
      </Modal.Header>
      {popup == 1 ? (
        <Modal.Body>
          <Form>
            <div className="bar">
              <Form.Check
                type="checkbox"
                label="Individual"
                checked={selectedOptions.individual}
                onChange={() => handleCheckboxChange("individual")}
                disabled
              />
            </div>
            <div className="bar">
              <Form.Check
                type="checkbox"
                label="With Spouse"
                checked={selectedOptions.spouse}
                onChange={() => handleCheckboxChange("spouse")}
              />
            </div>
            <div className="bar">
              <Form.Check
                type="checkbox"
                label="With Children"
                checked={selectedOptions.children}
                onChange={() => handleCheckboxChange("children")}
              />
            </div>
          </Form>
        </Modal.Body>
      ) : null}
      {popup == 2 ? (
        <div>
          {family.length
            ? family.map((item, index) => (
                <div className="CheckProfileBox" key={index}>
                  <span className="Profile">
                    <img
                      className="image-avatar"
                      src={
                        item?.profile_pic
                          ? item?.profile_pic
                          : item?.gender?.toLowerCase() == "male"
                          ? male
                          : female
                      }
                    />
                  </span>
                  <h3>{item?.firstname}</h3>
                  <ul>
                    <li>
                      <span className="fw-700">Age :</span>&nbsp;
                      {calculateAge(item?.dob)}
                    </li>
                    <li>
                      <span className="fw-700">Gender :</span>&nbsp;
                      {item?.gender}
                    </li>
                  </ul>
                  <h6>Relation</h6>
                  <div className="CheckName">
                    <label className="CheckBox">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          selectMembers(item?._id, item, e.target.checked)
                        }
                      />

                      <span className="checkmark"></span>
                    </label>
                  </div>
                  {/* <span className="RightArrow">
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </span> */}
                </div>
              ))
            : null}
          <div className="d-flex gap-2 my-2 justify-content-center">
            <div className="text-center">
              <a
                href="javascript:void(0);"
                className="AddPatientBtn"
                onClick={() => setpopup(4)}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Add Patient
              </a>
            </div>
            {/* <div className="text-center">
              <a
                href="javascript:void(0);"
                className="AddPatientBtn"
                onClick={() => setpopup(3)}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Next
              </a>
            </div> */}
          </div>
        </div>
      ) : null}
      {popup == 3 ? (
        <StripeCheckout
          amount={totalAmount}
          relations={selectedRelations}
          planId={planId}
          productId={productId}
          planData={planData}
        />
      ) : null}

      {popup == 4 ? (
        <>
          <>
            <div className="modal-body">
              <div className="ModalTopArea">
                <div className="BookingModalArea">
                  <h3 className="Title">Add Patient Info</h3>
                </div>
                <form>
                  <div className="LoginBox">
                    <div className="LoginBody">
                      <label>Select Relationship</label>
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="relation"
                          value={familyData.relation}
                          onChange={familyDataChange}
                        >
                          <option value="">Select</option>
                          <option value="Brother">Brother</option>
                          <option value="Sister">Sister</option>
                          <option value="Son">Son</option>
                          <option value="Daughter">Daughter</option>
                          <option value="Husband">Husband</option>
                          <option value="Wife">Wife</option>
                          <option value="Father">Father</option>
                          <option value="Mother">Mother</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="firstname"
                          value={familyData.firstname}
                          onChange={familyDataChange}
                        />
                        <label>First Name</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="lastname"
                          value={familyData.lastname}
                          onChange={familyDataChange}
                        />
                        <label>Last Name</label>
                      </div>
                      <div className="SelectGender">
                        <div className="form-group">
                          <label>Select Gender</label>
                          <ul>
                            <li>
                              <input
                                type="radio"
                                name="gender"
                                value="male"
                                checked={familyData.gender === "male"}
                                onChange={familyDataChange}
                              />
                              <aside>
                                <span>Male</span>
                              </aside>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="gender"
                                value="female"
                                checked={familyData.gender === "female"}
                                onChange={familyDataChange}
                              />
                              <aside>
                                <span>Female</span>
                              </aside>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="SelectGender">
                        <div className="form-group">
                          <label>Date of Birth</label>
                          <input
                            type="date"
                            className="form-control"
                            name="dob"
                            value={familyData.dob}
                            onChange={familyDataChange}
                            max={today}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ButtonBox ButtonBoxNew">
                      <button
                        type="button"
                        className="Dark FolderPermissionId"
                        onClick={handleSaveDetails}
                      >
                        Save Details
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        </>
      ) : null}

      {popup == 5 ? (
        <>
          <div className="modal-body">
            <div className="ModalTopArea">
              <div className="BookingModalArea">
                <h3 className="Title">Membership Policies</h3>
              </div>
              <form onSubmit={() => {}}>
                <div className="LoginBox">
                  <div className="LoginBody">
                    <ul className="PolicyList">
                      <li className="my-3">
                        1. Memberships are non-transferable and non-refundable.
                      </li>
                      <li className="my-3">
                        2. A minimum subscription period of one month is
                        required.
                      </li>
                      <li className="my-3">
                        3. Members must adhere to the terms of use outlined in
                        our agreement.
                      </li>
                      <li className="my-3">
                        4. Any violation of policies may result in membership
                        cancellation.
                      </li>
                    </ul>
                    <div>
                      <input
                        className="mx-2"
                        type="checkbox"
                        id="agreePolicies"
                        name="agreePolicies"
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                      />
                      <label htmlFor="agreePolicies">
                        I agree to the membership policies.
                      </label>
                    </div>
                    <div className="CheckboxArea d-flex justify-content-end my-3 ">
                      <button
                        className="btn btn-primary"
                        onClick={handleNext}
                        disabled={!isChecked}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : null}

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {popup == 4 || popup == 3 || popup == 5 ? null : (
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Membership;

// <>
//   <Helmet>

//   </Helmet>

//   {!isloggedin ? (
//     <>
//       <div className="modal-body w-100 d-flex justify-content-center">
//         <div className="ModalTopArea w-50">
//           <div className="BookingModalArea">
//             <h3 className="Title">
//               Purchase Membership <br />
//               Plan
//             </h3>
//             <div className="PayMonthly">
//               <aside>
//                 <span>
//                   <img src="/images/pay-monthly.png" />
//                 </span>
//                 <h4>Pay Monthly</h4>
//                 <h6>
//                   $
//                   {membership[selectedMembership]?.membership_discount_cost}{" "}
//                   per month
//                 </h6>
//               </aside>
//               <p>
//                 ${membership[selectedMembership]?.membership_cost} today.
//                 Then $
//                 {membership[selectedMembership]?.membership_discount_cost}{" "}
//                 per month for 12 months.
//               </p>
//             </div>
//             <div className="MembershipPlan">
//               <ul>
//                 {membership?.map((item, index) => (
//                   <li onClick={() => setselectedMembership(index)}>
//                     <input key={index} type="radio" name={`plan1`} />
//                     <aside>
//                       <span className="Icon">
//                         <img src="/images/plan-1.png" />
//                       </span>
//                       <h6>{item?.membership_name}</h6>
//                       <p>${item?.membership_discount_cost} pm</p>
//                       <span className="CheckOverlay">
//                         <i className="fa fa-check" aria-hidden="true" />
//                       </span>
//                     </aside>
//                   </li>
//                 ))}
//               </ul>
//               <div className="ButtonBox ButtonBoxNew mt-3">
//                 <button
//                   className=""
//                   onClick={() => {
//                     if (selectedMembership >= 0) {
//                       // setbookingTabs(7);
//                     } else {
//                       toast("Please select a plan.");
//                     }
//                   }}
//                 >
//                   Confirm Payment
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   ) : Object.keys(purchasedPlan).length ? (
//     <>
//       <div className="Small-Wrapper">
//         <div className="container">
//           <div className="TitleBox">
//             <h4 className="Title">Membership</h4>
//           </div>
//           <div className="RenewArea">
//             <span className="Green TopSpan">
//               {" "}
//               <img src="../images/set-up.png" />
//               {purchasedPlan.is_plan_over ? (
//                 <>Your membership is Not Active</>
//               ) : (
//                 <>Your membership is Active</>
//               )}
//             </span>
//             <div className="PayMonthlyBox">
//               <aside>
//                 <figcaption>
//                   <h4>Pay Monthly</h4>
//                   <p>${purchasedPlan?.reduced_amount} per month</p>
//                 </figcaption>
//                 <button className="Expired">
//                   {purchasedPlan?.is_plan_expired ? (
//                     <>Expired</>
//                   ) : (
//                     <>Active</>
//                   )}
//                 </button>
//               </aside>
//               <div className="PayMonthlyTable">
//                 <span className="Icon">
//                   <img src="../images/individual.png" />
//                 </span>
//                 <table>
//                   <tbody>
//                     <tr>
//                       <td>
//                         <h6 className="text-capitalize">
//                           {
//                             purchasedPlan?.membership_id
//                               ?.membership_description
//                           }
//                         </h6>
//                         <p>${purchasedPlan?.reduced_amount} pm</p>
//                       </td>
//                       <td>
//                         <p>Expired On</p>
//                         <h6>
//                           {getFormattedDate(
//                             purchasedPlan?.plan_end,
//                             "YYYY-MM-DD"
//                           )}
//                         </h6>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//           <div className="TransactionArea">
//             <h5 className="Title">Transaction</h5>
//             {purchasedPlan?.installments?.length
//               ? purchasedPlan?.installments?.map((item, index) => (
//                   <article>
//                     <aside>
//                       <figcaption>
//                         <h5>Auto Debit</h5>
//                         <p>{item.transaction_id}</p>
//                       </figcaption>
//                       <h5 className="Red">${item?.amount_paid}</h5>
//                     </aside>
//                     <aside>
//                       <h6 className="text-capitalize">
//                         {
//                           purchasedPlan?.membership_id
//                             ?.membership_description
//                         }
//                         {item.month_name} Month
//                       </h6>
//                       <h6>
//                         <span>
//                           {getFormattedDate(
//                             item?.payment_date,
//                             "DD-MM-YYYY"
//                           )}
//                         </span>{" "}
//                         {extractTime(item?.payment_date)}
//                       </h6>
//                     </aside>
//                   </article>
//                 ))
//               : null}
//           </div>
//           <div style={{ clear: "both" }} />
//         </div>
//       </div>

//       <div className="ModalBox">
//         <div className="modal fade" id="PatientInsuranceInformation">
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <button
//                   type="button"
//                   className="CloseModal"
//                   data-dismiss="modal"
//                 >
//                   ×
//                 </button>
//                 <div className="ModalTopArea">
//                   <div className="BookingModalArea">
//                     <h3 className="Title">Insurance Information</h3>
//                   </div>
//                   <form>
//                     <div className="LoginBox">
//                       <div className="LoginBody">
//                         <div className="form-group">
//                           <label>Carrier Name</label>
//                           <select className="form-control">
//                             <option>Select</option>
//                             <option>Tricare West</option>
//                           </select>
//                         </div>
//                         <div className="form-group">
//                           <label>
//                             Independent Physician Association (IPA)
//                           </label>
//                           <select className="form-control">
//                             <option>Select IPA</option>
//                             <option />
//                           </select>
//                         </div>
//                         <div className="row">
//                           <div className="form-group col-lg-6">
//                             <label>Member ID</label>
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder={54512323}
//                             />
//                           </div>
//                           <div className="form-group col-lg-6">
//                             <label>Group ID/No.</label>
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder={54512323}
//                             />
//                           </div>
//                         </div>
//                         <div className="form-group">
//                           <label>Date of Birth</label>
//                           <input type="date" className="form-control" />
//                         </div>
//                         <div className="form-group">
//                           <div className="SelectGender">
//                             <label>Upload Insurance Card Photo</label>
//                             <figure>
//                               <img src="../images/card.png" />
//                             </figure>
//                           </div>
//                           <div className="ImageSize">
//                             <figcaption>
//                               <h4>Jpeg, Png &amp; PDF</h4>
//                               <p>20 MB max</p>
//                             </figcaption>
//                             <button className="Button Plus">
//                               <i
//                                 className="fa fa-plus"
//                                 aria-hidden="true"
//                               />
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="ButtonBox">
//                         <button
//                           className="Dark FolderPermissionId"
//                           data-toggle="modal"
//                           data-target="#PatientSubscriberInformation"
//                           data-dismiss="modal"
//                         >
//                           Next
//                         </button>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="modal fade" id="PatientSubscriberInformation">
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <button
//                   type="button"
//                   className="CloseModal"
//                   data-dismiss="modal"
//                 >
//                   ×
//                 </button>
//                 <div className="ModalTopArea">
//                   <div className="BookingModalArea">
//                     <h3 className="Title">Subscriber Information</h3>
//                   </div>
//                   <form>
//                     <div className="LoginBox">
//                       <div className="LoginBody">
//                         <div className="form-group">
//                           <label>
//                             Patient's Relationship to Subscriber
//                           </label>
//                           <select className="form-control">
//                             <option>Select</option>
//                             <option>Tricare West</option>
//                           </select>
//                         </div>
//                         <div className="form-group">
//                           <label>First Name</label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Zeeshan"
//                           />
//                         </div>
//                         <div className="form-group">
//                           <label>Date of Birth</label>
//                           <input type="date" className="form-control" />
//                         </div>
//                         <div className="form-group">
//                           <label>Subscriber Address</label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="London"
//                           />
//                         </div>
//                         <div className="row">
//                           <div className="form-group col-lg-6">
//                             <label>City</label>
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder="London"
//                             />
//                           </div>
//                           <div className="form-group col-lg-6">
//                             <label>State</label>
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder="New York"
//                             />
//                           </div>
//                         </div>
//                       </div>
//                       <div className="ButtonBox">
//                         <button
//                           className="Dark"
//                           data-toggle="modal"
//                           data-target="#WhoIsAppointment"
//                         >
//                           Next
//                         </button>
//                         <a
//                           href="insurance-information-verified.html"
//                           className="Dark"
//                         >
//                           Next
//                         </a>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   ) : (
//     <>
//       <div className="modal-body w-100 d-flex justify-content-center">
//         <div className="ModalTopArea w-50">
//           <div className="BookingModalArea">
//             <h3 className="Title">
//               Purchase Membership <br />
//               Plan
//             </h3>
//             <div className="PayMonthly">
//               <aside>
//                 <span>
//                   <img src="/images/pay-monthly.png" />
//                 </span>
//                 <h4>Pay Monthly</h4>
//                 <h6>
//                   $
//                   {membership[selectedMembership]?.membership_discount_cost}{" "}
//                   per month
//                 </h6>
//               </aside>
//               <p>
//                 ${membership[selectedMembership]?.membership_cost} today.
//                 Then $
//                 {membership[selectedMembership]?.membership_discount_cost}{" "}
//                 per month for 12 months.
//               </p>
//             </div>
//             <div className="MembershipPlan">
//               <ul>
//                 {membership?.map((item, index) => (
//                   <li onClick={() => setselectedMembership(index)}>
//                     <input key={index} type="radio" name={`plan1`} />
//                     <aside>
//                       <span className="Icon">
//                         <img src="/images/plan-1.png" />
//                       </span>
//                       <h6>{item?.membership_name}</h6>
//                       <p>${item?.membership_discount_cost} pm</p>
//                       <span className="CheckOverlay">
//                         <i className="fa fa-check" aria-hidden="true" />
//                       </span>
//                     </aside>
//                   </li>
//                 ))}
//               </ul>
//               <div className="ButtonBox mt-3">
//                 <button
//                   className=""
//                   onClick={() => {
//                     if (selectedMembership >= 0) {
//                       // setbookingTabs(7);
//                     } else {
//                       toast("Please select a plan.");
//                     }
//                   }}
//                 >
//                   Confirm Payment
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )}
// </>
