import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agoraToken: "", // This should match the state property you update
};

const agoraTokenSlice = createSlice({
  name: "agoraToken",
  initialState,
  reducers: {
    setAgoraToken: (state, action) => {
      state.agoraToken = action.payload; // Make sure this matches the initialState property
    },
  },
});

export const { setAgoraToken } = agoraTokenSlice.actions;
export default agoraTokenSlice.reducer;
