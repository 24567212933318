// CheckoutComponent.js
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import planImg from "../../assets/imges/plan-1.png";
import planImg2 from "../../assets/imges/plan-2.png";
import avatar from "../../assets/imges/Avatar-1.png";
import { useDispatch, useSelector } from "react-redux";
import { calculateAge } from "../../helper/helper";
import AddFamilyInMembership from "../Popups/AddFamilyInMembership";
import { patientService } from "../../services/patientServices";
import { loader } from "../../store/LoaderSlice";
import { toast } from "react-toastify";
import { updateUser } from "../../store/UserSlice";
const stripePromise = loadStripe(
  "pk_test_51QBKesJTkgYmRBAOnfV2tMLajeWwz5F1bRzTvhJcVjdRNQgUbiKu4wXNObFBY7hImCeaESHygFQUfR0pD8EiCDWb00K09D36eN"
);

const CheckoutComponent = ({ customerId }) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1); // Default for Family Plan
  const [planType, setPlanType] = useState("individual");
  const userData = useSelector((state) => state.user);
  const [familyRelations, setfamilyRelations] = useState([
    { relation: "self", id: userData._id },
  ]);
  const [family, setfamily] = useState([]);

  //..............

  const [show, setShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  //...............

  //   const handleCheckout = async () => {
  //     const stripe = await stripePromise;

  //     const response = await fetch(
  //       "http://localhost:5001/api/stripe/create-checkout-session",
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({ planType, customerId, quantity }),
  //       }
  //     );

  //     const session = await response.json();
  //     if (session.url) {
  //       const { error } = await stripe.redirectToCheckout({
  //         sessionId: session.id,
  //       });
  //       if (error) {
  //         console.error("Error redirecting to checkout:", error);
  //         alert("Failed to redirect to checkout.");
  //       } else {
  //         // Show success or cancel toast based on the session URL
  //         if (session.url.includes("success")) {
  //           alert("Payment successful!");
  //         } else {
  //           alert("Payment canceled.");
  //         }

  //         // After payment is complete, hit the confirm-subscription API
  //         fetch(
  //           "http://localhost:5001/api/stripe/get-plans/confirm-subscription",
  //           {
  //             method: "POST",
  //             headers: { "Content-Type": "application/json" },
  //             body: JSON.stringify({
  //               sessionId: session.id,
  //               customerId,
  //               planType,
  //               quantity,
  //             }),
  //           }
  //         )
  //           .then((response) => response.json())
  //           .then((data) => {
  //             if (data.success) {
  //               alert("Subscription created successfully!");
  //             } else {
  //               alert("Failed to create subscription.");
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             alert("An error occurred.");
  //           });
  //       }
  //     } else {
  //       alert("Failed to create checkout session.");
  //     }
  //   };

  const handleCheckout = async () => {
    if (planType == "family") {
      if (familyRelations.length < 2) {
        toast("Minimum 2 members are required to purchase family plan");
        return;
      }
    }
    const stripe = await stripePromise;

    // Retrieve the Bearer token from localStorage
    const token = localStorage.getItem("token"); // Ensure the key matches your stored token

    if (!token) {
      alert("User is not authenticated. Please log in.");
      return;
    }

    try {
      // Create the checkout session
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/stripe/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the Bearer token
          },
          body: JSON.stringify({
            planType,
            customerId,
            family_relations: familyRelations,
          }),
        }
      );

      const session = await response.json();

      if (session.url) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        // if (error) {
        //   console.error("Error redirecting to checkout:", error);
        //   alert("Failed to redirect to checkout.");
        // } else {
        //   // Handle post-payment actions
        //   if (session.url.includes("success")) {
        //     alert("Payment successful!");
        //   } else {
        //     alert("Payment canceled.");
        //   }

        //   // Confirm the subscription
        //   const confirmResponse = await fetch(
        //     "http://localhost:5001/api/stripe/get-plans/confirm-subscription",
        //     {
        //       method: "POST",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${token}`, // Include the Bearer token
        //       },
        //       body: JSON.stringify({
        //         sessionId: session.id,
        //         customerId,
        //         planType,
        //         quantity,
        //       }),
        //     }
        //   );

        //   const confirmData = await confirmResponse.json();

        //   if (confirmData.success) {
        //     alert("Subscription created successfully!");
        //   } else {
        //     alert("Failed to create subscription.");
        //   }
        // }
      } else {
        alert("Failed to create checkout session.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during the process.");
    }
  };

  async function getFamilyMem() {
    try {
      dispatch(loader(true));

      const result = await patientService.getFamily(userData?._id);
      if (result.family_found == true) {
        dispatch(loader(false));
        setfamily(result.family);
      } else {
        dispatch(loader(false));
      }
    } catch (err) {
      dispatch(loader(false));
      console.log(err.message);
    }
  }

  useEffect(() => {
    getFamilyMem();
  }, []);
  return (
    // <div>
    //   <h1>Select Your Plan</h1>
    //   <label>
    //     <input
    //       type="radio"
    //       value="individual"
    //       checked={planType === "individual"}
    //       onChange={() => setPlanType("individual")}
    //     />
    //     Individual Plan
    //   </label>
    //   <label>
    //     <input
    //       type="radio"
    //       value="family"
    //       checked={planType === "family"}
    //       onChange={() => setPlanType("family")}
    //     />
    //     Family Plan
    //   </label>
    //   {planType === "family" && (
    //     <div>
    //       <label>
    //         Number of Seats:
    //         <input
    //           type="number"
    //           value={quantity}
    //           min="2"
    //           onChange={(e) => setQuantity(e.target.value)}
    //         />
    //       </label>
    //     </div>
    //   )}
    //   <button onClick={handleCheckout}>Subscribe</button>
    // </div>
    <div className="modal-body w-100 d-flex justify-content-center">
      <div className="ModalTopArea w-50">
        <div className="BookingModalArea">
          <h3 className="Title">
            Purchase Membership <br />
            Plan
          </h3>
          <div className="MembershipPlan">
            <ul>
              <li onClick={() => setPlanType("individual")}>
                <input
                  type="radio"
                  name="planType"
                  value="individual"
                  checked={planType === "individual"}
                />
                <aside>
                  <span className="Icon">
                    <img src={planImg} alt="Individual Plan" />
                  </span>
                  <h6>Individual Plan</h6>
                  <p>$10 per month</p>
                  <span className="CheckOverlay">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </aside>
              </li>
              <li
                onClick={() => {
                  setPlanType("family");
                  setShow(true);
                }}
              >
                <input
                  type="radio"
                  name="planType"
                  value="family"
                  checked={planType === "family"}
                />
                <aside>
                  <span className="Icon">
                    <img src={planImg2} alt="Family Plan" />
                  </span>
                  <h6>Family Plan</h6>
                  <p>$7 per member</p>
                  <span className="CheckOverlay">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </aside>
              </li>
            </ul>

            {planType === "family" && (
              <>
                <AppointmentModal
                  show={show}
                  setShow={setShow}
                  userData={userData}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  family={family}
                  setfamilyRelations={setfamilyRelations}
                  familyRelations={familyRelations}
                />
                <AddPatientInfoModal
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setShow={setShow}
                  getFamilyMem={getFamilyMem}
                />
              </>
              // <div className="FamilyPlanInput">
              //   <label>
              //     Number of Seats:
              //     <input
              //       type="number"
              //       value={quantity}
              //       min="2"
              //       onChange={(e) => setQuantity(e.target.value)}
              //     />
              //   </label>
              // </div>
            )}
          </div>
          <div className="ButtonBox ButtonBoxNew mt-3">
            <button
              className="ConfirmPaymentButton"
              onClick={() => handleCheckout(planType, quantity)}
            >
              Confirm Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// const AppointmentModal = ({
//   show,
//   setShow,
//   userData,
//   isModalVisible,
//   setIsModalVisible,
//   family,
//   setfamilyRelations,
//   familyRelations
// }) => {
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   function addToRelation(item){

//   }

//   return (
//     <>
//       {/* Modal Component */}
//       <Modal show={show} onHide={handleClose} centered>
//         <Modal.Body>
//           <div className="ModalTopArea">
//             <div className="BookingModalArea">
//               <h3 className="Title">
//                 Add Members <br /> including yourself ?
//               </h3>
//               <div className="AppointmentPersonBox">
//                 <aside className="border border-danger">
//                   <span className="Profile">
//                     <img src={avatar} className="w-25" alt="profile" />
//                   </span>
//                   <h3>{userData.firstname}</h3>
//                   <ul>
//                     <li>{calculateAge(userData.dob)} years</li>
//                     <li>{userData.gender}</li>
//                   </ul>
//                   <span className="RightArrow">
//                     <i className="fa fa-chevron-right" aria-hidden="true" />
//                   </span>
//                 </aside>
//                 {/* <aside>
//                   <span className="Profile">
//                     <img src="images/girl-avatar.png" alt="profile" />
//                   </span>
//                   <h3>Miss Sharnya</h3>
//                   <ul>
//                     <li>27 years</li>
//                     <li>Female</li>
//                   </ul>
//                   <span className="RightArrow">
//                     <i className="fa fa-chevron-right" aria-hidden="true" />
//                   </span>
//                 </aside> */}

//                 {family.length
//                   ? family.map((item, index) => (
//                       <div className="MyProfileBox" onClick={()=>addToRelation(item)}>
//                         <span key={index} className="Icon">
//                           <img
//                             src={
//                               item?.profile_pic
//                                 ? item?.profile_pic
//                                 : "../images/familyAvatar.png"
//                             }
//                           />
//                         </span>
//                         <aside>
//                           <h6 className="text-capitalize">{item.firstname}</h6>
//                           <ul>
//                             <li>{calculateAge(item.dob)} Years</li>
//                             <li>{item.relation} </li>
//                             <li>{item.gender} </li>
//                           </ul>
//                         </aside>
//                         {/* <span onClick={() => editMemeber(item?._id)}>
//                           <img src="../images/edit-icon.png" />
//                         </span> */}
//                       </div>
//                     ))
//                   : null}
//               </div>
//               <div className="text-center">
//                 <a
//                   href="javascript:void(0);"
//                   className="AddPatientBtn"
//                   onClick={() => {
//                     handleClose();
//                     setIsModalVisible(true);
//                   }}
//                 >
//                   <i className="fa fa-plus" aria-hidden="true" />
//                   Add Member
//                 </a>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

const AppointmentModal = ({
  show,
  setShow,
  userData,
  isModalVisible,
  setIsModalVisible,
  family,
  setfamilyRelations,
  familyRelations,
}) => {
  const handleClose = () => {
    setShow(false);
  };

  // Function to add a new relation to familyRelations
  // const addToRelation = (item) => {
  //   const alreadyExists = familyRelations.some(
  //     (relation) => relation.relation === item.relation
  //   );

  //   if (alreadyExists) {
  //     toast.error(
  //       `${item.relation} is already added. Only one ${item.relation} can be selected.`
  //     );
  //   } else {
  //     setfamilyRelations((prevRelations) => {
  //       const updatedRelations = [
  //         ...prevRelations,
  //         { relation: item.relation, id: item._id },
  //       ];
  //       console.log("Updated familyRelations array:", updatedRelations);
  //       return updatedRelations;
  //     });
  //     // toast.success(`${item.relation} added successfully.`);
  //   }
  // };

  const addToRelation = (item) => {
    const alreadyExistsIndex = familyRelations.findIndex(
      (relation) => relation.relation === item.relation
    );

    if (alreadyExistsIndex !== -1) {
      // If already exists, remove it
      setfamilyRelations((prevRelations) => {
        const updatedRelations = prevRelations.filter(
          (_, index) => index !== alreadyExistsIndex
        );
        console.log("Removed from familyRelations:", item);
        return updatedRelations;
      });
      // toast.info(`${item.relation} removed successfully.`);
    } else {
      // Check if the relation type is already added
      const isRelationTypeExists = familyRelations.some(
        (relation) => relation.relation === item.relation
      );

      if (isRelationTypeExists) {
        toast.error(
          `Only one ${item.relation} can be added. ${item.relation} is already selected.`
        );
      } else {
        // Add the new relation
        setfamilyRelations((prevRelations) => {
          const updatedRelations = [
            ...prevRelations,
            { relation: item.relation, id: item._id },
          ];
          console.log("Added to familyRelations:", updatedRelations);
          return updatedRelations;
        });
        toast.success(`${item.relation} added successfully.`);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="ModalTopArea text-capitalize">
            <div className="BookingModalArea">
              <h3 className="Title">
                Add Members <br /> including yourself?
              </h3>
              <div className="AppointmentPersonBox">
                <aside className="border border-danger">
                  <span className="Profile">
                    <img
                      src={userData.profile_pic ? userData.profile_pic : avatar}
                      className="w-25"
                      alt="profile"
                    />
                  </span>
                  <h3>{userData.firstname}</h3>
                  <ul>
                    <li>{calculateAge(userData.dob)} years</li>
                    <li>{userData.gender}</li>
                  </ul>
                  <span className="RightArrow">
                    <i className="fa fa-chevron-right" aria-hidden="true" />
                  </span>
                </aside>

                {family.length
                  ? family.map((item, index) => (
                      <div
                        className={`MyProfileBox ${
                          familyRelations.some(
                            (relation) =>
                              relation.relation === item.relation &&
                              relation.id === item._id
                          )
                            ? "border border-danger"
                            : ""
                        }`}
                        key={index}
                        onClick={() => addToRelation(item)}
                      >
                        <span className="Icon">
                          <img
                            className="image-avatar"
                            src={
                              item?.profile_pic
                                ? item?.profile_pic
                                : "../images/familyAvatar.png"
                            }
                          />
                        </span>
                        <div>
                          <h6 className="text-capitalize">{item.firstname}</h6>
                          {/* <ul >
                            <li className="">{calculateAge(item.dob)} Years</li>
                            <li> {item.relation} </li>
                            <li> {item.gender} </li>
                          </ul> */}

                          <div className="d-flex gap-2">
                            <span className="">
                              {calculateAge(item.dob)} Years |
                            </span>
                            <span> {item.relation} </span>
                            <span>| {item.gender} </span>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div className="text-center">
                <a
                  href="javascript:void(0);"
                  className="AddPatientBtn"
                  onClick={() => {
                    handleClose();
                    setIsModalVisible(true);
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true" />
                  Add Member
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const AddPatientInfoModal = ({
  isModalVisible,
  setIsModalVisible,
  setShow,
  getFamilyMem,
}) => {
  const closeModal = () => {
    setIsModalVisible(false);
    setShow(true);
    getFamilyMem();
  };
  const openModal = () => setIsModalVisible(true);

  return (
    <>
      {/* Modal Component */}
      <Modal show={isModalVisible} onHide={closeModal} centered>
        <AddFamilyInMembership closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default CheckoutComponent;
