import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { doctorService } from "../../../services/doctorService";
import { toast } from "react-toastify";

function ChangePassword() {
  const dispatch = useDispatch();
  const [changePassword, setChangePassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleChange = (e) => {
    setChangePassword({ ...changePassword, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById("newPassword");
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
  };

  const toggleCurrentPasswordVisibility = () => {
    const passwordInput = document.getElementById("currentPassword");
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
  };

  const toggleConfirmPasswordVisibility = () => {
    const passwordInput = document.getElementById("confirmPassword");
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
  };

  const submitHandler = async (e) => {
    dispatch(loader(true));
    e.preventDefault();
    // submit form to server

    const data = {
      newpassword: changePassword.newPassword,
      confirmpassword: changePassword.confirmPassword,
    };

    if (changePassword.currentPassword === changePassword.confirmPassword) {
      const res = await doctorService.updatePass();
      console.log(changePassword);
      if (res.success) {
        toast("Password updated");
      }
    } else {
      toast("Passwords do not match");
    }

    dispatch(loader(false));
  };
  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">Change Password</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>
              <a href="settings.html">
                <i className="fa fa-angle-left" aria-hidden="true" />
              </a>{" "}
              Change Password
            </h4>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="PasswordBox">
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <input
                      type="password"
                      name="currentPassword"
                      value={changePassword.currentPassword}
                      className="form-control"
                      placeholder="Current Password"
                      onChange={handleChange}
                      id="currentPassword"
                    />
                    <span
                      className="Icon"
                      onClick={toggleCurrentPasswordVisibility}
                    >
                      <img src="/images/Eye.png" alt="Toggle visibility" />
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      name="newPassword"
                      id="newPassword"
                      value={changePassword.newPassword}
                      onChange={handleChange}
                    />
                    <span className="Icon" onClick={togglePasswordVisibility}>
                      <img src="/images/Eye.png" alt="Toggle visibility" />
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={changePassword.confirmPassword}
                      onChange={handleChange}
                      id="confirmPassword"
                    />
                    <span
                      className="Icon"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      <img src="/images/Eye.png" alt="Toggle visibility" />
                    </span>
                  </div>
                  <button
                    data-toggle="modal"
                    data-target="#DoctorCongratulationModal"
                  >
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
