import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { conversationService } from "../../../services/conversationService";
import { toast } from "react-toastify";
import { useState } from "react";
import io from "socket.io-client";
import {
  formatDate,
  getFileType,
  getFormattedDate,
  getFormattedDateForChat,
  openImageInNewTab,
  showNotification,
} from "../../../helper/helper";
import { useRef } from "react";
import { uploadToAws } from "../../../helper/s3Helper";
import AudioPlayer from "../../audioPlayer/AudioPLayer";
import VideoPlayer from "../../videoPlayer/VideoPLayer";
import { FcImageFile } from "react-icons/fc";
import { getSocket } from "../../../config/socketIoConfig";
const Messaging = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const ulRef = useRef(null);
  const reduxUser = useSelector((state) => state.user);
  const [conversation_id, setconversation_id] = useState("");
  const [messages, setmessages] = useState([]);
  const [ChatStatus, setsetChatStatus] = useState("");
  const [socket, setSocket] = useState(null);
  const [typedMessage, settypedMessage] = useState("");
  const [senderName, setsenderName] = useState("");
  const [receiverName, setreceiverName] = useState("");
  const [typing, settyping] = useState(false);
  const { participant } = location.state;

  const SOCKET_URL = "http://3.13.196.242:5001";
  // const SOCKET_URL = "http://localhost:5001";

  useEffect(() => {
    if (reduxUser?._id) {
      const socketIo = getSocket(reduxUser?._id);

      setSocket(socketIo);

      socketIo.on("connect", () => {
        console.log("Connected to Socket.IO server");
      });

      socketIo.on("disconnect", (reason) => {
        console.log("Disconnected from Socket.IO server:", reason);
      });

      socketIo.on("rcv-one-to-one-message", (msg) => {
        console.log("Message received:", msg);
        setmessages((prevMessages) => [...prevMessages, msg]);
      });

      socketIo.on("typing", (msg) => {
        if (msg.event == "started typing") {
          settyping(true);
        }
      });

      socketIo.on("stopTyping", (msg) => {
        if (msg.event == "stopped typing") {
          settyping(false);
        }
      });

      // return () => {
      //   socketIo.disconnect();
      // };
    }
  }, [reduxUser]);

  useEffect(() => {
    console.log("=>", params.patient);
    console.log("=>", params.doctor);

    if (params.patient && params.doctor && params.appointment) {
      create_conversation(params.patient, params.doctor, params.appointment);
    }
  }, [params]);

  useEffect(() => {
    if (messages.length) {
      if (ulRef.current) {
        // Scroll to the bottom of the ul element
        ulRef.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
      }

      const seen_msg = {
        conversation_id: conversation_id,
        seen_by: "patient",
      };

      socket.emit("seen-message", seen_msg);
    }
  }, [messages]);

  useEffect(() => {
    const data = {
      receiver_id: params.doctor,
      sender_id: reduxUser?._id,
    };
    if (typedMessage == "" && socket != null) {
      socket?.emit("stopTyping", data);
    } else {
      socket?.emit("typing", data);
    }
  }, [typedMessage, socket]);

  async function create_conversation(patient, doctor, appointment) {
    try {
      const res = await conversationService.createConvo(
        patient,
        doctor,
        appointment
      );
      if (res.success == true && res.conversation_type == "new") {
        setconversation_id(res.convo._id);
        setsetChatStatus(true);
        // toast("Chat Initiated");
      }
      if (res.success == true && res.conversation_type == "existing") {
        setconversation_id(res.convo._id);
        getMessagesInsidechat(res.convo._id);
      }
    } catch (err) {}
  }

  async function getMessagesInsidechat(id) {
    try {
      const res = await conversationService.getMessagesByConversation(id);
      if (res.success) {
        setmessages(res.messages);
        setsetChatStatus(res.convo_open_or_close);
        const patient = res.participants.find((item) => item.type == "patient");
        const doctor = res.participants.find((item) => item.type == "doctor");
        // console.log("pat", patient, "doc", doctor);
        setsenderName(patient.participantId.firstname);
        setreceiverName(doctor.participantId.firstname);
      } else {
        setsetChatStatus(res.convo_open_or_close);
      }
    } catch (err) {}
  }

  async function sendMessage(e) {
    console.log("function hit");
    e.preventDefault();
    if (ChatStatus == false) {
      showNotification(
        "warning",
        "Chat is closed",
        "You cannot communicate as this chat is closed."
      );
      return;
    } else {
      if (typedMessage != "" && conversation_id != "") {
        const message = {
          receiver_id: params.doctor,
          sender_id: params.patient,
          sender_role: "patient",
          receiver_role: "doctor",
          message: typedMessage,
          conversation_id: conversation_id,
          message_type: "text",
        };

        console.log("sent-message", message);

        socket.emit("send-one-to-one-message", message);
        settypedMessage("");
      } else {
        toast("Please type a message.");
      }
    }
  }

  // async function handleFileChange(e) {
  //   const link = await uploadToAws(e.target.files[0]);
  //   const file_type = getFileType(e.target.files[0]);
  //   console.log("file type", file_type);
  //   if (file_type == "unknown") {
  //     showNotification(
  //       "error",
  //       "File Not Supported",
  //       "File type not supported."
  //     );
  //   } else {
  //     if (link && file_type) {
  //       const message = {
  //         receiver_id: params.doctor,
  //         sender_id: params.patient,
  //         sender_role: "patient",
  //         receiver_role: "doctor",
  //         message: link,
  //         conversation_id: conversation_id,
  //         message_type: file_type,
  //       };
  //       socket.emit("send-one-to-one-message", message);
  //       settypedMessage("");
  //     }
  //   }
  // }
  async function handleFileChange(e) {
    // Check if any files are selected
    if (!e.target.files || e.target.files.length === 0) {
      return; // Exit early if no files are selected
    }

    // Get the first file
    const file = e.target.files[0];

    try {
      const file_type = getFileType(file);
      console.log("file type", file_type);

      if (file_type === "unknown") {
        showNotification(
          "error",
          "File Not Supported",
          "File type not supported."
        );
      } else {
        const link = await uploadToAws(file);
        if (link && file_type) {
          const message = {
            receiver_id: params.doctor,
            sender_id: params.patient,
            sender_role: "patient",
            receiver_role: "doctor",
            message: link,
            conversation_id: conversation_id,
            message_type: file_type,
          };
          socket.emit("send-one-to-one-message", message);
          settypedMessage("");
        }
      }
    } catch (error) {
      console.error("Error handling file:", error);
      // Optionally show an error notification
      showNotification(
        "error",
        "Upload Error",
        "There was an error uploading the file."
      );
    }
  }

  return (
    <>
      <div
        className="Small-Wrapper"
        style={{ minHeight: "calc(100vh - 132px)" }}
      >
        <div className="d-flex justify-content-center">
          <h6 className="text-capitalize">
            Chatting with - Dr {participant?.firstname}
          </h6>
        </div>
        <div className="container px-5">
          <div className="ChatArea">
            <div className="ChatBody">
              <ul ref={ulRef} className="pr-3">
                {messages?.length
                  ? messages?.map((item, index) => (
                      <li key={index}>
                        {item.message_type == "text" ? (
                          <div
                            className={`${
                              reduxUser._id == item.sender
                                ? item.message_type == "text"
                                  ? "MyMessage"
                                  : "MyMessageWImg"
                                : item.message_type == "text"
                                ? "OtherMessage"
                                : "OtherMessageWImg"
                            }`}
                          >
                            <h5 className="text-capitalize">
                              {reduxUser._id == item.sender ? (
                                <strong>{senderName}</strong>
                              ) : (
                                <strong>{receiverName}</strong>
                              )}{" "}
                              <span>
                                {getFormattedDateForChat(
                                  item.createdAt,
                                  "YYYY-MM-DD HH:mm A"
                                )}
                              </span>{" "}
                            </h5>
                            <figure>
                              {/* <img src="/images/Tablets.png" /> */}
                            </figure>
                            <p>{item.content}</p>
                          </div>
                        ) : item.message_type == "image" ? (
                          <div
                            className={`${
                              reduxUser._id == item.sender
                                ? item.message_type == "text"
                                  ? "MyMessage"
                                  : "MyMessageWImg"
                                : item.message_type == "text"
                                ? "OtherMessage"
                                : "OtherMessageWImg"
                            }`}
                            onClick={() => openImageInNewTab(item.content)}
                          >
                            <h5 className="text-capitalize">
                              {reduxUser._id == item.sender ? (
                                <strong>{senderName}</strong>
                              ) : (
                                <strong>{receiverName}</strong>
                              )}{" "}
                              <span>
                                {getFormattedDateForChat(
                                  item.createdAt,
                                  "YYYY-MM-DD HH:mm A"
                                )}
                              </span>{" "}
                            </h5>
                            <figure>
                              {/* <img src="/images/Tablets.png" /> */}
                            </figure>
                            <img src={item.content}></img>
                          </div>
                        ) : item.message_type == "audio" ? (
                          <div
                            className={`${
                              reduxUser._id == item.sender
                                ? item.message_type == "text"
                                  ? "MyMessage"
                                  : "MyMessageWImg"
                                : item.message_type == "text"
                                ? "OtherMessage"
                                : "OtherMessageWImg"
                            }`}
                            onClick={() => openImageInNewTab(item.content)}
                          >
                            <h5 className="text-capitalize">
                              {reduxUser._id == item.sender ? (
                                <strong>{senderName}</strong>
                              ) : (
                                <strong>{receiverName}</strong>
                              )}{" "}
                              <span>
                                {getFormattedDateForChat(
                                  item.createdAt,
                                  "YYYY-MM-DD HH:mm A"
                                )}
                              </span>{" "}
                            </h5>
                            <figure>
                              {/* <img src="/images/Tablets.png" /> */}
                            </figure>
                            <AudioPlayer url={item.content} />
                          </div>
                        ) : item.message_type == "video" ? (
                          <div
                            className={`${
                              reduxUser._id == item.sender
                                ? item.message_type == "text"
                                  ? "MyMessage"
                                  : "MyMessageWImg"
                                : item.message_type == "text"
                                ? "OtherMessage"
                                : "OtherMessageWImg"
                            }`}
                            onClick={() => openImageInNewTab(item.content)}
                          >
                            <h5 className="text-capitalize">
                              {reduxUser._id == item.sender ? (
                                <strong>{senderName}</strong>
                              ) : (
                                <strong>{receiverName}</strong>
                              )}{" "}
                              <span>
                                {getFormattedDateForChat(
                                  item.createdAt,
                                  "YYYY-MM-DD HH:mm A"
                                )}
                              </span>{" "}
                            </h5>
                            <figure>
                              {/* <img src="/images/Tablets.png" /> */}
                            </figure>
                            <VideoPlayer url={item.content} />
                          </div>
                        ) : item.message_type == "file" ? (
                          <div
                            className={`${
                              reduxUser._id == item.sender
                                ? item.message_type == "text"
                                  ? "MyMessage"
                                  : "MyMessageWImg"
                                : item.message_type == "text"
                                ? "OtherMessage"
                                : "OtherMessageWImg"
                            }`}
                            onClick={() => openImageInNewTab(item.content)}
                          >
                            <h5 className="text-capitalize">
                              {reduxUser._id == item.sender ? (
                                <strong>{senderName}</strong>
                              ) : (
                                <strong>{receiverName}</strong>
                              )}{" "}
                              <span>
                                {getFormattedDateForChat(
                                  item.createdAt,
                                  "YYYY-MM-DD HH:mm A"
                                )}
                              </span>{" "}
                            </h5>
                            <figure>
                              {/* <img src="/images/Tablets.png" /> */}
                            </figure>
                            <img src="/images/file_icon.png" alt="" />
                          </div>
                        ) : null}
                      </li>
                    ))
                  : null}
              </ul>
              {typing ? (
                <div className="typing">
                  <strong>Typing</strong>
                  <div className="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              ) : null}
            </div>
            {ChatStatus == true ? (
              <div
                className="ChatFooter fixed-bottom"
                style={{ boxShadow: "0 -4px 60px 0 rgba(4, 6, 15, 0.05)" }}
              >
                <form className="w-100" onSubmit={(e) => sendMessage(e)}>
                  <aside
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <img src="/images/Attach.png" />
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                        multiple
                        onChange={handleFileChange}
                      />
                    </span>
                    <input
                      type="text"
                      value={typedMessage}
                      className="form-control"
                      placeholder="Type a Message..."
                      onChange={(e) => settypedMessage(e.target.value)}
                      required
                    />
                    <button type="submit">
                      <img src="/images/Plan.png" />{" "}
                    </button>
                  </aside>
                </form>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                Chat is completed. Cannot send new messages.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Messaging;
