import { Helmet } from "react-helmet";
import { emrService } from "../../../services/emrService";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  formatDate,
  getFormattedDate,
  startcsvdownload,
} from "../../../helper/helper";
import { loader } from "../../../store/LoaderSlice";

const Medications = () => {
  const dispatch = useDispatch();
  const emr_id = useSelector((state) => state.user.emr_id);
  const [documents, setDocuments] = useState([]);
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");

  useEffect(() => {
    if (emr_id) {
      getCarePlan();
    }
  }, []);

  async function getCarePlan() {
    dispatch(loader(true));
    const res = await emrService.getMedications(emr_id);
    if (res.success) {
      setDocuments(res.data);
      setNext(res.next);
      setPrev(res.prev);
    }
    dispatch(loader(false));
  }

  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Documents</h4>
          </div>
          {/* <div className="LabReporsArea">
            {documents.length ? (
              documents.map((item, index) => (
                <article>
                  <>{console.log("link=>", item.document)}</>
                  <a href="document-available.html">
                    <aside>
                      <figcaption>
                        <h4>{item?.metatags[1]}</h4>
                        <p>{item?.description}</p>
                      </figcaption>
                      <span>
                        <img src="../images/pdf.png" />
                      </span>
                    </aside>
                    <aside>
                      <p>
                        {getFormattedDate(item.updated_at, "YYYY-MM-DD hh:mm")}
                      </p>
                    </aside>
                  </a>
                </article>
              ))
            ) : (
              <div>No documents found</div>
            )}
          </div> */}
          <div className="LabReporsArea">
            {documents.length ? (
              documents.map((item, index) => (
                <article className="text-capitalize" key={index}>
                  <p className="float-right">
                    {/* {formatDate(item.updated_at, "YYYY-MM-DD")} */}
                  </p>
                  <aside>
                    <figcaption>
                      <h4 className="my-3">{item?.name}</h4>
                      <p className="my-3">
                        {item?.dosage_quantity != null ? (
                          <>Dosage - {item?.dosage_quantity}</>
                        ) : null}{" "}
                        {item?.dosage_units != null ? item?.dosage_units : null}
                      </p>
                    </figcaption>
                  </aside>
                  <aside></aside>
                  <span className="d-flex justify-content-between my-3">
                    <span>Start Date - {item?.date_started_taking}</span>
                    <span>End Date - {item?.date_stopped_taking}</span>
                  </span>
                  <span className="my-3">Note - {item.notes}</span>
                </article>
              ))
            ) : (
              <div>No documents found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Medications;
