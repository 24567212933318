import { Helmet } from "react-helmet";
const Documents = () => {
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Documents</h4>
          </div>
          <div className="VitalsAailable">
            <figure>
              <img src="../images/heart.png" />
            </figure>
            <figcaption>
              <h6>Currently No Documents available</h6>
              <p>Please visit our center to get your Documents</p>
            </figcaption>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
