import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import cong from "../../assets/imges/Congratulations.png";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axiosInstance from "../../axios/axios";
import { useSelector } from "react-redux";
import {
  calculateLastDate,
  getFormattedDate,
  showNotification,
} from "../../helper/helper";
import Swal from "sweetalert2";
import { loader } from "../../store/LoaderSlice";
import { useDispatch } from "react-redux";

const stripePromise = loadStripe(
  "pk_test_51QBKesJTkgYmRBAOnfV2tMLajeWwz5F1bRzTvhJcVjdRNQgUbiKu4wXNObFBY7hImCeaESHygFQUfR0pD8EiCDWb00K09D36eN"
);

const CheckoutForm = ({ amount, relations, planId, productId, planData }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const user_id = useSelector((state) => state.user._id);
  const user = useSelector((state) => state.user);

  // Create Payment Intent
  const createPaymentIntent = async () => {
    dispatch(loader(true));
    try {
      const response = await axiosInstance.post(
        "/api/stripe/create-payment-intent",
        {
          amount: amount * 100, // Amount in cents
          currency: "usd",
          description: "Subscription payment for Urhealthcare Membership",
        }
      );

      setClientSecret(response.data.clientSecret);
      dispatch(loader(false));
    } catch (error) {
      dispatch(loader(false));
      console.error("Error creating payment intent:", error.message);
    }
  };

  // Handle payment submission
  const handleSubmit = async (event) => {
    dispatch(loader(true));
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return; // Ensure Stripe.js is loaded
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: user.email, // Replace with actual customer email
        },
      },
      setup_future_usage: "off_session", // Save the payment method for future use
    });

    if (result.error) {
      console.error(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        console.log("Payment successful!");
        // Proceed to create the subscription
        createSubscription(result.paymentIntent.payment_method);
      }
    }

    setLoading(false);
  };

  // Create Subscription
  const createSubscription = async (paymentMethodId) => {
    try {
      dispatch(loader(true));
      const response = await axiosInstance.post(
        "/api/stripe/stripe-checkout-for-mobile",
        {
          customerId: user.customer_id, // Replace with actual customer ID
          amount: amount * 100, // Amount in cents
          members: {
            members: relations,
            planPurchased: planData.planName,
          },
          planPurchased: planData.planName,
          productId: productId, // Replace with actual product ID
          email: user.email, // Customer email
          paymentMethodId, // Pass the payment method ID
          patient_id: user_id,
        }
      );

      console.log("Subscription created:", response.data.subscriptionId);
      if (response.data.subscriptionId) {
        Swal.fire({
          icon: "success", // Set the icon to 'success' as it's for successful subscription
          title: "Subscription Purchased", // Title for the success notification
          text: "Subscription purchased successfully", // The message
          timer: 5000, // Display for 5 seconds
          timerProgressBar: true, // Show a progress bar for the countdown
          willClose: () => {
            // Reload the page after 5 seconds
            window.location.reload();
          },
        });
      }
      dispatch(loader(false));
    } catch (error) {
      dispatch(loader(false));
      console.error("Error creating subscription:", error.message);
    }
  };

  return (
    <div className="px-4 py-4">
      {clientSecret ? null : (
        <button onClick={createPaymentIntent}>Checkout Now</button>
      )}
      {clientSecret && (
        <form className="py-4" onSubmit={handleSubmit}>
          <CardElement />
          <button
            className="w-100 btn btn-warning my-4"
            type="submit"
            disabled={!stripe || loading}
          >
            {loading ? "Processing..." : "Pay"}
          </button>
        </form>
      )}
    </div>
  );
};

const StripeCheckout = ({ amount, relations, planId, productId, planData }) => (
  <Elements stripe={stripePromise}>
    <div className="modal-body">
      <div className="LoginBox FolderPermissionId">
        <div className="LoginHead">{/* <img src={cong} /> */}</div>
        <div className="AccountCreatBody">
          {/* <article>
            <h3>Account Created</h3>
            <h4>
              Congratulations <br />
              Your payment was successful
            </h4>
          </article> */}
        </div>
        <div className="PaymentSuccessTable">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Plan Type</p>
                  <h6>{planData.planName}</h6>
                </td>
                <td>
                  <p>Plan Description</p>
                  <h6>{planData.description}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Billing Month</p>
                  <h6>${amount} Monthly</h6>
                </td>
                <td>
                  <p>Purchase Date</p>
                  <h6>{getFormattedDate(new Date(), "YYYY-MM-DD")}</h6>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <p>Expiry Date</p>
                  <h6>
                    {planData?.plan_start
                      ? calculateLastDate(
                          planData.plan_start,
                          planData.membership_duration
                        )
                      : "-"}
                  </h6>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <p className="Note">
          <span>Note : </span> Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </p>
      </div>
      <div className="ButtonBox ButtonBoxNew mt-3">
        <CheckoutForm
          amount={amount}
          relations={relations}
          planId={planId}
          productId={productId}
          planData={planData}
        />
      </div>
    </div>
  </Elements>
);

export default StripeCheckout;
