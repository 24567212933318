import { Helmet } from "react-helmet";
import { staticContentService } from "../../services/staticContentService";
import { useEffect, useState } from "react";
const Faq = () => {
  const [faq, setfaq] = useState([]);

  useEffect(() => {
    getfaq();
  }, []);

  async function getfaq() {
    const res = await staticContentService.getFaq();
    if (res.success) {
      console.log("faq", res.data);
      setfaq(res.data);
    } else {
    }
  }
  return (
    <>
      <Helmet>{/* <link rel="stylesheet" href="/css/style.css" /> */}</Helmet>

      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Frequently Asked Questions</h4>
            <h4 className="TitleSmall">How can we help you?</h4>
          </div>
          <div className="StaticArea">
            <h4 className="Title">Frequently Asked Questions</h4>
            <h4 className="TitleSmall">How can we help you?</h4>
            <div id="accordion">
              {faq.map((item, index) => (
                <div className="card" key={index}>
                  <div
                    className={`card-header ${index === 0 ? "" : "collapsed"}`}
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href={`#collapse${index + 1}`}
                    aria-expanded={index === 0 ? "true" : "false"}
                  >
                    <h4
                      dangerouslySetInnerHTML={{ __html: item.question }}
                    ></h4>
                  </div>
                  <div
                    id={`collapse${index + 1}`}
                    className={`panel-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                  >
                    <div className="card-body">
                      <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <></>
    </>
  );
};

export default Faq;
