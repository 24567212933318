import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { staticContentService } from "../../services/staticContentService";
import { toast } from "react-toastify";
import { loader } from "../../store/LoaderSlice";
import { useDispatch } from "react-redux";

const Contact = () => {
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    getContactUs();
  }, []);

  async function getContactUs() {
    dispatch(loader(true));
    const result = await staticContentService.getContactUs();
    if (result?.success) {
      console.log("Contact us Data", result.data);
      setContactData(result.data);
      dispatch(loader(false));
    }
  }

  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>
      <>
        <div className="Small-Wrapper">
          <div className="container">
            <div className="TitleBox">
              <h4 className="Title">Contact Us</h4>
            </div>
            <div className="SettingsList">
              <h4 className="Title">Contact Us</h4>
              {contactData.map((item, index) => (
                <p dangerouslySetInnerHTML={{ __html: item.contact_us }}></p>
              ))}

              {/* <ul>
                <li>
                  <a href="javascript:void(0);">
                    <h4>Contact with Admin</h4>
                    <p>
                      Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                      Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    </p>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>

        {/* Patient Login Modals */}

        {/* Appointment Modal  */}
      </>
    </>
  );
};

export default Contact;
