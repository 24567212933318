import { createSlice } from "@reduxjs/toolkit";

const bookingPopupSlice = createSlice({
  name: "bookingPopup",
  initialState: false, // Boolean state to indicate popup visibility
  reducers: {
    setBookingPopup: (state, action) => action.payload, // Sets state to true or false
    clearBookingPopup: () => false, // Explicitly resets to false
  },
});

export const { setBookingPopup, clearBookingPopup } = bookingPopupSlice.actions;
export default bookingPopupSlice.reducer;
