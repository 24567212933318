import axiosInstance from "../axios/axios";

class EMRService {
  async getCarePlan(id) {
    try {
      const res = await axiosInstance.get(
        `/api/emr/get-patient-care-plan?emr_id=${id}`
      );
      console.log("Care Plan", res);
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data.results,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getDocuments(id) {
    try {
      const res = await axiosInstance.get(
        `/api/emr/get-documents?emr_id=${id}`
      );
      console.log("Documents", res);
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data.results,
          next: res.data.data.next,
          prev: res.data.data.previous,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getLabReports(id) {
    try {
      const res = await axiosInstance.get(
        `/api/emr/get-lab-reports?emr_id=${id}`
      );
      console.log("Documents", res);
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data,
          next: res.data.next,
          prev: res.data.previous,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getMedications(id) {
    try {
      const res = await axiosInstance.get(
        `/api/emr/get-patient-medications?emr_id=${id}`
      );
      console.log("Medications", res);
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data.results,
          next: res.data.data.next,
          prev: res.data.data.previous,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getVitals(id) {
    try {
      const res = await axiosInstance.get(
        `/api/emr/get-patient-vitals?emr_id=${id}`
      );
      console.log("Vitals", res);
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }
}

const emrService = new EMRService();

export { emrService, EMRService };
