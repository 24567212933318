// utils/notificationUtils.js
import Swal from "sweetalert2";
import axiosInstance from "../axios/axios";
import moment from "moment/moment";
import { parseISO, format } from "date-fns";
import { messaging } from "../config/firebaseConfig";
import "moment-timezone";

const icons = {
  success: "success",
  error: "error",
  warning: "warning",
};

export const showNotification = (icon, title, message) => {
  if (!icons[icon]) {
    console.error(`Invalid icon type: ${icon}`);
    return;
  }

  return Swal.fire({
    icon: icons[icon],
    title: `<span style="font-size: 1.2rem; font-weight: bold; color: #333;">${title}</span>`,
    html: `<span style="font-size: 1rem; color: #666;">${message}</span>`,
    showConfirmButton: true, // Show the "OK" button
    confirmButtonText: "OK", // Customize the button text
    allowOutsideClick: false, // Prevent closing by clicking outside the modal
    customClass: {
      confirmButton: "custom-confirm-button", // Add your custom class here
    },
  });
};

export async function getOtp(phone) {
  try {
    const res = await axiosInstance.post(
      "/api/patient/patient-otp-email-phone",
      {
        phone: phone,
      }
    );
    console.log(res);
    if (res.data.success) {
      return res.data.otp;
    }
  } catch (err) {
    console.log(err.message);
  }
}

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// export const isValidIndianPhone = (phoneNumber) => {
//   // Regular expression to match Indian phone number format
//   const phoneRegex = /^[6-9]\d{9}$/;

//   // Check if phoneNumber is a non-empty string and matches the regex pattern
//   return (
//     typeof phoneNumber === "string" &&
//     phoneNumber.trim().length > 0 &&
//     phoneRegex.test(phoneNumber)
//   );
// };

// export const isValidIndianPhone = (phoneNumber) => {
//   const phoneRegex = /^(\+?1[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
//   return phoneRegex.test(phoneNumber);
// };

export const isValidIndianPhone = (phoneNumber) => {
  // Enhanced regex to validate US phone numbers and exclude most fictional numbers
  const phoneRegex =
    /^(?:\+1[-.\s]?)?(?!555[-.\s]?)([2-9]\d{2})[-.\s]?([2-9]\d{2})[-.\s]?(\d{4})$/;

  // Test if the number matches the pattern
  return phoneRegex.test(phoneNumber);
};

export const formatUSPhoneNumber = (phoneNumber) => {
  // Remove all non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the cleaned number has exactly 10 digits
  if (cleaned.length !== 10) {
    throw new Error(
      "Invalid phone number. It should contain exactly 10 digits."
    );
  }

  // Format the number as (123)456-7890
  const formattedNumber = `(${cleaned.slice(0, 3)})${cleaned.slice(
    3,
    6
  )}-${cleaned.slice(6)}`;

  return formattedNumber;
};

export function calculateAge(dobString) {
  // Parse the DOB string into a Date object
  const dob = new Date(dobString);

  // Get the current date
  const today = new Date();

  // Calculate the difference in milliseconds
  let ageDiff = today - dob;

  // Convert the age difference from milliseconds to years
  let ageDate = new Date(ageDiff);
  let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

  return calculatedAge;
}

export function isImage(file) {
  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  return allowedTypes.includes(file.type);
}

export function addFeeAndTax(
  additionalFee,
  platform_charge,
  service_charge,
  others,
  doctor_fees
) {
  console.log("Input values:");
  console.log("additionalFee:", additionalFee);
  console.log("platform_charge:", platform_charge);
  console.log("service_charge:", service_charge);
  console.log("others:", others);
  console.log("doctor_fees:", doctor_fees);

  // Convert inputs to integers
  const parsedAdditionalFee = parseInt(additionalFee);
  const parsedPlatformCharge = parseInt(platform_charge);
  const parsedServiceCharge = parseInt(service_charge);
  const parsedOthers = parseInt(others);
  const parsedDoctorFees = parseInt(doctor_fees);

  console.log("Parsed values:");
  console.log("parsedAdditionalFee:", parsedAdditionalFee);
  console.log("parsedPlatformCharge:", parsedPlatformCharge);
  console.log("parsedServiceCharge:", parsedServiceCharge);
  console.log("parsedOthers:", parsedOthers);
  console.log("parsedDoctorFees:", parsedDoctorFees);

  // Calculate total sum
  const total =
    parsedAdditionalFee +
    parsedPlatformCharge +
    parsedServiceCharge +
    parsedOthers +
    parsedDoctorFees;

  console.log("Total:", total); // Log the total before returning

  return total;
}

export function isCurrentDateEqualToStoredDate(storedDate) {
  // Get current date in YYYY/MM/DD format
  const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "/"); // YYYY-MM-DD to YYYY/MM/DD

  // Compare storedDate with currentDate
  return storedDate === currentDate;
}

export function isStoredDateAfterCurrentDate(storedDate) {
  // Get current date in YYYY/MM/DD format
  const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "/"); // YYYY-MM-DD to YYYY/MM/DD

  // Compare storedDate with currentDate
  return storedDate > currentDate;
}

// export function generateTransactionId(length = 10) {
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let result = "";

//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     result += characters.charAt(randomIndex);
//   }

//   return result;
// }

export function generateTransactionId(length = 10, type = "string") {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  if (type === "number") {
    // Convert the string result to a number
    // Note: This conversion might not be possible if the length of result is too large
    const numericResult = parseInt(result, 36); // Base-36 conversion
    return isNaN(numericResult) ? 0 : numericResult;
  }

  return result;
}

export function formatDate(dateString) {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Format options for date and time
  const optionsDate = {
    weekday: "long", // Full weekday name
    year: "numeric",
    month: "short", // Short month name
    day: "numeric",
  };

  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/Los_Angeles",
    timeZoneName: "short", // Time zone abbreviation (PST/PDT)
  };

  // Create formatters
  const dateFormatter = new Intl.DateTimeFormat("en-US", optionsDate);
  const timeFormatter = new Intl.DateTimeFormat("en-US", optionsTime);

  // Format the date and time
  const formattedDate = dateFormatter.format(date);
  const formattedTime = timeFormatter.format(date);

  return `${formattedDate}, ${formattedTime}`;
}

// export function getFormattedDate(dateString, format = "YYYY-MM-DD") {
//   // Parse the input date string using Moment.js
//   const date = moment(dateString);

//   // Check if the date is valid
//   if (!date.isValid()) {
//     throw new Error("Invalid date format");
//   }

//   // Format the date using the specified format
//   return date.format(format);
// }

export function getFormattedDate(dateString, format = "YYYY-MM-DD") {
  const date = moment(dateString).utc();

  if (!date.isValid()) {
    throw new Error("Invalid date format");
  }

  return date.format(format);
}

export function getFormattedDateForChat(dateString, format = "YYYY-MM-DD") {
  const date = moment(dateString).utc();

  if (!date.isValid()) {
    throw new Error("Invalid date format");
  }

  // Convert the date to the local timezone before formatting
  return date.local().format(format);
}

export function extractTime(dateTimeString) {
  // Create a Date object from the ISO 8601 string
  const date = new Date(dateTimeString);

  // Extract hours and minutes
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  // Return time in HH:mm format
  return `${hours}:${minutes}`;
}

export function calculateLastDate(startDate, numberOfMonths) {
  console.log("start date in helper", startDate);
  // Parse the input date string
  const [year, month, day] = startDate?.split("/").map(Number);
  const date = new Date(year, month - 1, day); // JavaScript months are 0-based

  // Add the number of months to the date
  date.setMonth(date.getMonth() + numberOfMonths);

  // Calculate the last date of the resulting month
  // Set the date to 0th day of the next month
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // Format the date as 'YYYY/MM/DD'
  const lastDateString = [
    lastDate.getFullYear(),
    String(lastDate.getMonth() + 1).padStart(2, "0"),
    String(lastDate.getDate()).padStart(2, "0"),
  ].join("/");

  return lastDateString;
}

export const getFileType = (file) => {
  if (!file || !(file instanceof File)) {
    throw new Error("Invalid file input");
  }

  const mimeType = file.type;
  const fileType = {
    image: [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/svg+xml",
    ],
    audio: ["audio/mpeg", "audio/wav", "audio/ogg", "audio/mp4"],
    video: ["video/mp4", "video/webm", "video/ogg"],
    document: [
      "application/pdf", // PDF
      "application/msword", // DOC
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/vnd.ms-excel", // XLS
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      "text/csv", // CSV
      "application/vnd.ms-powerpoint", // PPT
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
    ],
  };

  if (fileType.image.includes(mimeType)) {
    return "image";
  } else if (fileType.audio.includes(mimeType)) {
    return "audio";
  } else if (fileType.video.includes(mimeType)) {
    return "video";
  } else if (fileType.document.includes(mimeType)) {
    return "file";
  } else {
    return "unknown";
  }
};

export const openImageInNewTab = (imageUrl) => {
  if (!imageUrl) {
    console.error("Image URL is required");
    return;
  }

  // Create a new anchor element
  const link = document.createElement("a");

  // Set the href attribute to the image URL
  link.href = imageUrl;

  // Set the target attribute to _blank to open in a new tab
  link.target = "_blank";

  // Append the link to the document body (it won't be visible)
  document.body.appendChild(link);

  // Programmatically click the link
  link.click();

  // Remove the link from the document body
  document.body.removeChild(link);
};

// export async function getDeviceToken() {
//   try {
//     // Request permission to send notifications
//     await Notification.requestPermission();

//     // Get FCM token
//     const token = await messaging.getToken({
//       vapidKey: "YOUR_PUBLIC_VAPID_KEY",
//     });
//     setFcmToken(token);
//     console.log("FCM Token:", token);
//   } catch (err) {
//     console.error("Error getting FCM token:", err);
//     setError(err);
//   }
// }

export const convertToBrowserTimezone = (timestamp) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log("Browser Timezone = ", browserTimezone);

  const convertedTimestamp = moment
    .tz(timestamp, browserTimezone)
    .format("YYYY-MM-DD hh:mm A");

  return convertedTimestamp;
};
