import React, { useEffect, useState } from "react";

import { staticContentService } from "../../../services/staticContentService";

import { useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";

function DocterAbout() {
  const dispatch = useDispatch();

  const [aboutData, setaboutData] = useState([]);

  useEffect(() => {
    getContents();
  }, []);

  async function getContents() {
    dispatch(loader(true));
    const result = await staticContentService.getAboutUs();

    if (result?.success) {
      // console.log("About Data", result.data);
      setaboutData(result.data);
      dispatch(loader(false));
    }
  }
  return (
    <>
      <div className="DoctorWrapperArea">
        <h4 className="mb-3 ml-3">About Us</h4>
        <div className="DoctorWrapper">
          <div className="TitleBox">
            <h4>
              <a href="settings.html">
                <i className="fa fa-angle-left" aria-hidden="true" />
              </a>{" "}
              About Us
            </h4>
          </div>
          <div className="StaticBox">
            {aboutData.map((item, index) => (
              <p dangerouslySetInnerHTML={{ __html: item.about_content }}></p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default DocterAbout;
