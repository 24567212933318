import { Helmet } from "react-helmet";
const Prescription = () => {
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>
      <div className="Small-Wrapper">
        <div className="container">
          <div className="TitleBox">
            <h4 className="Title">Prescription</h4>
          </div>
          <div className="LabReporsArea">
            <article>
              <aside>
                <figcaption>
                  <h4>#1234567890</h4>
                  <p>
                    <span className="Icon">
                      <img src="../images/girl-avatar.png" />
                    </span>
                    Mr. John Smith
                  </p>
                </figcaption>
                <span>
                  <img src="../images/pdf.png" />
                </span>
              </aside>
              <aside>
                <a href="javascript:void(0);">Reason For Call</a>
                <h6>24/04/24 12:44</h6>
              </aside>
            </article>
            <article>
              <a href="document-available.html">
                <aside>
                  <figcaption>
                    <h4>Lorem Ipsum</h4>
                    <p>Lorem Ipsum</p>
                  </figcaption>
                  <span>
                    <img src="../images/pdf.png" />
                  </span>
                </aside>
                <aside>
                  <p>24/04/24 12:44</p>
                </aside>
              </a>
            </article>
            <article>
              <a href="document-available.html">
                <aside>
                  <figcaption>
                    <h4>Lorem Ipsum</h4>
                    <p>Lorem Ipsum</p>
                  </figcaption>
                  <span>
                    <img src="../images/pdf.png" />
                  </span>
                </aside>
                <aside>
                  <p>24/04/24 12:44</p>
                </aside>
              </a>
            </article>
            <article>
              <a href="document-available.html">
                <aside>
                  <figcaption>
                    <h4>Lorem Ipsum</h4>
                    <p>Lorem Ipsum</p>
                  </figcaption>
                  <span>
                    <img src="../images/pdf.png" />
                  </span>
                </aside>
                <aside>
                  <p>24/04/24 12:44</p>
                </aside>
              </a>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default Prescription;
