// import React, { useState } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { setUser, clearUser } from "../../store/UserSlice";
// import { showNotification } from "../../helper/helper";
// import { patientService } from "../../services/patientServices";
// const VerifyPhoneOtp = ({
//   toggleOTPModal,
//   setToggleOTPModal,
//   phoneRegister,
//   otpregister,
//   email,
//   setisLoggedIn,
//   settogglecongratulations,
// }) => {
//   const dispatch = useDispatch();
//   const reduxUser = useSelector((state) => state.user);
//   const [otpInputs, setOtpInputs] = useState(["", "", "", ""]); // State to store OTP inputs

//   const handleInputChange = (index, value) => {
//     const newOtpInputs = [...otpInputs];
//     newOtpInputs[index] = value;
//     setOtpInputs(newOtpInputs);
//   };

//   const handleClose = () => {
//     setOtpInputs(["", "", "", ""]);
//     setToggleOTPModal(false);
//   };

//   const handleVerifyOtp = async () => {
//     const enteredOtp = otpInputs.join(""); // Join array elements to form OTP
//     console.log("Entered OTP:", enteredOtp);

//     if (otpregister == enteredOtp) {
//       console.log("email", email);
//       console.log("phone", phoneRegister);

//       const result = await patientService.createPatient(email, phoneRegister);
//       if (result.is_created) {
//         // showNotification(
//         //   "success",
//         //   "Registered successfuly",
//         //   `You have been registered successfully.`
//         // );
//         settogglecongratulations(true);

//         localStorage.setItem("user", JSON.stringify(result.patient));
//         localStorage.setItem("token", result.token);
//         setisLoggedIn(true);
//         localStorage.setItem("isLoggedIn", true);
//         dispatch(setUser(result.patient));
//       } else {
//         showNotification(
//           "error",
//           "Failed to craete",
//           `Someting went wrong. Please try again`
//         );
//       }

//       handleClose();
//     } else {
//       showNotification(
//         "error",
//         "Incorrect OTP",
//         "The otp you entered is incorrect or invalid."
//       );
//     }
//   };

//   return (
//     <Modal
//       show={toggleOTPModal}
//       onHide={handleClose}
//       id="VerifyPhoneOtp"
//       centered
//     >
//       <div className="modal-content">
//         <div className="modal-body">
//           <button
//             type="button"
//             className="CloseModal"
//             onClick={handleClose}
//             aria-label="Close"
//           >
//             &times;
//           </button>
//           <div className="LoginBox">
//             <div className="LoginHead">
//               <img src="images/Fav.png" alt="Logo" />
//             </div>
//             <div className="LoginBody">
//               <article>
//                 <h3>OTP Code Verification - register phone</h3>
//                 <p>Code has been sent to {phoneRegister}</p>
//               </article>
//               <Form>
//                 <Form.Group className="form-group">
//                   <div className="OTPBox">
//                     {otpInputs.map((input, index) => (
//                       <input
//                         key={index}
//                         type="text"
//                         className="form-control"
//                         maxLength={1}
//                         placeholder=""
//                         value={input}
//                         onChange={(e) =>
//                           handleInputChange(index, e.target.value)
//                         }
//                       />
//                     ))}
//                   </div>
//                 </Form.Group>
//                 <div className="ButtonBox">
//                   <Button className="Gray" onClick={handleVerifyOtp}>
//                     Verify
//                   </Button>
//                 </div>
//               </Form>
//               <aside>
//                 <h6>
//                   Resend Code In <strong>55</strong> s
//                 </h6>
//               </aside>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default VerifyPhoneOtp;
import React, { useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/UserSlice";
import { formatUSPhoneNumber, showNotification } from "../../helper/helper";
import { patientService } from "../../services/patientServices";
import Timer from "../timerComponent/Timer";
const VerifyPhoneOtp = ({
  toggleOTPModal,
  setToggleOTPModal,
  phoneRegister,
  otpregister,
  email,
  setisLoggedIn,
  settogglecongratulations,
}) => {
  const dispatch = useDispatch();
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]); // State to store OTP inputs
  const inputRefs = useRef([]); // Create a ref array for input elements

  // const handleInputChange = (index, value) => {
  //   if (isNaN(value) || value === "") return; // Allow only numeric inputs

  //   const newOtpInputs = [...otpInputs];
  //   newOtpInputs[index] = value;
  //   setOtpInputs(newOtpInputs);

  //   // Move focus to the next input if a value is entered
  //   if (value && index < otpInputs.length - 1) {
  //     inputRefs.current[index + 1].focus();
  //   }
  // };
  const handleInputChange = (index, value) => {
    if (isNaN(value)) return; // Allow only numeric inputs

    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);

    // Move focus to the next input if a value is entered
    if (value && index < otpInputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Move focus to the previous input if backspace is pressed and the current field is empty
    if (e.key === "Backspace" && index > 0 && !otpInputs[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleClose = () => {
    setOtpInputs(["", "", "", ""]);
    setToggleOTPModal(false);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const enteredOtp = otpInputs.join(""); // Join array elements to form OTP
    console.log("Entered OTP:", enteredOtp);

    if (otpregister === enteredOtp) {
      console.log("email", email);
      console.log("phone", formatUSPhoneNumber(phoneRegister));

      const result = await patientService.createPatient(
        email,
        formatUSPhoneNumber(phoneRegister)
      );
      if (result?.is_created) {
        settogglecongratulations(true);
        localStorage.setItem("user", JSON.stringify(result.patient));
        localStorage.setItem("token", result.token);
        setisLoggedIn(true);
        localStorage.setItem("isLoggedIn", true);
        dispatch(setUser(result.patient));
        handleClose();
      } else {
        showNotification(
          "error",
          "Failed to Create",
          `Something went wrong. Please try again.`
        );
      }
    } else {
      showNotification(
        "error",
        "Incorrect OTP",
        "The OTP you entered is incorrect or invalid."
      );
    }
  };

  return (
    <Modal
      show={toggleOTPModal}
      onHide={handleClose}
      backdrop="static"
      id="VerifyPhoneOtp"
      centered
    >
      <div className="modal-content">
        <div className="modal-body">
          <button
            type="button"
            className="CloseModal"
            onClick={handleClose}
            aria-label="Close"
          >
            &times;
          </button>
          <div className="LoginBox">
            <div className="LoginHead">
              <img src="images/Fav.png" alt="Logo" />
            </div>
            <div className="LoginBody">
              <article>
                <h3>OTP Code Verification - Register Phone</h3>
                <p>Code has been sent to {phoneRegister}</p>
              </article>
              <Form onSubmit={handleVerifyOtp}>
                <Form.Group className="form-group">
                  <div className="OTPBox">
                    {otpInputs.map((input, index) => (
                      <input
                        key={index}
                        type="text"
                        className="form-control"
                        maxLength={1}
                        placeholder=""
                        value={input}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                </Form.Group>
                <div className="ButtonBox ButtonBoxNew ">
                  <Button className="Blue" type="submit">
                    Verify
                  </Button>
                </div>
              </Form>
              <aside>
                {/* <h6>
                  Resend Code In <strong>55</strong> s
                </h6> */}
                <Timer />
              </aside>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyPhoneOtp;
