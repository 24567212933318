import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agoraChannel: "", // This should match the state property you update
};

const agoraChannelSlice = createSlice({
  name: "agoraChannel",
  initialState,
  reducers: {
    setAgoraChannel: (state, action) => {
      state.agoraChannel = action.payload; // Make sure this matches the initialState property
    },
  },
});

export const { setAgoraChannel } = agoraChannelSlice.actions;
export default agoraChannelSlice.reducer;
