import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { useState, useEffect } from "react";
import { emrService } from "../../../services/emrService";
const Vitals = () => {
  const dispatch = useDispatch();
  const emr_id = useSelector((state) => state.user.emr_id);
  const [vitals, setvitals] = useState({});

  useEffect(() => {
    if (emr_id) {
      getVital();
    }
  }, [emr_id]);

  async function getVital() {
    dispatch(loader(true));
    const res = await emrService.getVitals(emr_id);
    if (res.success) {
      setvitals(res.data);
    }
    dispatch(loader(false));
  }
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="/css/style.css" />

        <link rel="stylesheet" href="/css/patient.css" /> */}
      </Helmet>

      <div class="Small-Wrapper">
        <div class="container">
          <div class="TitleBox">
            <h4 class="Title">My Vitals</h4>
          </div>
          <div class="MyVitalsArea">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <h6>Height</h6>
                  <p>{Object.keys(vitals).length ? vitals.height : "-"}</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <h6>Weight</h6>
                  <p>{Object.keys(vitals).length ? vitals.weight : "-"}</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <h6>Body Mass Index (BMI)</h6>
                  <p>{Object.keys(vitals).length ? vitals.bmi : "-"}</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <h6>Heart Rate</h6>
                  <p>
                    {Object.keys(vitals).length ? vitals.heart_rate : "-"} BPM
                  </p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <h6>Blood Pressure</h6>
                  <p>
                    {Object.keys(vitals).length ? vitals.blood_pressure : "-"}
                  </p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <h6>SPO2</h6>
                  <p>{Object.keys(vitals).length ? vitals.spo2 : "-"}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vitals;
