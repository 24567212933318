import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { conversationService } from "../../../services/conversationService";
import { toast } from "react-toastify";
import { useState } from "react";
import io from "socket.io-client";
import {
  formatDate,
  getFileType,
  getFormattedDate,
  getFormattedDateForChat,
  openImageInNewTab,
  showNotification,
} from "../../../helper/helper";
import { useRef } from "react";
import { uploadToAws } from "../../../helper/s3Helper";
import AudioPlayer from "../../audioPlayer/AudioPLayer";
import VideoPlayer from "../../videoPlayer/VideoPLayer";
import { useLocation } from "react-router-dom";
import { getSocket } from "../../../config/socketIoConfig";
const Messages = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = useParams();
  const ulRef = useRef(null);
  const reduxUser = useSelector((state) => state.user);
  const doctor = useSelector((state) => state.doctor);
  const [conversation_id, setconversation_id] = useState("");
  const [messages, setmessages] = useState([]);
  const [ChatStatus, setsetChatStatus] = useState("");
  const [socket, setSocket] = useState(null);
  const [typedMessage, settypedMessage] = useState("");
  const [senderName, setsenderName] = useState("");
  const [receiverName, setreceiverName] = useState("");
  const [patient, setpatient] = useState("");
  const [doc_id, setDoc_id] = useState("");
  const [appointment, setappointment] = useState("");
  const [typing, settyping] = useState(false);

  const { participant } = location.state;

  const SOCKET_URL = "http://3.13.196.242:5001";
  // const SOCKET_URL = "http://localhost:5001";

  useEffect(() => {
    console.log("location", location);
    console.log("query", queryParams);
    const patient = queryParams.get("patient");
    const doctor = queryParams.get("doctor");
    const appointment = queryParams.get("appointment");
    setpatient(patient);
    setDoc_id(doctor);
    setappointment(appointment);
    console.log("patient", patient);
    console.log("doctor", doctor);
  }, []);

  useEffect(() => {
    if (doctor?._id) {
      const socketIo = getSocket(doctor?._id);

      setSocket(socketIo);

      socketIo.on("connect", () => {
        console.log("Connected to Socket.IO server");
      });

      socketIo.on("disconnect", (reason) => {
        console.log("Disconnected from Socket.IO server:", reason);
      });

      socketIo.on("rcv-one-to-one-message", (msg) => {
        console.log("Message received:", msg);
        setmessages((prevMessages) => [...prevMessages, msg]);
      });

      socketIo.on("typing", (msg) => {
        if (msg.event == "started typing") {
          settyping(true);
        }
      });

      socketIo.on("stopTyping", (msg) => {
        if (msg.event == "stopped typing") {
          settyping(false);
        }
      });

      // return () => {
      //   socketIo.disconnect();
      // };
    }
  }, [doctor]);

  useEffect(() => {
    // console.log("=>", params.patient);
    // console.log("=>", params.doctor);

    if (patient != "" && doc_id != "" && appointment != "") {
      create_conversation(doc_id, patient, appointment);
    }
  }, [patient, doc_id]);

  useEffect(() => {
    if (messages.length) {
      if (ulRef.current) {
        // Scroll to the bottom of the ul element
        ulRef.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
      }

      const seen_msg = {
        conversation_id: conversation_id,
        seen_by: "doctor",
      };

      socket.emit("seen-message", seen_msg);
    }
  }, [messages]);

  useEffect(() => {
    const data = {
      receiver_id: patient,
      sender_id: doctor?._id,
    };
    if (typedMessage == "" && socket != null) {
      socket?.emit("stopTyping", data);
    } else {
      socket?.emit("typing", data);
    }
  }, [typedMessage, socket]);

  async function create_conversation(doctor, patient, appointment) {
    try {
      dispatch(loader(true));
      const res = await conversationService.createConvoDoctor(
        doctor,
        patient,
        appointment
      );
      if (res.success == true && res.conversation_type == "new") {
        setconversation_id(res.convo._id);
        setsetChatStatus(true);
      }
      if (res.success == true && res.conversation_type == "existing") {
        setconversation_id(res.convo._id);
        getMessagesInsidechat(res.convo._id);
      }
      dispatch(loader(false));
    } catch (err) {
      console.log(err.message);
      dispatch(loader(false));
    }
  }

  async function getMessagesInsidechat(id) {
    try {
      dispatch(loader(true));
      const res = await conversationService.getMessagesByConversation(id);
      if (res.success) {
        setmessages(res.messages);
        setsetChatStatus(res.convo_open_or_close);
        const patient = res.participants.find((item) => item.type == "patient");
        const doctor = res.participants.find((item) => item.type == "doctor");
        // console.log("pat", patient, "doc", doctor);
        setsenderName(patient.participantId.firstname);
        setreceiverName(doctor.participantId.firstname);
      } else {
        setsetChatStatus(res.convo_open_or_close);
      }

      dispatch(loader(false));
    } catch (err) {
      console.log(err.message);

      dispatch(loader(false));
    }
  }

  async function sendMessage(e) {
    console.log("function hit");
    e.preventDefault();
    if (ChatStatus == false) {
      showNotification(
        "warning",
        "Chat is closed",
        "You cannot communicate as this chat is closed."
      );
      return;
    } else {
      if (typedMessage != "" && conversation_id != "") {
        const message = {
          receiver_id: patient,
          sender_id: doc_id,
          sender_role: "doctor",
          receiver_role: "patient",
          message: typedMessage,
          conversation_id: conversation_id,
          message_type: "text",
        };

        console.log("sent-message", message);

        socket.emit("send-one-to-one-message", message);
        settypedMessage("");
      } else {
        toast("Please type a message.");
      }
    }
  }

  async function handleFileChange(e) {
    // Check if any files are selected
    if (!e.target.files || e.target.files.length === 0) {
      return; // Exit early if no files are selected
    }

    // Get the first file
    const file = e.target.files[0];

    try {
      const file_type = getFileType(file);
      console.log("file type", file_type);

      if (file_type === "unknown") {
        showNotification(
          "error",
          "File Not Supported",
          "File type not supported."
        );
      } else {
        dispatch(loader(true));
        const link = await uploadToAws(file);
        if (link && file_type) {
          const message = {
            receiver_id: patient,
            sender_id: doc_id,
            sender_role: "doctor",
            receiver_role: "patient",
            message: link,
            conversation_id: conversation_id,
            message_type: file_type,
          };
          socket.emit("send-one-to-one-message", message);
          settypedMessage("");
        }
        dispatch(loader(false));
      }
    } catch (error) {
      dispatch(loader(false));
      console.error("Error handling file:", error);
      // Optionally show an error notification
      showNotification(
        "error",
        "Upload Error",
        "There was an error uploading the file."
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>Messaging</title>
      </Helmet>

      <div className="DoctorWrapperArea">
        {participant.firstname ? (
          <div className="d-flex justify-content-center ">
            <h6>Chatting with - {participant?.firstname}</h6>
          </div>
        ) : null}
        <div className="DoctorWrapper">
          <div style={{ minHeight: "calc(100vh - 132px)" }}>
            <div className="container">
              <div className="ChatArea">
                <div className="ChatBody">
                  <ul ref={ulRef} className="pr-3">
                    {messages?.length
                      ? messages?.map((item, index) => (
                          <li key={index}>
                            {item.message_type == "text" ? (
                              <div
                                className={`${
                                  doctor?._id == item.sender
                                    ? item.message_type == "text"
                                      ? "MyMessage"
                                      : "MyMessageWImg"
                                    : item.message_type == "text"
                                    ? "OtherMessage"
                                    : "OtherMessageWImg"
                                }`}
                              >
                                <h5 className="text-capitalize">
                                  {doctor?._id == item.sender ? (
                                    <strong>{receiverName}</strong>
                                  ) : (
                                    <strong>{senderName}</strong>
                                  )}{" "}
                                  <span>
                                    {getFormattedDateForChat(
                                      item.createdAt,
                                      "YYYY-MM-DD HH:mm A"
                                    )}
                                  </span>{" "}
                                </h5>
                                <figure>
                                  {/* <img src="/images/Tablets.png" /> */}
                                </figure>
                                <p>{item.content}</p>
                              </div>
                            ) : item.message_type == "image" ? (
                              <div
                                className={`${
                                  doctor?._id == item.sender
                                    ? item.message_type == "text"
                                      ? "MyMessage"
                                      : "MyMessageWImg"
                                    : item.message_type == "text"
                                    ? "OtherMessage"
                                    : "OtherMessageWImg"
                                }`}
                                onClick={() => openImageInNewTab(item.content)}
                              >
                                <h5 className="text-capitalize">
                                  {doctor?._id == item.sender ? (
                                    <strong>{receiverName}</strong>
                                  ) : (
                                    <strong>{senderName}</strong>
                                  )}{" "}
                                  <span>
                                    {getFormattedDateForChat(
                                      item.createdAt,
                                      "YYYY-MM-DD HH:mm A"
                                    )}
                                  </span>{" "}
                                </h5>
                                <figure>
                                  {/* <img src="/images/Tablets.png" /> */}
                                </figure>
                                <img src={item.content}></img>
                              </div>
                            ) : item.message_type == "audio" ? (
                              <div
                                className={`${
                                  doctor?._id == item.sender
                                    ? item.message_type == "text"
                                      ? "MyMessage"
                                      : "MyMessageWImg"
                                    : item.message_type == "text"
                                    ? "OtherMessage"
                                    : "OtherMessageWImg"
                                }`}
                                onClick={() => openImageInNewTab(item.content)}
                              >
                                <h5 className="text-capitalize">
                                  {doctor?._id == item.sender ? (
                                    <strong>{receiverName}</strong>
                                  ) : (
                                    <strong>{senderName}</strong>
                                  )}{" "}
                                  <span>
                                    {getFormattedDateForChat(
                                      item.createdAt,
                                      "YYYY-MM-DD HH:mm A"
                                    )}
                                  </span>{" "}
                                </h5>
                                <figure>
                                  {/* <img src="/images/Tablets.png" /> */}
                                </figure>
                                <AudioPlayer url={item.content} />
                              </div>
                            ) : item.message_type == "video" ? (
                              <div
                                className={`${
                                  doctor?._id == item.sender
                                    ? item.message_type == "text"
                                      ? "MyMessage"
                                      : "MyMessageWImg"
                                    : item.message_type == "text"
                                    ? "OtherMessage"
                                    : "OtherMessageWImg"
                                }`}
                                onClick={() => openImageInNewTab(item.content)}
                              >
                                <h5 className="text-capitalize">
                                  {doctor?._id == item.sender ? (
                                    <strong>{receiverName}</strong>
                                  ) : (
                                    <strong>{senderName}</strong>
                                  )}{" "}
                                  <span>
                                    {getFormattedDateForChat(
                                      item.createdAt,
                                      "YYYY-MM-DD HH:mm A"
                                    )}
                                  </span>{" "}
                                </h5>
                                <figure>
                                  {/* <img src="/images/Tablets.png" /> */}
                                </figure>
                                <VideoPlayer url={item.content} />
                              </div>
                            ) : item.message_type == "file" ? (
                              <div
                                className={`${
                                  doctor?._id == item.sender
                                    ? item.message_type == "text"
                                      ? "MyMessage"
                                      : "MyMessageWImg"
                                    : item.message_type == "text"
                                    ? "OtherMessage"
                                    : "OtherMessageWImg"
                                }`}
                                onClick={() => openImageInNewTab(item.content)}
                              >
                                <h5 className="text-capitalize">
                                  {doctor?._id == item.sender ? (
                                    <strong>{receiverName}</strong>
                                  ) : (
                                    <strong>{senderName}</strong>
                                  )}{" "}
                                  <span>
                                    {getFormattedDateForChat(
                                      item.createdAt,
                                      "YYYY-MM-DD HH:mm A"
                                    )}
                                  </span>{" "}
                                </h5>
                                <figure>
                                  {/* <img src="/images/Tablets.png" /> */}
                                </figure>
                                <img src="/images/file_icon.png" alt="" />
                              </div>
                            ) : null}
                          </li>
                        ))
                      : null}
                    {typing ? (
                      <div className="typing2">
                        <strong>Typing</strong>
                        <div className="dots">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    ) : null}
                  </ul>
                </div>
                {ChatStatus == true ? (
                  <div
                    className="ChatFooter fixed-bottom"
                    style={{ boxShadow: "0 -4px 60px 0 rgba(4, 6, 15, 0.05)" }}
                  >
                    <form className="w-100" onSubmit={(e) => sendMessage(e)}>
                      <aside
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <img src="/images/Attach.png" />
                          <input
                            type="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                            multiple
                            onChange={handleFileChange}
                          />
                        </span>
                        <input
                          type="text"
                          value={typedMessage}
                          className="form-control"
                          placeholder="Type a Message..."
                          onChange={(e) => settypedMessage(e.target.value)}
                          required
                        />
                        <button type="submit">
                          <img src="/images/Plan.png" />{" "}
                        </button>
                      </aside>
                    </form>
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      Chat is completed. Cannot send new messages now.
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
