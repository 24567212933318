import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const videoCallService = {
  getChannel,
  getToken,
  sendCallNotification,
};

async function getChannel(patient_id, doctor_id) {
  const data = {
    sender_id: doctor_id,
    receiver_id: patient_id,
    sender_role: "doctor",
    receiver_role: "patient",
  };

  try {
    const result = await axiosInstance.post(
      `/api/agora/create-or-get-channel-between-participants`,
      data
    );
    console.log(result);
    if (result.data.success) {
      return { success: true, channel: result?.data?.channel?._id };
    } else {
      return { success: false, channel: "" };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, clinics: [] };
  }
}

async function getToken(channel) {
  const data = {
    channel_id: channel,
  };

  try {
    const result = await axiosInstance.post(
      `/api/agora/get-token-for-channel`,
      data
    );
    console.log(result);
    if (result.data.success) {
      return { success: true, token: result.data.token.token };
    } else {
      return { success: false, token: "" };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, clinics: [] };
  }
}

async function sendCallNotification(data) {
  try {
    const result = await axiosInstance.post(
      `/api/agora/start-video-call`,
      data
    );
    console.log(result);
    if (result.data.success) {
      return { success: true, msg: "Call-ringing notification send" };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false };
  }
}
