import { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { patientService } from "../../services/patientServices";
import { useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { showNotification } from "../../helper/helper";

const ContactForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);

    dispatch(loader(true));

    const res = await patientService.sendContactForm(formData);
    if (res) {
      showNotification("success", "Email sent", "Email sent successfuly");
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }

    dispatch(loader(false));
  };

  return (
    <Container className="my-5" style={{ width: "30%" }}>
      <Form onSubmit={handleSubmit}>
        {/* <div className="ApplicationArea"> */}
        <figcaption className="w-100 ml-0">
        <h4 className="mb-3">Need more information</h4>
          <Form.Group controlId="formName" className="mb-4 form-group">
            <Form.Label className="text-sm">Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="rounded-3 text-sm h-10 form-control"
            />
          </Form.Group>
        </figcaption>

        <figcaption className="w-100 ml-0">
          <Form.Group controlId="formEmail" className="mb-4 form-group">
            <Form.Label className="text-sm">Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="rounded-3 text-sm h-10"
            />
          </Form.Group>
        </figcaption>

        <figcaption className="w-100 ml-0">
          <Form.Group controlId="formPhone" className="mb-4 form-group">
            <Form.Label className="text-sm">Phone</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter your phone number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="rounded-3 text-sm h-10"
            />
          </Form.Group>
        </figcaption>

        <figcaption className="w-100 ml-0">
          <Form.Group controlId="formMessage" className="mb-4 form-group">
            <Form.Label className="text-sm">Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter your message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="rounded-3 text-sm "
            />
          </Form.Group>
        </figcaption>

        <Button variant="primary" type="submit" className="SentSmsButton mt-0">
          Submit
        </Button>
        {/* </div> */}
      </Form>
    </Container>
  );
};

export default ContactForm;
