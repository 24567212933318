import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const doctorService = {
  login: login,
  sendOtp: sendOtp,
  updatePass: updatePass,
  sendOtpOnPhone: sendOtpOnPhone,
  loginusingphone: loginusingphone,
  getAppointments: getAppointments,
  getNotifications: getNotifications,
  getDoctorDashboardData: getDoctorDashboardData,
  updateProfile: updateProfile,
};

async function login(data) {
  try {
    const res = await axiosInstance.post(`/api/doctor/doctor-login`, data);

    console.log("doctor login", res);

    if (res.status == 200) {
      localStorage.clear();
      return {
        success: true,
        token: res.data.access_token,
        doctor: res.data.doctor_details,
      };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function sendOtp(data) {
  try {
    const res = await axiosInstance.post(
      `/api/doctor/doctor-forgot-pass-sent-otp`,
      data
    );

    console.log("otp", res);

    if (
      res.data.message ==
      "otp sent successfully on mail and on the registered phone number"
    ) {
      return { success: true, otp: res.data.otp };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function updatePass(data) {
  try {
    const res = await axiosInstance.post(`/api/doctor/reset-password`, data);
    console.log("updated pass", res);

    if (res.data.message == "Password reset successfuly") {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function sendOtpOnPhone(phone) {
  try {
    const res = await axiosInstance.post(
      `/api/doctor/send-otp-on-phone`,
      phone
    );

    console.log("otp", res);

    if (res.data.message == "otp sent successfully on phone number") {
      return { success: true, otp: res.data.otp };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function loginusingphone(phone) {
  try {
    const res = await axiosInstance.post(
      `/api/doctor/find-account-by-phone`,
      phone
    );

    console.log("otp", res);

    if (
      res.data.message ==
      "Account found with the given phone . An otp sent successfully on mail and on the registered phone number"
    ) {
      localStorage.clear();
      return {
        success: true,
        otp: res.data.otp,
        token: res.data.access_token,
        doc: res.data.doctor,
      };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function getAppointments(id) {
  try {
    const res = await axiosInstance.get(
      `api/appointment/get-upcoming-appointments-by-doctor_id?doctor_id=${id}`
    );

    const res2 = await axiosInstance.get(
      `/api/appointment/get-completed-appointments-by-doctor_id?doctor_id=${id}`
    );

    console.log("res===>", res);
    console.log("res2===>", res2);

    return {
      upcoming: res.data.upcoming_appointmnets,
      completed: res2.data.completed_appointmnets,
    };
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function getNotifications(id) {
  try {
    const res = await axiosInstance.get(
      `/api/admin/get-notifications-by-doctorId?doctor_id=${id}`
    );

    // console.log("notifications===>", res);
    if (res.data.success) {
      return {
        success: true,
        data: res.data.notification,
      };
    } else {
      return {
        success: false,
        data: [],
      };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function getDoctorDashboardData(doctorId, fromDate, toDate, filterBy) {
  try {
    const res = await axiosInstance.post(
      `/api/doctor/get-doctor-dashboard-details`,
      {
        doctorId,
        fromDate,
        toDate,
        filterBy,
      }
    );

    // console.log("dashboard data===>", res);
    if (res.status == 200) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}

async function updateProfile(id, data) {
  try {
    const res = await axiosInstance.put(
      `/api/doctor/update-doctor/${id}`,
      data
    );
    console.log("updated pass", res);

    if (res.data.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}
